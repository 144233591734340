import React from 'react';
import { Link } from 'react-scroll';
import BackButton from '../general/BackButton';

const sections = [
    { id: 'section1', title: 'Introduction', activeClass: 'active' },
    { id: 'section2', title: 'Inscription' },
    { id: 'section3', title: 'Rôles' },
    { id: 'section4', title: 'Général' },
    { id: 'section5', title: 'Canal' },
    { id: 'section6', title: 'Secteur' },
    { id: 'section7', title: 'Animal' },
    { id: 'section8', title: 'Listing' },
    { id: 'section9', title: 'Mode édition' },
    { id: 'section10', title: 'Notifications' },
    { id: 'section11', title: 'Paiements' },
    { id: 'section12', title: 'Kappze+' }
];

const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="backBtnDoc" style={{ marginBottom: '2rem' }}>
                <BackButton />
            </div>



            {sections.map(section => (
                <li key={section.id}>
                    <Link
                        to={section.id}
                        spy={true}
                        smooth={true}
                        offset={-300}
                        duration={500}
                        isDynamic={true}

                    >
                        {section.title}
                    </Link>
                </li>
            ))}

        </div>
    );
}

export default Sidebar;
