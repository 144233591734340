import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { addAnimal, uploadImage, uploadSingleFile, addDocumentToAnimal } from '../../features/animals/animalSlice';
import { createAndSendNotification } from '../../features/notifications/notificationSlice';
import { fetchAnimalsByCanal } from '../../features/animals/animalSlice';
import { fetchSubSectors } from '../../features/citiesSector/subSectorSlice';
import CityAndSectorSelect from '../../components/citiesSector/CityAndSectorSelect';
import ColorSelect from '../../components/animals/ColorSelect';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import 'animate.css';
import iconpaw from '../../assets/icon-paw.svg';
import iconadd from '../../assets/icon-add.svg';
import imgIconWhite from '../../assets/image-outline-white.svg'

import BackButton from '../../components/general/BackButton';
import LoadingPage from '../../components/general/LoadingPage';
import MotherSelect from '../../components/animals/MotherAnimalSelect';
import RaceSelect from '../../components/animals/RaceSelect';
import HairSelect from '../../components/animals/HairSelect';
import OpenableSection from '../../components/documentation/OpenableSection';
import SituationSelect from '../../components/animals/SituationCatSelect';
import CatNotFound from '../../assets/cat-not-found-white.png'
import PatternSelect from '../../components/animals/BreadSelect';
import { updateSignalsTotal } from '../../features/user/userSlice';
import { signal } from '@tensorflow/tfjs';
import DynamicMap from '../Maps/standardMap';


const SignalAnimal = () => {
    const { uid, email } = useSelector((state) => state.auth);
    const { canalId } = useParams();
    const { data: animals } = useSelector((state) => state.animals)

    const { t } = useTranslation();
    let location = useLocation();
    const hasAnOwner = location.state;
    const today = new Date();
    const formattedTodayDate = today.toISOString().split('T')[0];
    const visitorAnimals = animals.filter((animal) => uid === animal.userCreatorId)
    const motherAnimals = visitorAnimals.filter((animal) => animal.isMother);
    const { data: canals, status } = useSelector((state) => state.canals);
    const { data: users } = useSelector((state) => state.canalUsers);
    const canalInfos = canals.find((asso) => asso.id === canalId);
    const { data: citiesSector } = useSelector((state) => state.citiesSector);
    const { data: subSectors } = useSelector((state) => state.subSectors);


    const [name, setName] = useState('');
    const [identification, setIdentification] = useState(false);
    const [numberIdentification, setNumberIdentification] = useState('');
    const [isBelonged, setBelong] = useState(hasAnOwner ? true : null);
    const [isSterilize, setSterilize] = useState(false);
    const [colors, setColor] = useState([]);
    const [patterns, setPattern] = useState([]);
    const [hairs, setHair] = useState([]);
    const [races, setRace] = useState([]);
    const [isSick, setDisease] = useState('');
    const [diseases, setDiseases] = useState('');
    const [particularities, setParticularities] = useState('');
    const [commentSignal, setCommentSignal] = useState('');
    const [city, setCity] = useState('');
    const [cityId, setCityId] = useState('');
    const [subSector, setSubSector] = useState('');
    const [subSectorId, setSubSectorId] = useState('');
    const [sexAnimal, setSex] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [dateIdentification, setDateIdentification] = useState(null);
    const [isFamily, setFamily] = useState(null);
    const [motherId, setMotherId] = useState('');
    const [situationCat, setSituationCat] = useState('');
    const [mother, setMother] = useState(null);
    const [selectedSituationCat, setAnimalSituation] = useState(null);
    const [situationId, setSituationId] = useState(null);
    const [fatherId, setFatherId] = useState(null);
    const [isMother, setIsMother] = useState(null);
    const [requestInProcess, setRequestInProcess] = useState(null);
    const fileInputRef = useRef(null);
    const [previewImage, setPreviewImage] = useState('');
    const [imageUrlCat, setImageUrl] = useState('');
    const [image, setImage] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [showSubSectors, setShowSubSectors] = useState('');
    const [userIsAdmin, setUserRole] = useState(false);
    const [userIsCreator, setUserCreatorRole] = useState(true)
    const [markers, setMarkers] = useState([]);



    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentDate = new Date();

    const triggerFileUpload = () => {
        fileInputRef.current.click();
    };


    useEffect(() => {

        dispatch(fetchAnimalsByCanal(canalId))

    }, [dispatch, canalId]);

    useEffect(() => {
        if (cityId) {
            setShowSubSectors(true);
            dispatch(fetchSubSectors(cityId))
        } else {
            setShowSubSectors(''); // Réinitialiser si isTrapped est false
        }
    }, [cityId]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setRequestInProcess(true);
        let imageUrl = {};
        if (imageUrlCat) {
            imageUrl = await uploadImage(imageUrlCat);
        }



        const generateName = (animalsCount) => {
            // Ajoute 1 au nombre total d'animaux pour obtenir le prochain identifiant
            const nextId = animalsCount + 1;
            // Formate le numéro pour qu'il soit toujours au format "#001", "#010", "#100", etc.
            return `#${nextId.toString().padStart(3, '0')}`;
        };

        const data = {
            userCreatorId: uid,
            addedDate: formattedTodayDate,
            canalId: canalId,
            canalName: canalInfos.name,
            name: name ? name : generateName(animals?.length),
            sex: sexAnimal,
            age: selectedDate,
            hasIdNumber: identification,
            idNumber: numberIdentification,
            dateIdentification: dateIdentification,
            isBelonged: isBelonged,
            isSterilise: isSterilize,
            isSick: isSick,
            colors: colors,
            patterns: patterns,
            races: races,
            hairs: hairs,
            diseases: diseases,
            particularities: particularities,
            citySectorId: cityId,
            citySectorName: city,
            image: imageUrl,
            isFamily: isFamily,
            isMother: isMother,
            motherAppId: motherId,
            mother: mother,
            fatherAppId: fatherId,
            situationCat: selectedSituationCat,
            signal: true,
            published: false,
            commentSignal: commentSignal,
            userSignal: email,
            signalByOwner: isBelonged ? true : false,
            location: markers.length ? markers[0] : null
        };


        // console.log(data)
        // return;

        const subject = 'Un nouvel animal a été signalé sur un de vos canaux Kappze. ';
        const message = 'Bonjour, un nouvel animal est signalé dans le canal ' + canalInfos.name + '. Vous pouvez dès à présent le modérer.';
        const userIds = [];
        // const authorized = true;
        users.forEach(user => {
            if (user.isAdmin) {
                userIds.push(user?.id)
            }

        });

        try {
            const createdAnimal = await dispatch(addAnimal(data));

            const animalId = createdAnimal.payload.id;
            if (documents.length > 0) {
                const uploadedDocuments = await Promise.all(documents.map(uploadSingleFile, name));
                await dispatch(
                    addDocumentToAnimal({
                        animalId: animalId,
                        documents: uploadedDocuments,
                    })
                );
            }

            // dispatch(createAndSendNotification({ userIds, message, subject }));

            if (data?.isBelonged !== true) {
                dispatch(updateSignalsTotal({ uid }));

                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.reportTransmitted')}`,
                    text: `${t('modal.yourReportHasBeenTransmitted')}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })

            } else {
                Swal.fire({
                    icon: 'success',
                    title: `Votre chat a bien été pris en compte.`,
                    text: `Votre chat a bien été pris en compte. Vous serez notifié une fois validé par la modération.`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
            }



            setRequestInProcess(false);
            navigate(-1, { from: navigate.location });


        } catch (error) {
            console.error('Error adding object: ', error);
            setRequestInProcess(false);
            Swal.fire({
                icon: 'error',
                title: `${t('modal.error')}`,
                text: `${t('modal.anErrorOccurendWhenTryingToPublishTheAnimal')} ${error}`,
                confirmButtonColor: '#2F4F4F',
                confirmButtonText: 'OK',
                showClass: {
                    popup: 'animate__animated animate__fadeIn'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOut'
                }
            })
        }
    };

    const handleObjectFormCityChange = async (id, name) => {
        setCity(name);
        setCityId(id);

    };

    const handleObjectFormSubSectorChange = async (id, name) => {
        setSubSector(name);
        setSubSectorId(id);

    };

    const handleObjectFormAnimalChange = async (id, name) => {
        setMother(name);
        setMotherId(id);
    };

    const handleObjectFormAnimalSituationChange = async (id, situation) => {
        setAnimalSituation(situation);
        setSituationId(id);
    };



    const handleMarkerAdded = (marker) => {
        setMarkers([marker]); // Garde seulement un marqueur à la fois
    };


    const handleRobeChange = (event) => {
        if (event.target.checked) {
            setColor([...colors, event.target.value]);
        } else {
            setColor(colors.filter((color) => color !== event.target.value));
        }
    };

    const handlePatternChange = (event) => {
        if (event.target.checked) {
            setPattern([...patterns, event.target.value]);
        } else {
            setPattern(patterns.filter((pattern) => pattern !== event.target.value));
        }
    };


    const handleHairChange = (event) => {
        if (event.target.checked) {
            setHair([...hairs, event.target.value]);
        } else {
            setHair(hairs.filter((hair) => hair !== event.target.value));
        }
    };

    const handleRaceChange = (event) => {
        if (event.target.checked) {
            setRace([...races, event.target.value]);
        } else {
            setRace(races.filter((race) => race !== event.target.value));
        }
    };


    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // Créer un aperçu
            const previewUrl = URL.createObjectURL(file);
            setPreviewImage(previewUrl); // setPreviewImage est une fonction de useState pour stocker l'URL de l'aperçu
            setImageUrl(file); // setImageUrl est une fonction de useState pour stocker l'URL de l'image sur Firebase
        }
    };

    // useEffect(() => {
    //     // Trouver l'utilisateur actuel par uid
    //     const currentUser = users.find(user => user.id === uid);

    //     // Définir le rôle de l'utilisateur actuel, ou 'null' si non trouvé
    //     // (currentUser.isAdmin && !currentUser.isAssociation) || (currentUser.isAssociation && animal.linkedAssociationId includes currentUser.linkedAssociationids) || 
    //     // (!currentUser.isAssociation && currentUser.IsLinkedToAnAssociation && animal.linkedAssociationId includes currentUser.linkedAssociationIds) 
    //     // setUserRole(currentUser ? currentUser.isAdmin : null);

    //     setUserRole((false))
    //     setUserCreatorRole(true)

    //     console.log(currentUser)
    //     console.log(userIsCreator)

    // }, [users, uid, animal]);


    if (status === 'loading') {
        return <LoadingPage isLoading={true} />;
    }

    return (
        <div className='kappze-page kappze-paw'>
            <LoadingPage isLoading={false} />
            <BackButton needsBackground={false} />
            <div className='add-cat-page_section'>
                <form onSubmit={handleSubmit} className='authFormCat'>
                    {/* <div className="header-add-cat" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center'}}>
                
                <h2 style={{ color: 'white', textAlign: 'center' }}>Signaler un chat</h2>
                <img src={CatNotFound} style={{maxWidth: '100px'}} />
                </div> */}

                    <div className="create-animal-img-name" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', rowGap: '1rem' }}>

                        {!hasAnOwner && (<p style={{ fontWeight: '600', marginRight: '1rem' }}>Si vous disposez d'une photo : </p>)}
                        <div className="image-upload-container">
                            {previewImage ? (
                                <img src={previewImage} alt="Aperçu du chat" className="avatar-preview" onClick={triggerFileUpload} />
                            ) : (
                                <div className="avatar-placeholder" onClick={triggerFileUpload}>
                                    {/* Ici, vous pouvez mettre un icône ou un texte invitant à uploader une image */}
                                    <img src={imgIconWhite} style={{ width: '30px' }} />
                                </div>
                            )}
                            <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} ref={fileInputRef} />
                        </div>

                        <div>


                            <DynamicMap hasAnOwner={hasAnOwner} communeCode={canalInfos?.codeCommune} markers={markers} onMarkerAdded={handleMarkerAdded} />
                        </div>




                        {hasAnOwner && (<>
                            <label htmlFor="name" style={{ display: 'none' }}>{t('name')} </label>
                            <input required type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="auth-input auth-input-name" placeholder="Nom de l'animal" />
                        </>)}

                    </div>

                    {/* <div className='select-localization'>
                    <CityAndSectorSelect
                                idPrefix="modal"
                                cities={citiesSector}
                                selectedCityId={cityId}
                                onCityChange={handleObjectFormCityChange}
                            />

                            {(cityId && showSubSectors && subSectors.length) ? (
                                <CityAndSectorSelect
                                    idPrefix="modal"
                                    cities={subSectors}
                                    selectedCityId={subSectorId}
                                    onCityChange={handleObjectFormSubSectorChange}
                                    selectType="subSector"
                                />
                            ) : (null)}

                    </div> */}

                    {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                        <label htmlFor="appartenance">Il s'agit de mon chat </label>

                        <label className="switch">
                            <input type="checkbox" checked={isBelonged} onChange={(e) => setBelong(e.target.checked)} />
                            <span className="slider round"></span>
                        </label>
                    </div> */}


                    <div className="adding-cat-informations">

                        <div className="general-cat-informations bloc-cat-informations">
                            <h3 style={{ margin: '0', fontWeight: '600', marginBottom: '1rem' }}>Informations générales</h3>

                            {!hasAnOwner && (
                                <>
                                    <p style={{ color: '#000' }}>Essayez de remplir le maximum de champs possible. Signalez le secteur et toutes les informations dont vous possédez dans le champs "Description". <br />Votre signalement et votre email seront envoyés à la modération et pourra vous contacter au besoin.</p>
                                    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                        <label htmlFor="particularites">Description *</label>
                                        <textarea id="particularites" placeholder="Ex: Chat noir vu dans le secteur de la Boulangerie, je ne sais pas si il appartient à quelqu'un mais il n'est pas dans la liste ... " value={commentSignal} onChange={(e) => setCommentSignal(e.target.value)} className="auth-input" rows={4} cols={20} />
                                    </div>
                                </>
                            )}



                            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem', flexWrap: 'wrap', rowGap: '1rem' }}>
                                <label className='label-radio-animal'>Sexe </label>
                                <div className='radio-container-animal'>
                                    <input
                                        type="radio"
                                        id="male"
                                        name="sex"
                                        value="Mâle"
                                        checked={sexAnimal === 'Mâle'}
                                        onChange={(e) => setSex(e.target.value)}
                                    />
                                    <label htmlFor="male" className={`radio-text-animal ${sexAnimal === 'Mâle' && 'selected-radio-text-animal'}`}>Mâle</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="female"
                                        name="sex"
                                        value="Femelle"
                                        checked={sexAnimal === 'Femelle'}
                                        onChange={(e) => setSex(e.target.value)}
                                    />
                                    <label htmlFor="female" className={`radio-text-animal ${sexAnimal === 'Femelle' && 'selected-radio-text-animal'}`}>Femelle</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="unknown"
                                        name="sex"
                                        value="Inconnu"
                                        checked={sexAnimal === 'Inconnu'}
                                        onChange={(e) => setSex(e.target.value)}
                                    />
                                    <label htmlFor="unknown" className={`radio-text-animal ${sexAnimal === 'Inconnu' && 'selected-radio-text-animal'}`}>Inconnu</label>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem' }}>
                                <label htmlFor="sterilise">Le chat est stérilisé (vide si ne sait pas)</label>
                                <label className="switch">
                                    <input type="checkbox" checked={isSterilize} onChange={(e) => setSterilize(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>


                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem' }}>
                                <label htmlFor="birthdate">Date de naissance </label>
                                <input type="date" id="birthdate" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
                            </div>
                            <OpenableSection title='Couleurs et motifs' customClass="create-animal-page">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {/* <label >Couleurs de la robe </label> */}
                                    <ColorSelect selectedColors={colors} onChange={handleRobeChange} />
                                    <p style={{ color: 'black', fontWeight: '500' }}>Motifs sur le pelage</p>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {/* <label >Couleurs de la robe </label> */}
                                        <PatternSelect selectedPatterns={patterns} onChange={handlePatternChange} />

                                    </div>
                                </div>
                            </OpenableSection>
                            <OpenableSection title="Type de poil" customClass="create-animal-page">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <HairSelect selectedColors={hairs} onChange={handleHairChange} />
                                </div>
                            </OpenableSection>
                            <OpenableSection title="Race" customClass="create-animal-page">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <RaceSelect selectedRaces={races} onChange={handleRaceChange} />
                                </div>
                            </OpenableSection>

                            {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="image">Image </label>
                                <input type="file" id="image" onChange={(e) => setImage(e.target.files[0])} className="auth-input" />
                            </div> */}
                        </div>



                        <div className="id-cat-informations bloc-cat-informations">
                            <h3 style={{ margin: '0', fontWeight: '600', marginBottom: '1rem' }}>Identification</h3>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="identification">Le chat est-il identifié ?  (vide si ne sait pas)</label>
                                <label className="switch">
                                    <input type="checkbox" checked={identification} onChange={(e) => setIdentification(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="dateIdentification">Date d'identification </label>
                                <input type="date" id="dateIdentification" value={dateIdentification} onChange={(e) => setDateIdentification(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="numeroIdentification">Numéro d'identification (puce ou tatouage)</label>
                                <input type="text" id="numeroIdentification" value={numberIdentification} onChange={(e) => setNumberIdentification(e.target.value)} className="auth-input" />
                            </div>
                            {/* Bloc 2 */}


                            {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="appartenance">Le chat a un propriétaire (identifié ou non) </label>

                                <label className="switch">
                                    <input type="checkbox" checked={isBelonged} onChange={(e) => setBelong(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>
                            </div> */}
                        </div>


                        {hasAnOwner && (

                            <div className="relationship-cat-informations bloc-cat-informations">
                                <h3 style={{ margin: '0', fontWeight: '600', marginBottom: '1rem' }}>Lié à une famille</h3>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                    <label htmlFor="famille">Le chat est lié à une famille (vide si ne sait pas)</label>

                                    <label className="switch">
                                        <input type="checkbox" checked={isFamily} onChange={(e) => setFamily(e.target.checked)} />
                                        <span className="slider round"></span>
                                    </label>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                    <label htmlFor="mother">Le chat est une mère (vide si ne sait pas)</label>

                                    <label className="switch">
                                        <input type="checkbox" checked={isMother} onChange={(e) => setIsMother(e.target.checked)} />
                                        <span className="slider round"></span>
                                    </label>

                                </div>
                                <MotherSelect
                                    idPrefix="modal"
                                    animals={motherAnimals}
                                    selectedAnimalId={motherId}
                                    onAnimalChange={handleObjectFormAnimalChange}
                                    needsLabel={true}
                                    isEditMode={true}
                                />
                            </div>
                        )}




                        <div className="other-cat-informations bloc-cat-informations">
                            <h3 style={{ margin: '0', fontWeight: '600', marginBottom: '1rem' }}>Autre</h3>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="disease">Le chat semble malade ou blessé ? </label>

                                <label className="switch">
                                    <input type="checkbox" checked={isSick} onChange={(e) => setDisease(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="maladies">Maladies </label>
                                <input type="text" id="maladies" value={diseases} onChange={(e) => setDiseases(e.target.value)} className="auth-input" />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="particularites">Particularités </label>
                                <input type="text" id="particularites" value={particularities} onChange={(e) => setParticularities(e.target.value)} className="auth-input" />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <SituationSelect
                                    idPrefix="modal"
                                    animals={motherAnimals}
                                    selectedSituationCat={situationCat}
                                    onSituationChange={handleObjectFormAnimalSituationChange}
                                    needsLabel={true}
                                    isEditMode={true}
                                />
                            </div>

                        </div>

                        {hasAnOwner && (
                            <div className="other-cat-informations bloc-cat-informations">
                                <h3 style={{ margin: '0', fontWeight: '600', marginBottom: '1rem' }}>Documents</h3>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                    <label htmlFor="documents">Ajouter des documents </label>
                                    <input type="file" id="documents" multiple onChange={(e) => setDocuments([...e.target.files])} className="auth-input" />
                                </div>
                            </div>
                        )}


                    </div>

                    <button className="button-general" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '0' }} type="submit">{t('add')}
                        <img style={{ width: '28px', marginLeft: '5px' }} src={iconpaw} alt="Icon Paw" className="button-icon" />
                        <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" /></button>
                    {requestInProcess && <div className="loaderPaymentAddCat"></div>}
                </form>
            </div>
        </div>
    );
};

export default SignalAnimal;



