import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCanalUsers } from '../../features/canals/canalUsersSlice';
import BackButton from '../../components/general/BackButton';
import LoadingPage from '../../components/general/LoadingPage';
import StripeContainer from '../../stripe/StripeContainer';


const SubscribePage = () => {
    const { uid, isAuthenticated, status } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCanalUsers(uid));
        }
    }, [dispatch, uid, isAuthenticated]);

    if (status === 'loading') {
        return <LoadingPage isLoading={true} />;
    }
    return (
        <div className='kappze-page user-page' >
            <LoadingPage isLoading={false} />
            <BackButton />
            <div className="header-useful_links page-main">
                <h2>Abonnement Kappze</h2>

            </div>
            <div className="blocs paiment-page" style={{ padding: '0rem', display: 'flex', columnGap: '2rem', alignItems: 'flex-start' }}>
                <div className="bloc-1" style={{ width: '100%' }}>

                    <StripeContainer />
                </div>
                <div className="bloc-2 subscribe-bloc-2">
                    <h2 style={{ marginBottom: '1rem' }}>Informations importantes</h2>
                    <p>● Une fois le paiement validé, votre licence est automatiquement activée.
                        <br />Il peut être nécessaire de se reconnecter à votre compte pour que les modifications soient prises en compte.
                        <br /> <br />
                        ● Le renouvellement automatique n'est pas disponible. <br />
                        Vous recevrez une notification deux mois avant la fin de votre licence. <br />
                        Si vous ne la renouvelez pas dans ce délai, votre canal passera en mode lecture seule et les données ne pourront plus être modifiées
                        <br />Le renouvellement prend effet à la date de fin de la licence précédente. Si le renouvellement est effectué après la date de fin de la licence précédente, la nouvelle licence prendra effet à la date du nouvel achat.
                        <br /> <br /> ● En cas d'upgrade, la date d'expiration de la licence ne changera pas de la précédente licence.
                        <br /> <br /> ● Vous pourrez mettre à jour vos informations de facturation lors du prochain renouvellement. Au moment du paiement, il vous sera demandé de valider les nouvelles informations de facturation.
                        <br /> Étant donné le caractère unique de l'achat, vous disposez d'un délai de remboursement de 30 jours après la confirmation de l'achat.
                        <br /> <br /> ● Depuis votre profil, vous pouvez accéder à vos 10 derniers paiements. Contactez le support à "support@kappze.com" pour plus d'informations.
                        <br /> <br />Pour toute autre question relative aux paiements, vous pouvez consulter notre politique commerciale ou contacter directement le support par mail à "support@kappze.com". Le délai de réponse est de 24 heures.</p>
                </div>
                <div className="bloc-3">

                </div>
                <div className="bloc-4">
                </div>
            </div>

        </div>
    );
}

export default SubscribePage;