import React from 'react';
import TableGeneral from './TableGeneral';
import TableAnimals from './TableAnimals';
import TableConsult from './TableConsult';
import OpenableSection from './OpenableSection';
import kappzeCroquis from '../../assets/documentation/kappze-croquis-entite.png';
import kappzeCroquisAsso from '../../assets/documentation/kappze-croquis-asso.png';
import modalCreateCanal from '../../assets/documentation/add-canal.jpg';
import modalCreateSector from '../../assets/documentation/add-sector.jpg';
import createAnimal from '../../assets/documentation/doc-addcat.png';
import profileAnimal from '../../assets/documentation/doc-profilecat.png';
import genealogyAnimal from '../../assets/documentation/profile-cat-genealogy.jpg';
import filterRepresentation from '../../assets/documentation/doc-listingcat.png';
import listingCroquis from '../../assets/documentation/kappze-animals-croquis.png';
import editionSwitch from '../../assets/documentation/edit-mode.jpg';
import editionBtn from '../../assets/documentation/edit-btn.jpg';
import notificationMode from '../../assets/documentation/notification-mode.jpg';
import paymentBtns from '../../assets/documentation/votre-offre.jpg';
import signalAnimal from '../../assets/documentation/doc-signalcat.png';
import signalProfileAnimal from '../../assets/documentation/signalProfileAnimal.png';

import BackButton from '../general/BackButton';

const DocumentationContentMobile = () => {
    return (
        <div className="documentation-content-mobile">
            <div className="backBtnDoc" style={{ marginBottom: '1rem' }}>
                <BackButton />
            </div>

            <h1 style={{ textAlign: 'center', marginBottom: '2rem' }}>Documentation</h1>

            <OpenableSection title="Introduction">Kappze est une plate-forme destinée aux collectivités et acteurs œuvrant pour la gestion des animaux errants.

                Kappze a pour objectif de faciliter tous les processus concernant la gestion de chats errants. Ainsi, il peut être utilisé dans un but de collaboration, entre vétérinaires / associations-bénévoles / mairies / administrés.

                Objectif : lutter contre la prolifération tout en accordant la protection, via une organisation encadrée.</OpenableSection>

            <OpenableSection title="Inscription">Lors de votre inscription, il vous sera demandé si vous êtes une commune ou un visiteur.
                En tant qu'association ou bénévole, vous devez vous inscrire en tant que visiteur. La commune pourra vous attribuer un rôle suite à votre inscription.
            </OpenableSection>
            <OpenableSection title="Rôles">

                <div className="scroll">
                    <p>Rôles généraux ←→</p>
                    <TableGeneral />

                    <p>Rôles animaux ←→</p>

                    <TableAnimals />
                    <p>Rôles consultation ←→</p>
                    <TableConsult />
                </div>



            </OpenableSection>


            <OpenableSection title="Général">
                <p>Représentation graphique de la plateforme</p>
                <p>L'entité : </p>
                <img style={{ maxWidth: '250px' }} src={kappzeCroquis} />
                <p>L'association : </p>
                <img style={{ maxWidth: '250px' }} src={kappzeCroquisAsso} />
                <p>Une entité peut avoir plusieurs canaux, chacun des canaux peut avoir plusieurs secteurs.</p>
                <p>Pour créer un canal, il convient d'être pourvu d'une licence (pour une entité) ou d'un id donné par l'entité (pour une association).</p>
                <p></p>


            </OpenableSection>

            <OpenableSection title="Canal">
                <p>Un canal peut être :
                    Une commune, un arrondissement, ou autre.
                    Par exemple, des communautés de communes peuvent disposer de plusieurs communes (et donc répartis en canaux). Les grandes villes peuvent disposer de plusieurs arrondissements.
                    Une mairie individuelle peut disposer d'un seul canal, et à l'intérieur y créer des secteurs.</p>

                <p>Création d'un canal : </p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p style={{ color: 'black' }}>Outre le nom du canal, ces infos permettront aux utilisateurs de votre Canal
                        de pouvoir vous joindre rapidement.</p>
                    <img style={{ maxWidth: '250px' }} src={modalCreateCanal} />
                </div>


                <p>Une fois créé, le canal sera disponible dans votre listing. Vous pouvez y entrer et commencer à renseigner vos données.</p>
                <p>Vous pouvez par exemple ajouter d'hors est déjà un secteur, ou un animal. Vous pourrez accéder, une fois un secteur ajouté, à l'onglet secteur.</p>
            </OpenableSection>

            <OpenableSection title="Secteur">
                <p>Un secteur est un lieu. Vous pouvez disposez de plusieurs lieu dans la commune. Par exemple, si vous avez plusieurs colonies,
                    ou simplement qu'un chat appartient à un secteur en particulier. </p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p style={{ color: 'black' }}>Vous n'avez besoin que d'y ajouter un nom. Il sera ensuite disponible dans votre listing, et disponible lors de la création d'un animal par exemple.</p>
                    <img style={{ maxWidth: '250px' }} src={modalCreateSector} />
                </div>
            </OpenableSection>

            <OpenableSection title="Animal">
                <p>Vous pouvez ajouter autant de chats que vous le vouler. Chacun des chats a sa fiche d'informations remplissable lors de sa création, et modifiable. </p><br />


                <img style={{ maxWidth: '250px' }} src={createAnimal} />

                <p>En plus de disposer de ses informations, il y a la possibilité d'y ajouter des documents, de les consulter, mais aussi de pouvoir échanger via la section commentaires. <br />
                    Lorsque le chat est lié à une famille et à une mère préalablement renseignée, sa généalogie s'affichera.</p>

                <p>Profil d'un animal : </p>
                <img style={{ maxWidth: '250px' }} src={profileAnimal} />
                <p>Généalogie d'un animal : </p>
                <img style={{ maxWidth: '250px' }} src={genealogyAnimal} />

                <p>Chacun des animaux est cliquable, que ce soit dans le listing, ou dans la généalogie.</p>

                <p>Signalement d'un animal : </p>

                <img style={{ maxWidth: '500px' }} src={signalAnimal} />

                <p>Tous les invités ou habitants peuvent signaler un chat. Une fois le signalement rempli, l'animal n'est accessible
                    qu'à la modération. Elle décidera ensuite de si le signalement peut-être publié ou non.</p>

                <p>Modération d'un animal : </p>

                <img style={{ maxWidth: '500px' }} src={signalProfileAnimal} />

                <p>Après avoir eu accès au listing des animaux à modérer, le profil d'un animal se présentera sous cette forme pour les Administrateurs.
                    Vous pouvez modifier le signalement comme si vous modifiez un chat.
                    Tant qu'il n'est pas publié, il n'apparaîtra pas dans les listings. Si vous décidez de supprimer le signalement, le choix sera définitif.</p>

            </OpenableSection>

            <OpenableSection title="Listing">
                <p>Les listings sont une manière de pouvoir retrouver tous vos animaux, et de filtrer en conséquences. <br />
                    Tous vos animaux appartiennent à un canal, mais aussi à un secteur. </p>

                <img style={{ maxWidth: '250px' }} src={listingCroquis} />
                <p>
                    Il existe trois différents types de listings : </p>
                <ul>
                    <li>
                        Listing de canal : Regroupe tous les animaux du canal. Vous pourrez filtrer par secteur.
                    </li>
                    <li>
                        Listing de secteur : Regroupe tous les animaux du secteur auquel vous êtes.
                    </li>
                    <li>
                        Listing de liaison : Regroupe tous les animaux uniquement lié à celui sur lequel vous êtes. Tout comme le listing de canal, vous pourrez filtrer par secteur.
                    </li>
                    <li>
                        Listing d'association : Regroupe tous les animaux uniquement lié à une association. Vous avez la possibilité de filtrer par canal.
                    </li>
                </ul>
                <p>Chacunes des cartes regroupent les informations rapides, et sont cliquables pour amener directement sur la page de l'animal.</p>
                <img style={{ maxWidth: '250px' }} src={filterRepresentation} />
            </OpenableSection>

            <OpenableSection title="Mode édition">
                <p>En tant qu'Administrateur ou Association (contributeur inclus), vous pouvez avoir accès à des outils d'éditions. En tant que visiteur, votre mode édition n'est disponible que pour vos propres paramètres de profil.</p>
                <p>Le mode édition se caractérise par un " switch " , activant la modification. Une confirmation de donnée édité vous sera systématiquement affiché en cas de modification</p>

                <img style={{ maxWidth: '250px' }} src={editionSwitch} />
                <p>Pour modifier un élément en particulier il vous suffira de cliquer sur l'élément suivant : </p>
                <img style={{ maxWidth: '250px' }} src={editionBtn} />

            </OpenableSection>

            <OpenableSection title="Notifications">
            <p>Kappze propose un système de notifications : </p>
                <p>Pour les administrateurs : </p><ul>
                    <li>Ajout d'un chat</li>
                    <li>Signalement d'un chat</li>
                    <li>Signalement validé d'un chat</li>
                    <li>Nouveau commentaire publique</li>
                    <li>Nouveau commentaire privé</li>
                    <li>Nouveau document</li>
                </ul>
                <p>Pour détenteurs Kappze+ : </p><ul>
                    <li>Ajout d'un chat</li>
                    <li>Signalement validé d'un chat</li>
                    <li>Nouveau commentaire publique</li>
                </ul>
                <p>Si vous avez installé l'application MyKappze, vous recevrez les notifications directement sur votre téléphone. Sinon, elles vous seront envoyées par e-mail à l'adresse avec laquelle vous vous êtes inscrit. Vous avez la possibilité d'activer ou de désactiver ces notifications à partir de vos paramètres de profil.
                    Cette option est disponible pour les administrateurs de canaux, mais nécessite un abonnement Kappze+ pour les particuliers.</p>
                <img style={{ maxWidth: '500px' }} src={notificationMode} />
            </OpenableSection>

            <OpenableSection title="Paiements">
                <p>Comme vous le savez, Kappze nécessite des ressources pour la maintenance et le personnel. L'objectif principal de Kappze est de faciliter le travail de chaque partie prenante. C'est pourquoi Kappze propose un abonnement Kappze+, afin de permettre à tous de travailler dans les meilleures conditions possibles.
                    La licence est valable pour une année. En cas de non-renouvellement, tous les avantages seront retirés. Pour le moment, Kappze accepte uniquement les paiements par carte bancaire.
                    Sur votre page de profil, vous avez accès à deux boutons : "Acheter / Renouveler" et "Vos derniers paiements". Le prix de la licence est de 2€99/an.
                </p>

                <img style={{ maxWidth: '250px' }} src={paymentBtns} />
            </OpenableSection>

            <OpenableSection title="Kappze+">
            <p>Kappze+ est à 2€99 par an. Il doit être renouvelé chaquée année, par mesure de transparence le renouvellement automatique n'est pas disponible. Vous serez informés quelques jours avant la fin de votre abonnement.</p>
            <p>Kappze+ propose quelques avantages, dont les notifications pour les Visiteurs, la possibilité d'ajouter plus de 4 canaux, masquer les publicités ou encore avoir accès à la section commentaire publique.</p>
            <p>La version gratuite de Kappze pour les mairies est limitée à 20 animaux. La version standard (149€/an) propose jusqu'à 100 animaux, tandis que le premium propose l'illimité. (449€/an)</p>
            </OpenableSection>
        </div>
    );
}

export default DocumentationContentMobile;
