import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCitiesSector } from '../../features/citiesSector/citySectorSlice';
import { fetchAnimalsByCanal } from '../../features/animals/animalSlice';
import { createAndSendNotification } from '../../features/notifications/notificationSlice'
import { updateCanal, changeUserRole, updateCanalImage, updateCanalCoverImage, updateCanalList, updateCanalSectors, updateAdminMessageInCanal, updateCanalReachabilty, joinCanal, fetchUserSignalementsInCanal, joinCanalByEmail } from '../../features/canals/canalSlice';
import { fetchCanalUsers, removeUserFromCanal, joinAssociation, leaveAssociation } from '../../features/canals/canalUsersSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import heartblack from '../../assets/heart-circle.svg';
import heartwhite from '../../assets/heart-circle-sharp.svg';
import iconpersonwhite from '../../assets/person-circle.svg';
import iconadd from '../../assets/icon-add.svg';
import JoinAssoPopup from '../../components/canal/joinAssoPopup';
import JoinAssoByEmailPopup from '../../components/canal/joinCanalByEmailPopup';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Swal from 'sweetalert2';
import 'animate.css';
import ChevronLeft from '../../assets/chevron-back-outline.svg'
import ChevronRight from '../../assets/chevron-forward-outline.svg'
import { Tooltip } from 'react-tooltip';
import helpOutline from '../../assets/help-outline.svg';
import BannerPlaceHolder from '../../assets/placeholder-banner-kappze.png'
import CatNotFound from '../../assets/cat-not-found.png'
import iconupdate from '../../assets/icon-update.svg';
import logocolor from '../../assets/transparent-without-circle.png'
import copy from '../../assets/copypaste.svg';
import EditableField from '../../components/general/EditableField';
import BackButton from '../../components/general/BackButton';
import LoadingPage from '../../components/general/LoadingPage';
import { useTranslation } from 'react-i18next';
import { resetSiretState } from '../../features/siret/siretSlice';
import { fetchAssociationsDetails } from '../../features/association/associationSlice';

const SettingsCanal = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const { data: canals } = useSelector((state) => state.canals);
    const { data: animals } = useSelector((state) => state.animals);
    const { isAuthenticated, uid } = useSelector((state) => state.auth);
    const { data: users } = useSelector((state) => state.canalUsers);
    const associationsValidate = useSelector((state) => state.associations?.associationsDetails);
    const adminSiret = associationsValidate ? associationsValidate.flatMap(assoc => assoc.siret || []) : [];

    // const signalementsCount = useSelector(state => state.canals?.usersSignalsCount);

    // console.log('signalementsCount', signalementsCount)


    const [editableFields, setEditableFields] = useState([]);
    const [userRoles, setUserRoles] = useState({});
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showConfirmationUnlinkedCanal, setShowConfirmationUnlinkCanal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedCoverImage, setSelectedCoverImage] = useState(null);
    const [canal, setCanal] = useState(canals.find((canal) => canal.id === id));

    const [isListEnabled, setIsListEnabled] = useState(canal?.visibilityListEnabled);
    const [isSectorsEnabled, setIsSectorsEnabled] = useState(canal?.visibilitySectorsEnabled);
    const [isReachableEnabled, setIsReachableEnabled] = useState(canal?.canalReachable);

    const [showImagePopup, setShowImagePopup] = useState(false);
    const [showImageCoverPopup, setShowCoverImagePopup] = useState(false);
    const [showRolePopup, setShowRolePopup] = useState(false);

    const [showUnlinkPopup, setShowUnlinkPopup] = useState(false);
    const [selectedUserRole, setSelectedUserRole] = useState('');
    const [updatedUsers, setUpdatedUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [userIsAdmin, setUserRole] = useState({});
    const [userIsAssociation, setUserIsAssociation] = useState({});
    const [userIsBeta, setUserIsBeta] = useState({});
    const [userLinkedAssociationId, setUserLinkedAssociationId] = useState('');
    const [userIsLinkedToAnAssociation, setUserIsLinkedToAnAssociation] = useState(false);
    const [userAdminAssociationName, setUserAdminAssociationName] = useState('');
    const [userAdminAssociationId, setUserAdminAssociationId] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const [isEditMode, setIsEditMode] = useState(null);
    const [animationClass, setAnimationClass] = useState('');
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedCoverFileName, setSelectedCoverFileName] = useState('');
    const [itemOffset, setItemOffset] = useState('');
    const animalsStatus = useSelector((state) => state.animals.status);
    const canalsStatus = useSelector((state) => state.canals.status);
    const [currentPage, setCurrentPage] = useState(1);
    const [showCreateCanalPopup, setShowCreateCanalPopup] = useState(false);
    const [showJoinAssoPopup, setShowJoinAssoPopup] = useState(false);
    const [showLeftAssoPopup, setShowLeftAssoPopup] = useState(false);
    const [showJoinCanalPopup, setShowJoinCanalPopup] = useState(false);
    const [showJoinCanalByEmailPopup, setShowJoinCanalByEmailPopup] = useState(false);
    const [canalId, setCanalId] = useState('');
    const backgroundUrl = canal.imageCover?.url?.url ? canal.imageCover?.url?.url : canal.imageCover?.url ? canal.imageCover?.url : BannerPlaceHolder;
    const dispatch = useDispatch();

    // console.log('canal codecomme ; ', canal.codeCommune)

    const [message, setMessage] = useState(canal?.adminMessage);
    const [isActive, setIsActive] = useState(canal?.adminMessageActive);

    const associationsInCanal = useMemo(() => {
        return users.filter(user => user.isAssociation);
    }, [users]); // Assurez-vous que canalId est bien la dépendance correcte ici

    const handleSave = () => {
        dispatch(updateAdminMessageInCanal({ canalId: canal?.id, adminMessage: message, adminMessageActive: isActive }));
    };

    const handleFormSubmit = (e, codeCommune, uidAsso) => {
        e.preventDefault(); // Empêche le rechargement de la page
        // return;
        handleConfirmJoinCanal(e, codeCommune, uidAsso); // Appelle la fonction pour rejoindre le canal
    };

    const handleFormEmailSubmit = (email, canalId) => {
        // return;
        handleConfirmJoinCanalByEmail(email, canalId); // Appelle la fonction pour rejoindre le canal
    };

    const handleJoinAssoSubmit = (e, codeCommune, isLinkedToAnAssociation, linkedAssociationId) => {
        e.preventDefault();

        handleConfirmJoinAsso(e, codeCommune, isLinkedToAnAssociation, linkedAssociationId)
    }

    const handleShowJoinCanalPopup = () => {

        setAnimationClass('popup-entering');
        setShowJoinCanalPopup(true);;
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleShowJoinCanalByEmailPopup = () => {

        setAnimationClass('popup-entering');
        setShowJoinCanalByEmailPopup(true);;
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleCloseJoinCanalByEmailPopup = () => {

        setAnimationClass('popup-exiting');
        setShowJoinCanalByEmailPopup(false);
        setAnimationClass(null);
    };



    const isUserAlreadyInAssociation = (user) => {
        // Vérifiez si le tableau linkedAssociationIds de l'utilisateur contient le siretAdmin
        return user.linkedAssociationIds && user.linkedAssociationIds?.some(id => adminSiret.includes(id));
    };



    const handleConfirmJoinCanal = async (e, codeCommune, uidAsso) => {

        // console.log('DATA : ', data)
        e.preventDefault();
        if (!codeCommune) {
            alert('Aucune commune identifiée'); // Utilisez une alerte ou une notification appropriée ici
        } else {
            
            const resultAction = await dispatch(joinCanal({ userId: uidAsso, codeCommune: canal.codeCommune, isAdmin: true }));  // Here is the change

            if (joinCanal.fulfilled.match(resultAction)) {
                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.canalJoined')}`,
                    text: 'L\'association a bien rejoint le canal. Une notification lui a été envoyée.',
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
                setCanalId('');

                const message = `La commune de ${canal?.city} vous a ajouté comme administrateur. Vous avez désormais accès à la gestion des chats de cette commune.`;
                const subject = `La commune : ${canal?.city} vous a ajouté comme administrateur de canal.`;
                const authorized = true;
                const userIds = [];
                userIds.push(uidAsso)

                dispatch(createAndSendNotification({ userIds, message, subject, authorized }));
            } else {
                if (joinCanal.rejected.match(resultAction)) {
                    // Si une erreur personnalisée a été rejetée avec `rejectWithValue`
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${resultAction.payload} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.error')} ${resultAction.error.message} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                }
            }
            handleCloseJoinCanalPopup();
        }
    };



    const handleConfirmJoinCanalByEmail = async (email, canalId) => {

        // console.log('DATA : ', data)
       
        if (!email) {
            alert('Aucun compte identifié'); // Utilisez une alerte ou une notification appropriée ici
        } else {
            
            const resultAction = await dispatch(joinCanalByEmail({ isAdmin: true, email: email, cid: canalId }));  // Here is the change

            if (joinCanalByEmail.fulfilled.match(resultAction)) {
                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.canalJoined')}`,
                    text: 'Le contact a désormais accès à votre canal.',
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
                setCanalId('');

                const message = `L'association ${canal?.associationName} vous a ajouté comme administrateur. Vous avez désormais accès à la gestion des chats de cette commune.`;
                const subject = `L'association : ${canal?.associationName} vous a ajouté comme administrateur de canal.`;
                const authorized = true;
                const userIds = [];
                userIds.push(canalId)

                dispatch(createAndSendNotification({ userIds, message, subject, authorized }));
            } else {
                if (joinCanal.rejected.match(resultAction)) {
                    // Si une erreur personnalisée a été rejetée avec `rejectWithValue`
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${resultAction.payload} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.error')} ${resultAction.payload} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                }
            }
            handleCloseJoinCanalByEmailPopup();
        }
    };

    const handleConfirmJoinAsso = async (e, userId, isLinkedToAnAssociation, linkedAssociationId) => {

        // console.log('DATA : ', data)
        e.preventDefault();
        if (!userId) {
            alert('Aucun utilisateur trouvé'); // Utilisez une alerte ou une notification appropriée ici
        } else {
            const resultAction = await dispatch(joinAssociation({ userId: userId, isLinkedToAnAssociation: isLinkedToAnAssociation, linkedAssociationId: linkedAssociationId }));  // Here is the change

            if (joinAssociation.fulfilled.match(resultAction)) {

                // Mettre à jour l'état local avec les nouveaux détails de l'utilisateur
                const newUpdatedUsers = users.map(user => {
                    if (user.id === userId) {
                        // Ajouter l'ID de l'association sans dupliquer les valeurs existantes
                        const newLinkedIds = user.linkedAssociationIds ? (new Set([...user.linkedAssociationIds, linkedAssociationId])) : (user.linkedAssociationIds.push(linkedAssociationId));
                        return { ...user, linkedAssociationIds: Array.from(newLinkedIds), isLinkedToAnAssociation };
                    }
                    return user;
                });
                setFilteredUsers(newUpdatedUsers);

                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.canalJoined')}`,
                    text: 'L\'utilisateur a bien rejoint votre association. Une notification lui a été envoyée.',
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
                setCanalId('');

                const message = `${userAdminAssociationName} qui agît dans le canal de ${canal?.city} vous a ajouté comme contributeur. Vous avez désormais accès à la gestion de chats de ce canal.`;
                const subject = `Une association de : ${canal?.city} vous a ajouté comme contributeur de canal.`;
                const authorized = true;
                const userIds = [];
                userIds.push(userId)

                dispatch(createAndSendNotification({ userIds, message, subject, authorized }));


            } else {
                if (joinAssociation.rejected.match(resultAction)) {
                    // Si une erreur personnalisée a été rejetée avec `rejectWithValue`
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${resultAction.payload} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.error')} ${resultAction.error.message} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                }
            }
            handleCloseJoinAssoPopup();
        }
    };


    const handleConfirmLeftAsso = async (e, userId, isLinkedToAnAssociation, linkedAssociationId) => {

        // console.log('DATA : ', data)
        e.preventDefault();
        if (!userId) {
            alert('Aucun utilisateur trouvé'); // Utilisez une alerte ou une notification appropriée ici
        } else {
            const resultAction = await dispatch(leaveAssociation({ userId: userId, isLinkedToAnAssociation: isLinkedToAnAssociation, linkedAssociationId: linkedAssociationId }));  // Here is the change

            if (leaveAssociation.fulfilled.match(resultAction)) {

                const newUpdatedUsers = users.map(user => {
                    if (user.id === userId) {
                        // Retirer l'ID de l'association
                        const newLinkedIds = user.linkedAssociationIds.filter(id => id !== linkedAssociationId);

                        // Déterminer si l'utilisateur reste lié à une quelconque association
                        const isStillLinked = newLinkedIds.length > 0;

                        return { ...user, linkedAssociationIds: newLinkedIds, isLinkedToAnAssociation: isStillLinked };
                    }
                    return user;
                });

                setFilteredUsers(newUpdatedUsers);

                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.assoLeft')}`,
                    text: 'L\'utilisateur a bien été retiré de l\'association. Une notification lui a été envoyée.',
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
                setCanalId('');

                const message = `${canal?.city} - Vous n'êtes plus contributeur`;
                const subject = `Une association de : ${canal?.city} vous a retiré du rôle de contributeur.`;
                const authorized = true;
                const userIds = [];
                userIds.push(userId)

                dispatch(createAndSendNotification({ userIds, message, subject, authorized }));
            } else {
                if (leaveAssociation.rejected.match(resultAction)) {
                    // Si une erreur personnalisée a été rejetée avec `rejectWithValue`
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${resultAction.payload} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.error')} ${resultAction.error.message} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                }
            }
            handleCloseLeftAssoPopup();
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCitiesSector(id));
            dispatch(fetchAnimalsByCanal(id))
            dispatch(fetchCanalUsers(id));
        }
    }, [canal, dispatch, id, isAuthenticated]);



    useEffect(() => {
        if (canal && canal.id === id) {
            const fields = [
                { key: 'name', label: `${t('name')}`, value: canal.name },
                { key: 'email', label: `${t('email')}`, value: canal.email },
                { key: 'city', label: `${t('city')}`, value: canal.city },
                { key: 'postalCode', label: `${t('postalCode')}`, value: canal.postalCode },
                { key: 'communeCode', label: `Code Commune`, value: canal.codeCommune },
                { key: 'alias', label: `${t('aliasCanal')}`, value: canal.alias }
            ];

            setEditableFields(fields);
        } else {
            // Animal not available or ID mismatch, reset editableFields
            setEditableFields([]);
        }
    }, [canal, dispatch])

    useEffect(() => {
        // Initialiser userRoles avec les rôles actuels des utilisateurs
        const initialRoles = {};
        users.forEach(user => {
            initialRoles[user.id] = user.isAdmin;
        });
        setUserRoles(initialRoles);
    }, [users]);

    useEffect(() => {
        const currentCanal = canals.find((asso) => asso.id === id);
        if (currentCanal !== canal) {
            setCanal(currentCanal);
        }
    }, [canals]);

    useEffect(() => {
        setUpdatedUsers(users);
        // dispatch(fetchCanalUsers(id))
    }, [users, id]);


    useEffect(() => {
        // Trouver l'utilisateur actuel par uid
        const currentUser = users.find(user => user.id === uid);

        // Définir le rôle de l'utilisateur actuel, ou 'null' si non trouvé
        setUserRole(currentUser ? currentUser.isAdmin : null);
        setUserIsAssociation(currentUser ? currentUser.isAssociation : null)
        setUserIsBeta(currentUser ?  currentUser.isBeta : null)
        setUserLinkedAssociationId(currentUser ? currentUser.linkedAssociationId : null)
        setUserIsLinkedToAnAssociation(currentUser ? currentUser.isLinkedToAnAssociation : null)
        setUserAdminAssociationName(currentUser ? currentUser.associationName : null)
        setUserAdminAssociationId(currentUser ? currentUser.associationId : null)

    }, [users, uid, dispatch]);


    const handleCloseJoinCanalPopup = () => {

        setAnimationClass('popup-exiting');
        setShowJoinCanalPopup(false);
        setAnimationClass(null);
    };

    const handleCloseJoinAssoPopup = () => {

        setAnimationClass('popup-exiting');
        setShowJoinAssoPopup(false);
        setAnimationClass(null);
    };

    const handleCloseLeftAssoPopup = () => {

        setAnimationClass('popup-exiting');
        setShowLeftAssoPopup(false);
        setAnimationClass(null);
    };


    const handleUpdateField = (fieldKey, fieldData) => {
        const updatedData = {
            ...canal,
            ...(typeof fieldData === 'object' ? fieldData : { [fieldKey]: fieldData }),
        };
        const message = 'Notre nom canal a changé ! Il s\'appelle désormais ' + fieldData;
        const subject = 'Nouveau nom de canal ';
        const authorized = true;
        const userIds = [];
        users.forEach(user => {
            userIds.push(user?.id)
        });



        dispatch(updateCanal({ canalId: id, canalData: updatedData }))
            .unwrap()
            .then(() => {
                if (fieldKey = 'name') {
                    try {
                        dispatch(createAndSendNotification({ userIds, message, subject, authorized }));
                    } catch (error) {
                        console.error("An error occurred while sending the notification:", error);
                        
                    }

                }
                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.dataUpdatedTitle')}`,
                    text: `${t('modal.dataUpdated')}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
                setEditableFields((prevFields) => {
                    const updatedFields = prevFields.map((field) => {
                        if (field.key === fieldKey) {
                            return { ...field, value: fieldData };
                        }
                        return field;
                    });
                    return updatedFields;
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.errorUpdatingData')}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
            });
    };

    const handleDeleteConnexionCanal = (assoId) => {
        setAnimationClass('popup-entering');

        handleShowUnlinkPopup(assoId);
    }


    const confirmUnlinkUserCanal = (uid, aid) => {
        // Demande de confirmation supplémentaire avant la suppression de l'utilisateur
        Swal.fire({
            icon: 'warning',
            title: `${t('modal.attention')}`,
            text: `${t('modal.unlinkCanal')}`,
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#2F4F4F',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeUserFromCanal({ userId: uid, canalId: aid }))

            }
        }).then(() => {
            handleCloseUnlinkPopup()
            // dispatch(logout());
        })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.errorDeletingAccount')}`,
                    text: `${t('modal.errorDeletingAccount')} ${error.message}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })

            });
    }

    /* handle image actions */

    const handleImageChange = (event) => {
        setSelectedFileName(event.target.files[0].name);
        setSelectedImage(event.target.files[0]);
    };

    const handleUpdateImage = () => {
        setShowImagePopup(false);
        setAnimationClass(null);
        dispatch(
            updateCanalImage({ canalId: canal.id, image: selectedImage })
        )


            .unwrap()
            .catch((error) => {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.errorUpdatingData')} ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })

            });
    };

    const handleShowImagePopup = () => {
        setAnimationClass('popup-entering');
        setShowImagePopup(true);
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleCloseImagePopup = () => {
        setAnimationClass('popup-exiting');

        setTimeout(() => {


            setShowImagePopup(false);


        }, 450);

        setTimeout(() => {


            setAnimationClass(null);

        }, 500);

    };


    /* handle image actions */

    const handleCoverImageChange = (event) => {
        setSelectedCoverFileName(event.target.files[0].name);
        setSelectedCoverImage(event.target.files[0]);
    };

    const handleUpdateCoverImage = () => {
        setShowCoverImagePopup(false);
        setAnimationClass(null);
        dispatch(
            updateCanalCoverImage({ canalId: canal.id, image: selectedCoverImage })
        )


            .unwrap()
            .catch((error) => {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.errorUpdatingData')} ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })

            });
    };

    const handleShowCoverImagePopup = () => {
        setAnimationClass('popup-entering');
        setShowCoverImagePopup(true);
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleCloseCoverImagePopup = () => {

        setAnimationClass('popup-exiting');

        setTimeout(() => {


            setShowCoverImagePopup(false);


        }, 450);

        setTimeout(() => {


            setAnimationClass(null);

        }, 500);

    };

    const handleShowRolePopup = (userId) => {

        setAnimationClass('popup-entering');
        setShowRolePopup(true);
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
        setSelectedUserId(userId);
        setSelectedUserRole(userRoles[userId]);
    };

    const handleCloseRolePopup = () => {

        setAnimationClass('popup-exiting');

        setTimeout(() => {


            setShowRolePopup(false);


        }, 450);

        setTimeout(() => {


            setAnimationClass(null);

        }, 500);


    };


    const handleShowUnlinkPopup = (userId) => {
        setAnimationClass('popup-entering');
        setShowUnlinkPopup(true);
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
        setSelectedUserId(userId);
    };

    const handleCloseUnlinkPopup = () => {


        setAnimationClass('popup-exiting');

        setTimeout(() => {


            setShowUnlinkPopup(false);


        }, 450);

        setTimeout(() => {


            setAnimationClass(null);

        }, 500);
    };


    const handleUpdateUserRole = (userId, isAdmin) => {
        dispatch(
            changeUserRole({
                userId: String(userId), // Convertir l'ID de l'utilisateur en chaîne de caractères
                canalId: id,
                newIsAdmin: isAdmin,
            })
        )
            .unwrap()
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.success')}`,
                    text: `${t('modal.userRoleHasBeenUpdated')}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
                const updatedUsers = users.map((user) => {
                    if (user.id === userId) {
                        return { ...user, isAdmin: isAdmin };
                    }
                    return user;
                });
                setUpdatedUsers(updatedUsers); // Mettre à jour le state `updatedUsers` avec la liste mise à jour
                setShowRolePopup(false);
                const subject = `${t('notifications.youHaveGotANewRole')}`;
                const message = `${t('notifications.dearMemberNewRole')} ${isAdmin ? 'Administrateur' : 'Visiteur'} ${t('notifications.on')} ${canal?.name}.`
                const authorized = true;
                const userIds = [];

                userIds.push(userId)

                dispatch(createAndSendNotification({ userIds, message, subject, authorized }));


            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.errorWhileUpdatingTheUserRoleTitle')}`,
                    text: `${t('modal.errorWhileUpdatingTheUserRole')} ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
            });
    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(canal?.alias);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Réinitialiser après 2 secondes
        } catch (err) {
            console.error('Erreur de copie', err);
        }
    };

    const handleSearchChange = (e) => {
        e.preventDefault()
        let value = e.target.value.toLowerCase();

        if (value === '') {
            setFilteredUsers(users);
        } else {
            let filteredUsers = updatedUsers.filter(user =>
                user.name.toLowerCase().includes(value) ||
                user.surname.toLowerCase().includes(value) ||
                user.email.toLowerCase().includes(value)
            );
            setFilteredUsers(filteredUsers);
        }
        setCurrentPage(1); // Réinitialiser la page actuelle à 1
    };


    const usersPerPage = 6;

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);

    };

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = updatedUsers.slice(indexOfFirstUser, indexOfLastUser);
    const currentPageUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    
    const toggleSwitch = () => {
        setIsListEnabled(previousState => {
            // Si l'état précédent est null, retourne simplement true pour éviter les effets de bord
            if (previousState === null) {
                return true;
            }
            // Sinon, inverse l'état et continue avec la mise à jour
            const newState = !previousState;
            dispatch(updateCanalList({ newValue: newState, canalId: canal?.id }));
            return newState;
        });
    };

    const toggleAdminMessage = () => {
        setIsActive(previousState => {
            // Si l'état précédent est null, retourne simplement true pour éviter les effets de bord
            if (previousState === null) {
                return true;
            }
            // Sinon, inverse l'état et continue avec la mise à jour
            const newState = !previousState;
            return newState;
        });
    };

    const toggleSwitchSectors = () => {
        setIsSectorsEnabled(previousState => {
            // Si l'état précédent est null, retourne simplement true pour éviter les effets de bord
            if (previousState === null) {
                return true;
            }
            // Sinon, inverse l'état et continue avec la mise à jour
            const newState = !previousState;
            dispatch(updateCanalSectors({ newValue: newState, canalId: canal?.id }));
            return newState;
        });
    };

    const toggleSwitchReachable = () => {
        setIsReachableEnabled(previousState => {
            // Si l'état précédent est null, retourne simplement true pour éviter les effets de bord
            if (previousState === null) {
                return true;
            }
            // Sinon, inverse l'état et continue avec la mise à jour
            const newState = !previousState;
            dispatch(updateCanalReachabilty({ newValue: newState, canalId: canal?.id }));
            return newState;
        });
    };


    useEffect(() => {
        users.forEach(user => {
            dispatch(fetchUserSignalementsInCanal({ uid: user.id, canalId: canal?.id }));
        });
    }, [dispatch, users, canal]);


    useEffect(() => {
        if (associationsInCanal.length > 0) {
            const sirets = associationsInCanal.map(assoc => assoc.siretAsso).filter(siret => siret);
            // console.log("sirets : ", sirets)

            if (sirets.length > 0) {
                dispatch(fetchAssociationsDetails(sirets));
            }
        }
    }, [dispatch, associationsInCanal]); // Utilisation sûre car associationsInCanal est stabilisé par useMemo




    if (animalsStatus === 'loading' || canalsStatus === 'loading') {
        return <LoadingPage isLoading={true} />;
    }


    return (
        <div className='kappze-page canal-page filtered-page'>
            <BackButton />
            <LoadingPage isLoading={false} />
            <div className="page-main">
                <div className="canal-page_content">
                    <div className="canal-page_content-title" style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', backgroundImage: `url(${backgroundUrl})` }}>
                        <h1 className='page-title'>{canal.name}</h1>
                        <Tooltip id="my-tooltip-share-canal3" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', zIndex: 2 }}>
                            <div style={{ backgroundColor: '#122' }}>
                                <h3>{t('alias.shareChannel.title')}</h3>
                                <p>{t('alias.shareChannel.text1')}</p>
                                <ul>
                                    <li>{t('alias.shareChannel.list1')}</li>
                                    <li>{t('alias.shareChannel.list2')}</li>
                                </ul>
                            </div>
                        </Tooltip>
                                {(userIsAdmin === true) && (   
                        <div style={{ zIndex: 10 }}>

                            <button style={{ border: 'solid 1px white', borderBox: 'box-sizing', zIndex: 10 }} onClick={handleShowJoinCanalPopup} className='button-add-canal button-general-link'><span>Ajouter une association </span>
                                <img style={{ width: '20px', marginLeft: '5px' }} src={heartwhite} alt="Icon Add" className="button-icon" />
                                <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />

                            </button>
                        </div> )}

                        {(userIsAdmin === true && userIsAssociation) && (   
                        <div style={{ zIndex: 10 }}>

                            <button style={{ border: 'solid 1px white', borderBox: 'box-sizing', zIndex: 10 }} onClick={handleShowJoinCanalByEmailPopup} className='button-add-canal button-general-link'><span>Inviter un membre </span>
                                <img style={{ width: '20px', marginLeft: '5px', color: '#fff' }} src={iconpersonwhite} alt="Icon Add" className="button-icon" />
                                <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />

                            </button>
                        </div> )}


                    <JoinAssoPopup
                            show={showJoinCanalPopup}
                            handleClose={handleCloseJoinCanalPopup}
                            handleConfirm={handleFormSubmit}
                            canals={canals}
                            animation={animationClass}
                            users={users}
                        />


                        <JoinAssoByEmailPopup
                            show={showJoinCanalByEmailPopup}
                            handleClose={handleCloseJoinCanalByEmailPopup}
                            handleConfirm={handleFormEmailSubmit}
                            canalId={canal?.id}
                            animation={animationClass}
                            users={users}
                        />

                        {/* <AddUserToAssoPopup
                            show={setShowAssociationPopup}
                            handleClose={handleCloseJoinAssoPopup}
                            handleConfirm={handleJoinAssoSubmit}
                            associations={associationsValidate}
                            animation={animationClass}
                            users={users} /> */}



                    </div>
                    {/* {userIsAdmin === true && (<div style={{ display: 'flex', flexDirection: 'row', columnGap: '2rem', justifyContent: 'center', marginBottom: '1rem', width: '100%', marginTop: '1rem' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', justifyContent: 'flex-end' }}>
                            <label htmlFor="editing">Autoriser les visiteurs à accéder aux listes </label>
                            <label className='switch'>
                                <input type="checkbox"
                                    checked={isListEnabled} onChange={toggleSwitch} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    )} */}
                    {((userIsAdmin === true && (!userIsAssociation)) || (userIsAdmin === true && userIsAssociation && userIsBeta)) && (<div className="canal-options" style={{ display: 'flex', flexDirection: 'row', columnGap: '2rem', justifyContent: 'space-between', width: '100%', marginBottom: '1rem', marginTop: '2rem' }}>

                        <div style={{ display: 'flex', flexDirection: 'column', columnGap: '1rem', rowGap: '2rem', justifyContent: 'flex-end' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem' }}>
                                <label htmlFor="editing">Autoriser les visiteurs à accéder aux listes de chats</label>
                                <label className='switch'>
                                    <input type="checkbox"
                                        checked={isListEnabled} onChange={toggleSwitch} />
                                    <span className="slider round"></span>
                                </label>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem' }}>
                                <label htmlFor="editing">Autoriser les visiteurs à visualiser les secteurs</label>
                                <label className='switch'>
                                    <input type="checkbox"
                                        checked={isSectorsEnabled} onChange={toggleSwitchSectors} />
                                    <span className="slider round"></span>
                                </label>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem' }}>
                                <label htmlFor="editing">Autoriser les visiteurs à rejoindre le canal</label>
                                <label className='switch'>
                                    <input type="checkbox"
                                        checked={isReachableEnabled} onChange={toggleSwitchReachable} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>



                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', justifyContent: 'flex-end' }}>
                            <label htmlFor="editing">{t('editMode')} </label>
                            <label className='switch'>
                                <input type="checkbox"
                                    checked={isEditMode} onChange={(e) => setIsEditMode(e.target.checked)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    )}
                </div>
                <div className="section-informations">
                    <div className="section-informations_canal bloc-information">
                        <p style={{ fontWeight: '600' }}>{t('generalInformations')} :</p>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <div className="section-informations_canal-general">
                                {editableFields.map((field) => {
                                    return (
                                        <EditableField
                                            key={field.key}
                                            label={field.label}
                                            value={field.value}
                                            onSave={(value) => handleUpdateField(field.key, value)}
                                            isAdmin={userIsAdmin}
                                            isEditMode={isEditMode}
                                            customClass="settings-label"
                                        />)
                                })}
                            </div>


                            <div className="img-canal">
                                <p style={{ color: 'black', textDecoration: 'underline', fontWeight: 'bolder' }}>Image :  </p>
                                {canal.image?.url?.url ? (
                                    <LazyLoadImage effect="blur" src={canal.image.url.url} style={{ objectFit: 'contain', maxWidth: '150px' }} alt={`Image de ${canal.name}`} />) : canal.image?.url ? (
                                        <LazyLoadImage effect="blur" src={canal.image.url} style={{ objectFit: 'contain', maxWidth: '150px' }} alt={`Image de ${canal.name}`} />
                                    ) : (<LazyLoadImage effect="blur" src={CatNotFound} style={{ objectFit: 'contain', maxWidth: '150px' }} alt={`Image de ${canal.name}`} />
                                )}
                                {userIsAdmin === true && (
                                    <button className={`button-update ${isEditMode ? 'visible' : 'hidden'}`} onClick={handleShowImagePopup}>
                                        <img style={{ width: "14px", margin: "2px" }} src={iconupdate} alt="Icon Update" className="button-icon" />
                                    </button>)}
                                {showImagePopup && (
                                    <div className={`popup-container ${animationClass}`}>
                                        <div className="popup">
                                            <div className="popup-logo">
                                                <img src={logocolor} />
                                            </div>
                                            <h2 style={{ color: 'black', marginTop: '1rem' }}>Modifier l'image de profil</h2>

                                            <div className="popup-singleinput">
                                                {/* Ajoutez ici le contenu de la pop-up pour la modification de l'image */}

                                                <div className="chooseFile" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <label htmlFor="fileInput" className="custom-file-button">Choisir un fichier</label>

                                                    <input type="file" className="hidden-input" id="fileInput" onChange={handleImageChange} />
                                                    <span style={{ color: '#000' }}>{selectedFileName}</span>
                                                </div>


                                            </div>
                                            <div className="popup-btns">
                                                <button className="btn-cancel" onClick={handleCloseImagePopup}>Annuler</button>
                                                {selectedFileName && (
                                                    <button className="btn-validate" onClick={handleUpdateImage}>Valider</button>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="img-cover-canal">
                                <p style={{ color: 'black', textDecoration: 'underline', fontWeight: 'bolder' }}>Image de couverture :  </p>
                                {canal.imageCover?.url?.url ? (
                                    <LazyLoadImage effect="blur" src={canal.imageCover.url.url} style={{ objectFit: 'contain', maxWidth: '150px' }} alt={`Image de ${canal.name}`} />) : canal.imageCover?.url ? (
                                        <LazyLoadImage effect="blur" src={canal.imageCover.url} style={{ objectFit: 'contain', maxWidth: '150px' }} alt={`Image de ${canal.name}`} />
                                    ) : (<LazyLoadImage effect="blur" src={BannerPlaceHolder} style={{ objectFit: 'contain', maxWidth: '150px' }} alt={`Image de ${canal.name}`} />
                                )}
                                {userIsAdmin === true && (
                                    <button className={`button-update ${isEditMode ? 'visible' : 'hidden'}`} onClick={handleShowCoverImagePopup}>
                                        <img style={{ width: "14px", margin: "2px" }} src={iconupdate} alt="Icon Update" className="button-icon" />
                                    </button>)}
                                {showImageCoverPopup && (
                                    <div className={`popup-container ${animationClass}`}>
                                        <div className="popup">
                                            <div className="popup-logo">
                                                <img src={logocolor} />
                                            </div>
                                            <h2 style={{ color: 'black', marginTop: '1rem' }}>Modifier l'image de couverture</h2>

                                            <div className="popup-singleinput">
                                                <div className="chooseFile" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <label htmlFor="fileInput" className="custom-file-button">Choisir un fichier</label>

                                                    <input type="file" className="hidden-input" id="fileInput" onChange={handleCoverImageChange} />
                                                    <span style={{ color: '#000' }}>{selectedCoverFileName}</span>
                                                </div>


                                            </div>
                                            <div className="popup-btns">
                                                <button className="btn-cancel" onClick={handleCloseCoverImagePopup}>Annuler</button>
                                                {selectedCoverFileName && (
                                                    <button className="btn-validate" onClick={handleUpdateCoverImage}>Valider</button>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div style={{ width: '5%' }}></div>
                        </div>



                    </div>
                    <div className="section-informations_canal-members bloc-information">
                        <p style={{ fontWeight: '600' }}>{t('administrators')} :</p>
                        {users.map((user) => (
                            <>
                                {user.isAdmin && (

                                    <p style={{ color: 'black', wordBreak: 'break-all' }} key={user.id}> - {user.surname} {user.name} / {user.email}</p>

                                )}
                            </>
                        ))}
                    </div>
                </div>



                {(userIsAdmin) && (

                    <div className="section-informations">
                        <div className="section-informations_canal-members bloc-information" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', rowGap: '1rem' }}>
                            <p style={{ fontWeight: '600' }}>Informer vos utilisateurs :</p>
                            <div>
                                <textarea style={{
                                    width: '100%', // Rend le textarea responsive en largeur
                                    height: 'auto', // Optionnel : ajuste la hauteur au contenu
                                    minHeight: '80px', // Hauteur minimale basée sur votre "rows" initial
                                    resize: 'vertical', // Permet à l'utilisateur de redimensionner verticalement
                                }} value={message} onChange={(e) => setMessage(e.target.value)} />
                            </div>

                            <div className="show-banner-user-message" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem' }}>
                                <p style={{ color: '#000' }}>Afficher le message aux utilisateurs : </p>
                                <label className='switch'>
                                    <input type="checkbox"
                                        checked={isActive} onChange={(e) => toggleAdminMessage()} />
                                    <span className="slider round"></span>
                                </label>
                            </div>

                            <button onClick={handleSave}>Sauvegarder</button>
                        </div>
                    </div>

                )}



                <div className="canal-users">
                    <h3>{t('memberManagement')} :</h3>
                    <input
                        type="text"
                        placeholder={t('searchForAMember')}
                        onChange={handleSearchChange}
                        className='searching-member'
                    />


                    {currentPageUsers.map((user) => (
                        <div className="bloc-information" key={user.id}>
                            {user.isAssociation && (<div style={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
                                <div className="pastille_role pastille_role-association">
                                    <span className="pastille_role-admin-text">{t('association')}</span>
                                </div><div style={{ color: '#122', marginTop: '1.2rem' }}><p style={{ color: '#122' }}>{user.associationName}</p></div>
                            </div>
                            )}
                            {user.isLinkedToAnAssociation && !user.isAssociation && (<div style={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
                                <div className="pastille_role pastille_role-contributor">
                                    <span className="pastille_role-admin-text">{t('contributor')}</span>
                                </div><div style={{ color: '#122', marginTop: '1.2rem' }}><p style={{ color: '#122' }}>{user.associationName}</p></div>
                            </div>
                            )}
                            <p style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{user.surname} {user.name},<br /> {user.email}</p>
                            {/* <p style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>Nombre de signalements: {signalementsCount[user?.id] || 0}</p> */}


                            <p style={{ fontWeight: 'bold' }}>{user.isAdmin ? (t('administrator')) : user.isLinkedToAnAssociation ? (t('contributor')) : (t('visitor'))}</p>
                            {(user.id !== canal.adminId)  ? (
                                <>
                                    <div className="role-modification">
                                    {userIsAdmin && (<button onClick={() => handleShowRolePopup(user.id)}>{t('editRole')}</button>)}
                                        

                                        {showUnlinkPopup && selectedUserId && (
                                            <div className={`popup-container ${animationClass}`}>
                                                <div className="popup">
                                                    <div className="popup-logo">
                                                        <img src={logocolor} />
                                                    </div>
                                                    <h2 style={{ color: 'black' }}>Désassocier l'utilisateur</h2>
                                                    <div className="popup-singleinput">
                                                        <p style={{ color: 'black' }}>Voulez-vous vraiment supprimer la liaison avec ce canal ?</p>
                                                    </div>
                                                    <div className="popup-btns">
                                                        <button
                                                            onClick={() => confirmUnlinkUserCanal(selectedUserId, canal.id)}
                                                            style={{ background: 'rgb(135, 41, 41)', color: 'white' }}
                                                        >
                                                            OUI
                                                        </button>
                                                        <button
                                                            onClick={handleCloseUnlinkPopup}
                                                        >
                                                            NON
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        )}

                                        {!showConfirmationUnlinkedCanal && (
userIsAdmin === true && (                        <button
                                                onClick={() => {
                                                    handleDeleteConnexionCanal(canal.id);
                                                    setSelectedUserId(user.id);
                                                }}
                                                className='btn-supp'

                                            >
                                                {t('removeThisChannelMember')}
                                            </button>

)
                    
                                        )}
                                    </div>
                                </>
                            ) : (userIsAdmin) && (user.id === canal.adminId)  ? (
                                <>
                                    <p>Super Admin. {t('roleCannotBeChanged')}</p>
                                </>
                            ) : (<></>)}



                            <div className='role-modification' style={{flexWrap: 'wrap'}}>






                                {userIsAdmin && userIsAssociation && (!user.isAssociation) ? (
                                    <div>
                                        <button
                                            onClick={() => {
                                                setShowJoinAssoPopup(true);
                                                setSelectedUserId(user.id);
                                            }}
                                            disabled={isUserAlreadyInAssociation(user)}
                                            className={isUserAlreadyInAssociation(user) ? 'joinedButton' : 'btn-join'}
                                        >
                                            {isUserAlreadyInAssociation(user) ? t('userAlreadyInAssociation') : t('addThisUserToAssociation')}
                                        </button>

                                        {showJoinAssoPopup && selectedUserId === user.id && (
                                            <div className={`popup-container ${animationClass}`}>
                                                <div className="popup">
                                                    <div className="popup-logo">
                                                        <img src={logocolor} />
                                                    </div>
                                                    <h2 style={{ color: 'black' }}>{t('confirmAddUserToAssociation')}</h2>
                                                    <p style={{ color: 'black' }}>{t('doYouWantToAddUserToAssociation')}</p>
                                                    <div className="popup-btns">
                                                        <button
                                                            onClick={(e) => handleConfirmJoinAsso(e, selectedUserId, true, userAdminAssociationId)}
                                                            className="btn-validate"
                                                        >
                                                            {t('yes')}
                                                        </button>
                                                        <button
                                                            onClick={() => setShowJoinAssoPopup(false)}
                                                            className="btn-cancel"
                                                        >
                                                            {t('no')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {userIsAdmin && userIsAssociation && isUserAlreadyInAssociation(user) && !user.isAssociation ? (
                                    <div>
                                        <button
                                            onClick={() => {
                                                setShowLeftAssoPopup(true);
                                                setSelectedUserId(user.id);
                                            }}
                                            disabled={!isUserAlreadyInAssociation(user)}
                                            className={isUserAlreadyInAssociation(user) ? 'btn-supp' : 'btn-masked'}
                                        >
                                            {t('excludeFromAsso')}
                                        </button>

                                        {showLeftAssoPopup && selectedUserId && (
                                            <div className={`popup-container ${animationClass}`}>
                                                <div className="popup">
                                                    <div className="popup-logo">
                                                        <img src={logocolor} />
                                                    </div>
                                                    <h2 style={{ color: 'black' }}>{t('confirmRemoveUserToAssociation')}</h2>
                                                    <p style={{ color: 'black' }} >{t('doYouWantToRemoveUserFromAssociation')}</p>
                                                    <div className="popup-btns">
                                                        <button
                                                            onClick={(e) => handleConfirmLeftAsso(e, selectedUserId, userIsLinkedToAnAssociation, userLinkedAssociationId)}
                                                            className="btn-validate"
                                                        >
                                                            {t('yes')}
                                                        </button>
                                                        <button
                                                            className="btn-cancel"
                                                            onClick={() => setShowLeftAssoPopup(false)}
                                                        >
                                                            {t('no')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <></>
                                )}




                            </div>

                        </div>
                    ))}

                    <div className='pagination'>
                        {filteredUsers.length > usersPerPage && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button
                                    className='paginationButton'
                                    onClick={() => paginate(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    <img src={ChevronLeft} alt="Précédent" style={{ width: '20px' }} />
                                </button>
                                <span className='paginationText' style={{ color: '#ddd' }}>
                                    {currentPage} / {Math.ceil(filteredUsers.length / usersPerPage)}
                                </span>
                                <button
                                    className='paginationButton'
                                    onClick={() => paginate(currentPage + 1)}
                                    disabled={currentPage === Math.ceil(filteredUsers.length / usersPerPage)}
                                >
                                    <img src={ChevronRight} alt="Suivant" style={{ width: '20px' }} />
                                </button>
                            </div>
                        )}
                    </div>
                </div>



            </div>

            {/* Role Popup */}
            {showRolePopup && selectedUserId && (
                <div className={`popup-container ${animationClass}`}>
                    <div className="popup">
                        <div className="popup-logo">
                            <img src={logocolor} />
                        </div>
                        <h2 style={{ color: 'black' }}>Modifier le rôle de l'utilisateur</h2>
                        <div className="popup-singleinput">
                            <select
                                value={selectedUserRole}
                                onChange={(e) => setSelectedUserRole(e.target.value)}
                            >
                                <option value="true">{t('administrator')}</option>
                                <option value="false">{t('visitor')}</option>
                            </select>
                        </div>
                        <div className="popup-btns">

                            <button className="btn-cancel" onClick={handleCloseRolePopup}>Annuler</button>
                            <button className="btn-validate" onClick={() => handleUpdateUserRole(selectedUserId, selectedUserRole === 'true')}>
                                Enregistrer
                            </button>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
};


export default SettingsCanal;
