import React from 'react';

const ProgressBar = ({ numberOfAnimals, maximumOfAnimals }) => {
    const percentage = (numberOfAnimals / maximumOfAnimals) * 100;
    let barColor = '#2f4f4f'; // Vert par défaut

    if (percentage >= 100) {
        barColor = '#f44336'; // Rouge à 100%
    } else if (percentage >= 70) {
        barColor = '#ff9800'; // Orange à 70%
    }

    return (
        <div className="progress-container">
            <div className="progress-bar" style={{ width: `${percentage}%`, backgroundColor: barColor }}>
                <span style={{ color: '#fff', fontWeight: 'bolder', marginLeft: '1rem' }}>{numberOfAnimals}</span>
            </div>
            <div className="progress-max" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
            <span style={{ color: '#ddd', fontWeight: 'bolder', marginRight: '1rem' }}>{maximumOfAnimals}</span>
            </div>
        </div>
    );
};

export default ProgressBar;
