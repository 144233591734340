import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'animate.css';
import { updateEmail, updateUserEmail, updatePassword, updateUserSurname, updateUserName, logout, deleteUser, updateUserNotificationPreference, updateSiret, updateUserNewsletterPreference } from '../features/user/userSlice';
import EditableField from '../components/general/EditableField';
import { leaveAssociation, removeUserFromCanal } from '../features/canals/canalUsersSlice';
import { fetchCanals, removeCanal } from '../features/canals/canalSlice';
import { fetchAnimals } from '../features/animals/animalSlice.js';
import { fetchAnimalsCountByCanals } from '../features/licences/licenceSlice';
import BackButton from '../components/general/BackButton';
import LoadingPage from '../components/general/LoadingPage';
import { fetchLicenceById } from '../features/licences/licenceSlice';
import { formatDateToFrench } from '../utils/formatDate';
import { useTranslation } from 'react-i18next';
import Sparkles from "../assets/sparkles-outline-gold.svg";
import { createAndSendNotification } from '../features/notifications/notificationSlice.jsx';
import { validateSiret } from '../utils/errorMessages.js';
import ProgressBar from '../components/general/ProgressBar.jsx';
import { fetchAssociationsFromUserId } from '../features/user/fetchingUserSlice.js';
import { fetchSingleAssociationDetails } from '../features/association/associationSlice.js';




const Settings = () => {
    const { t } = useTranslation();



    const { userId } = useParams();
    const { name, surname, email, isAuthenticated, uid, licenceNumber, registrationDate, notificationsEnabled, siret, siretAsso, isMairie, newsletterConsent, isLinkedToAnAssociation, isLinkedToAnAssociationId, userType } = useSelector((state) => state.auth);

    // console.log('licence Status --> ', licenceStatus)
    const { data: canals } = useSelector((state) => state.canals);
    const { data: users } = useSelector((state) => state.canalUsers);
    const [isEnabled, setIsEnabled] = useState(notificationsEnabled);
    const [isNewsletterConsent, setNewsletterConsent] = useState(newsletterConsent);
    const associationsValidate = useSelector((state) => state.associations?.associationsDetails);
    const [password, setPassword] = useState('');
    const user = users.find((user) => user.id === userId)
    const registrationDateObj = new Date(registrationDate);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [editableFields, setEditableFields] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationUnlinkedCanal, setShowConfirmationUnlinkCanal] = useState(false);
    const [showConfirmationDeleteCanal, setShowConfirmationDeleteCanal] = useState(false);
    const [showConfirmationUnlinkedAssociation, setShowConfirmationUnlinkAssociation] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const [showLeftAssoPopup, setShowLeftAssoPopup] = useState(false);
    const [selectedCanalId, setSelectedCanalId] = useState(null);
    const [selectedAssociationId, setSelectedAssociationId] = useState(null);
    const [animalsCount, setAnimalsCount] = useState(0);
    // const [associationsUser, setAssociationsForUser] = useState([])
    const [associationsDetails, setAssociationsDetails] = useState([]);

    const  associationsUser  = useSelector((state) => state.users?.associationsIdsContributor)

    const [creatorCanal, setCreatorCanal] = useState([]);
    const [userIsAdmin, setUserRole] = useState(null);
    const currentLicenceDetails = useSelector((state) => state.licences?.licence);
    const productType = useSelector((state) => state.auth?.productType);
    const licenceStatus = useSelector((state) => state.auth?.status);
    const [isEditMode, setIsEditMode] = useState(null);

    const animalsStatus = useSelector((state) => state.animals.status);
    const animalsLicence = useSelector((state) => state.animals);
    const numberOfAnimals = animalsLicence?.data?.length;
    const canalsStatus = useSelector((state) => state.canals.status);
    const [canalsIds, setCanalsIds] = useState('');


    // console.log('Animals Status --->', numberOfAnimals)

    // console.log(licenceNumber)

    const maximumOfAnimals = productType === 4 ? 30 : productType === 2 ? 100 : productType === 3 ? 1000 : null;

    // console.log(maximumOfAnimals, productType)

    useEffect(() => {

        if (isAuthenticated && isMairie === true) {
            dispatch(fetchLicenceById(licenceNumber));
        } 
    }, [isAuthenticated, licenceNumber, userId, licenceStatus, dispatch]);

    useEffect(() => {

        if (isAuthenticated) {
            dispatch(fetchAssociationsFromUserId({userId: userId}));
        } 
    }, [isAuthenticated, licenceNumber, userId, dispatch]);



    // console.log(licenceNumber)
    useEffect(() => {
        if ((uid && uid === userId) && (siret || siretAsso)) {
            const fields = [
                { key: 'name', label: `${t('name')}`, value: name },
                { key: 'surname', label: `${t('surname')}`, value: surname },
                { key: 'email', label: `${t('email')}`, value: email },
                { key: 'password', label: `${t('password')}`, value: password }, // Update this line to remove the password value
                { key: 'siret', label: `Siret`, value: siret },
                { key: 'organizationSiret', label: `Siret Asso`, value: siretAsso },
            ];
            setEditableFields(fields);
        } else if ((uid && uid === userId) && (!siret || !siretAsso)) {

            const fields = [
            { key: 'name', label: `${t('name')}`, value: name },
            { key: 'surname', label: `${t('surname')}`, value: surname },
            { key: 'email', label: `${t('email')}`, value: email },
            { key: 'password', label: `${t('password')}`, value: password }, // Update this line to remove the password value
        
            ]
            setEditableFields(fields);
        } else {
            setEditableFields([]);
        }
    }, [userId]);

    // Utilisation d'effet pour mettre à jour canalsIds quand canals change
    // useEffect(() => {
    //     if (canals) {
    //         // Créer un nouveau tableau avec seulement les IDs des canals
    //         if(userType === 'mairie') {
    //             const extractedIds = canals.map(canal => canal?.id); // Remplacez 'id' par la clé appropriée pour l'ID du canal
    //             setCanalsIds(extractedIds);
    //         } else if (userType === "organization") {
    //             const extractedIds = creatorCanal.map(canal => canal?.id); // Remplacez 'id' par la clé appropriée pour l'ID du canal
    //             setCanalsIds(extractedIds);
    //         }

    //     }
    // }, [canals]); // Dépendance à canals pour que l'effet s'exécute quand canals change

    // useEffect(() => {
    //     if (canalsIds.length > 0 && userId) {
    //         fetchAnimalsCountByCanals(canalsIds, userId)
    //             .then(count => {
    //                 setAnimalsCount(count);
    //             })
    //             .catch(error => {
    //                 console.error('Error fetching animals count:', error);
    //             });
    //     } else {
    //         console.log('settings canals ids : ', canalsIds)
    //     }
    // }, [canalsIds, userId]);

    useEffect(() => {
        const updateCanalsIdsAndFetchAnimals = async () => {
            let extractedIds = [];
            if (canals) {
                if (userType === 'mairie') {
                    extractedIds = canals.map(canal => canal?.id); // Remplacez 'id' par la clé appropriée pour l'ID du canal
                } else if (userType === "association") {
                    extractedIds = creatorCanal.map(canal => canal?.id); // Remplacez 'id' par la clé appropriée pour l'ID du canal
                }
                setCanalsIds(extractedIds);
            }
    
            if (extractedIds.length > 0 && userId) {
                try {
                    const count = await fetchAnimalsCountByCanals(extractedIds, userId);
                    setAnimalsCount(count);
                } catch (error) {
                    console.error('Error fetching animals count:', error);
                }
            }
        };
    
        updateCanalsIdsAndFetchAnimals();
    }, [canals, userId, userType]);

    
    useEffect(() => {
        if (isAuthenticated && userId) {
            dispatch(fetchCanals(userId));
            if (uid && uid === userId) {
                let creatorCanals = [];

                for (let canal of canals) {
                    if (Array.isArray(canal.role)) {
                        for (let role of canal.role) {
                            if (role.isAdmin === true && role.uid === userId && canal.adminId === userId) {
                                creatorCanals.push(canal);
                                break;
                            }
                        }
                    }
                }

                setCreatorCanal(creatorCanals);
            }
            else {
                setCreatorCanal(null);
            }
        }
    }, [dispatch, userId, isAuthenticated]);


    // useEffect(() => {
    //     if (isAuthenticated) {

    //     }
    // }, [dispatch, userId, isAuthenticated]);

    const handleUpdateEmail = (newEmail) => {
        dispatch(updateUserEmail({ userId: userId, newEmail: newEmail }))
            .then((actionResult) => {
                // Vérifier si l'opération a réussi avant d'afficher la modale de succès
                if (actionResult.meta.requestStatus === 'fulfilled') {
                    Swal.fire({
                        icon: 'success',
                        title: `${t('modal.updateEmailTitle')}`,
                        text: `${t('modal.updateEmailText')}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    });
                }
            })
            .catch((error) => {
                if (error.message === 'REAUTHENTICATION_CANCELLED') {
                    // L'utilisateur a annulé la réauthentification, ne rien faire ou notifier l'utilisateur
                    // console.log('Réauthentification annulée par l\'utilisateur.');
                } else {
                    // console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.updateEmailError')} ${error.message}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    });
                }
            });
    };

    const handleUpdatePassword = async (newPassword) => {
        try {
            const actionResult = await dispatch(updatePassword({ newPassword }));
            if (actionResult.meta.requestStatus === 'fulfilled') {
                const message = 'Votre mot de passe a été modifié. Si vous n\'êtes pas à l\'origine de ce changement, contactez immédiatement le support à support@kappze.com.';
                const subject = 'Nouveau mot de passe enregistré';
                const authorized = true;
                const userIds = [];
                userIds.push(userId)

                Swal.fire({
                    icon: 'success',
                    title: 'Mot de passe mis à jour',
                    text: 'Votre mot de passe a été mis à jour avec succès.',
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK'
                });
                dispatch(createAndSendNotification({ userIds, message, subject, authorized }));
            }
        } catch (error) {
            if (error.message === 'REAUTHENTICATION_CANCELLED') {
                // console.log('Réauthentification annulée par l’utilisateur.');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: `Impossible de mettre à jour le mot de passe: ${error.message}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK'
                });
            }
        }
    };
    
    

    const toggleSwitch = () => {
        setIsEnabled(previousState => {
            // Si l'état précédent est null, retourne simplement true pour éviter les effets de bord
            if (previousState === null) {
                return true;
            }
            // Sinon, inverse l'état et continue avec la mise à jour
            const newState = !previousState;
            dispatch(updateUserNotificationPreference({ newValue: newState, userId: userId }));
            return newState;
        });
    };

    const toggleNewsletterSwitch = () => {
        setNewsletterConsent(previousState => {
            // Si l'état précédent est null, retourne simplement true pour éviter les effets de bord
            if (previousState === null) {
                return true;
            }
            // Sinon, inverse l'état et continue avec la mise à jour
            const newState = !previousState;
            dispatch(updateUserNewsletterPreference({ newValue: newState, userId: userId }));
            return newState;
        });
    };


    const handleUpdateField = (fieldKey, fieldData) => {
        if (fieldKey === 'email') {
            // dispatch(updateEmail(fieldData));
            handleUpdateEmail(fieldData)

        } else if (fieldKey === 'password') {
            // dispatch(updatePassword(fieldData))
            handleUpdatePassword(fieldData)

        } else if (fieldKey === 'name') {
            dispatch(updateUserName({ userId, newName: fieldData }))
                .then(() => {
                    setEditableFields((prevFields) =>
                        prevFields.map((field) =>
                            field.key === 'name' ? { ...field, value: fieldData } : field
                        )
                    );
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.updateNameError')} ${error.message}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                });
        } else if (fieldKey === 'surname') {
            dispatch(updateUserSurname({ userId, newSurname: fieldData }))
                .then(() => {
                    setEditableFields((prevFields) =>
                        prevFields.map((field) =>
                            field.key === 'surname' ? { ...field, value: fieldData } : field
                        )
                    );
                })
                .catch((error) => {
                    // console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${error.message}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                });
        }
        else if (fieldKey === 'siret') {

            dispatch(updateSiret({ userId, newSiret: fieldData }))
                .then(() => {
                    setEditableFields((prevFields) =>
                        prevFields.map((field) =>
                            field.key === 'siret' ? { ...field, value: fieldData } : field
                        )
                    );
                })
                .catch((error) => {
                    // console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${error.message}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                });
        }
        else if (fieldKey === 'siretAsso') {
            dispatch(updateSiret({ userId, newSiretAsso: fieldData }))
                .then(() => {
                    setEditableFields((prevFields) =>
                        prevFields.map((field) =>
                            field.key === 'siretAsso' ? { ...field, value: fieldData } : field
                        )
                    );
                })
                .catch((error) => {
                    // console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.updateSurnameError')} ${error.message}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                });
        }
    };

    const handleDeleteUser = () => {
        setShowConfirmation(true);

        
    };

    const handleDeleteConnexionCanal = (canalId) => {
        setShowConfirmationUnlinkCanal(true);
    }

    const handleDeleteConnexionAssociation = () => {
        setShowLeftAssoPopup(true);
    }
    

    const handleDeleteCanal = () => {
        setShowConfirmationDeleteCanal(true)
    }

    const handleCloseLeftAssoPopup = () => {

        setAnimationClass('popup-exiting');
        setShowLeftAssoPopup(false);
        setAnimationClass(null);
    };

    const confirmUnlinkUserCanal = (uid, aid) => {
        // Demande de confirmation supplémentaire avant la suppression de l'utilisateur
        Swal.fire({
            icon: 'warning',
            title: `${t('modal.attention')}`,
            text: `${t('modal.unlinkCanalConfirmation')}`,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#2F4F4F',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            showClass: {
                popup: 'animate__animated animate__fadeIn'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOut'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeUserFromCanal({ userId: uid, canalId: aid }))
                    .then(() => {
                        // dispatch(logout());
                    })
                    .catch((error) => {
                        // console.log(error);
                        Swal.fire({
                            icon: 'error',
                            title: `${t('modal.error')}`,
                            text: `${t('modal.deleteAccountConfirmationError')} ${error.message}`,
                            confirmButtonColor: '#2F4F4F',
                            confirmButtonText: 'OK',
                            showClass: {
                                popup: 'animate__animated animate__fadeIn'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOut'
                            }
                        })
                    });
            }
        })


    }



    const handleConfirmLeftAsso = async (e, userId, isLinkedToAnAssociation, linkedAssociationId) => {

        // console.log('DATA : ', data)
        e.preventDefault();
        if (!userId) {
            alert('Aucun utilisateur trouvé'); // Utilisez une alerte ou une notification appropriée ici
        } else {
            const resultAction = await dispatch(leaveAssociation({ userId: userId, linkedAssociationId: linkedAssociationId }));  // Here is the change

            if (leaveAssociation.fulfilled.match(resultAction)) {

                const newUpdatedUsers = users.map(user => {
                    if (user.id === userId) {
                        // Retirer l'ID de l'association
                        const newLinkedIds = user.linkedAssociationIds.filter(id => id !== linkedAssociationId);

                        // Déterminer si l'utilisateur reste lié à une quelconque association
                        const isStillLinked = newLinkedIds.length > 0;

                        return { ...user, linkedAssociationIds: newLinkedIds, isLinkedToAnAssociation: isStillLinked };
                    }
                    return user;
                });
                

                Swal.fire({
                    icon: 'success',
                    title: `Quitter une association`,
                    text: 'L\'utilisateur a bien été retiré de l\'association. Veuillez vous reconnecter.',
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                }).then(() => {
                    dispatch(logout());
                })

            } else {
                if (leaveAssociation.rejected.match(resultAction)) {
                    // Si une erreur personnalisée a été rejetée avec `rejectWithValue`
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${resultAction.payload} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.error')} ${resultAction.error.message} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                }
            }
            handleCloseLeftAssoPopup();
            
        }
    };

    const handleDeleteAccount = async () => {
        try {
            const actionResult = await dispatch(deleteUser());
            if (actionResult.meta.requestStatus === 'fulfilled') {
                Swal.fire({
                    icon: 'success',
                    title: 'Compte Supprimé',
                    text: 'Votre compte a été supprimé avec succès.',
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK'
                });
                dispatch(logout());
            }
        } catch (error) {
            if (error.message === 'REAUTHENTICATION_CANCELLED') {
                // console.log('Réauthentification annulée par l’utilisateur.');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: `Impossible de supprimer le compte: ${error.message}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK'
                });
            }
        }
    };

    const confirmDeleteCanal = (uid, aid) => {

        // Demande de confirmation supplémentaire avant la suppression de l'utilisateur
        Swal.fire({
            icon: 'warning',
            title: `${t('modal.attention')}`,
            text: `${t('modal.deleteCanalConfirmation')}`,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#2F4F4F',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            showClass: {
                popup: 'animate__animated animate__fadeIn'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOut'
            }

        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeCanal({ userId: uid, canalId: aid }))
                    .then(() => {
                        setShowConfirmationDeleteCanal(false);
                        navigate('/user-profile', { from: navigate.location });
                    })
                    .catch((error) => {
                        // console.log(error);

                        Swal.fire({
                            icon: 'error',
                            title: `${t('modal.error')}`,
                            text: `${t('modal.deleteCanalConfirmationError')} ${error.message}`,
                            confirmButtonColor: '#2F4F4F',
                            confirmButtonText: 'OK',
                            showClass: {
                                popup: 'animate__animated animate__fadeIn'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOut'
                            }
                        })
                    });
            }
        })
    };

    useEffect(() => {
        // Initialiser userRoles avec les rôles actuels des utilisateurs
        users.forEach(user => {

            if (user.id === uid) {
                setUserRole(user.isAdmin);
            }
        });
    }, [users]);

    useEffect(() => {
        const fetchDetails = async () => {
            if (associationsUser?.length) {
                const detailsPromises = associationsUser.map(siret => dispatch(fetchSingleAssociationDetails(siret)).unwrap());
                const details = await Promise.all(detailsPromises);
                setAssociationsDetails(details);
            }
        };
        fetchDetails();
    }, [associationsUser, dispatch]);
    

    if (animalsStatus === 'loading' || canalsStatus === 'loading') {
        return <LoadingPage />;
    }
    const expiryDateFrench = formatDateToFrench(currentLicenceDetails?.expiryDate);

    return (
        <div className="kappze-page user-page">
            <LoadingPage isLoading={false} />
            <BackButton />

            <div className="page-main">
                <div className="page-main-header" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h2 style={{ color: 'white', padding: '1rem', marginTop: '0' }}>{t('settingsOf')} {surname} {name}</h2>
                    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', rowGap: '1rem' }}>
                        <label htmlFor="editing">{t('editMode')} </label>
                        <label className='switch'>
                            <input type="checkbox"
                                checked={isEditMode} onChange={(e) => setIsEditMode(e.target.checked)} />
                            <span className="slider round"></span>
                        </label>
                        
                            <>
                                <label htmlFor="editing">{t('enableNotifications')}</label>
                                <label className='switch'>
                                    <input type="checkbox"
                                        checked={isEnabled} onChange={toggleSwitch} />
                                    <span className="slider round"></span>
                                </label>
                            </>
                        
                        {/* {((!licenceStatus || licenceStatus !== 'active') && (<>
                            <span className="notificationPremium" >
                                <label htmlFor="editing">{t('enableNotifications')}</label>


                                <label className='switch'>
                                    <input type="checkbox"
                                        checked={isEnabled} onChange={toggleSwitch} disabled />
                                    <span className="slider round"></span>
                                </label>

                            </span>
                            <img src={Sparkles} style={{ width: '20px', marginLeft: "0px" }} />
                        </>
                        ))} */}

                    </div>
                </div>

                <div className='user-page-editable-fields'>
                    {t('registeredSince')} {registrationDateObj?.toLocaleString()}

                    .
                    <div className="user-page-bloc-settings">
                        {editableFields?.map((field, key) => (
                            <div key={key}>
                                <EditableField
                                    key={field.key}
                                    label={field.label}
                                    value={field.value}
                                    onSave={(value) => handleUpdateField(field.key, value)}
                                    uid={uid}
                                    isEditMode={isEditMode}
                                    customClass="settings-label"
                                />


                            </div>
                        ))}

                        {/* <div className="">
                        <label htmlFor="editing" style={{color: '#000'}}>Accepter d'être visible par les mairies </label>
                                <label className='switch'>
                                    <input type="checkbox"
                                        checked={isEditMode} onChange={(e) => setIsEditMode(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>
                        </div> */}

                        {showConfirmation && (
                            <div>
                                <p style={{ color: 'black' }}>Voulez-vous vraiment supprimer votre compte ?</p>
                                <button onClick={handleDeleteAccount} className='button-supp' style={{ background: 'rgb(135, 41, 41)', color: 'white' }}>OUI</button>
                                <button onClick={() => setShowConfirmation(false)}>NON</button>
                            </div>
                        )}
                        {!showConfirmation && (
                            <button onClick={handleDeleteUser} className='button-supp' style={{ background: 'rgb(135, 41, 41)', color: 'white', marginLeft: 0 }}>{t('deleteAccount')}</button>
                        )}

                        <div style={{ marginTop: '2rem' }}><h3 style={{ color: 'black' }}>Newsletter </h3>
                            <div className="switch-newsletter" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                                <label htmlFor="editing" style={{ color: '#000', marginRight: "5px" }}>Recevoir la newsletter</label>
                                <label className='switch'>
                                    <input type="checkbox"
                                        checked={isNewsletterConsent} onChange={toggleNewsletterSwitch} />
                                    <span className="slider round"></span>
                                </label>
                            </div>



                        </div>
                    </div>




{(userType !== 'visitor') && (
    <>
    <h3 className="user-page-bloc-settings-title">{t('yourOffer')}</h3>
                    <div className="user-page-bloc-settings">



                        {maximumOfAnimals && (
                            <ProgressBar numberOfAnimals={animalsCount} maximumOfAnimals={maximumOfAnimals} />
                        )}


                        <div>
                            {(licenceStatus === 'active') ? (
                                <><p style={{ color: 'black' }}>{productType === 1 ? `${t('premiumVersion')}` : productType === 2 ? `${t('standardVersion')}` : productType === 4 ? `${t('freeVersion')}` : productType === 3 ? `${t('premiumVersion')}` : `${t('noLicense')}`}. {t('yourSubscriptionWillExpireOn')} {expiryDateFrench} . {t('licenseNumber')} : {licenceNumber}</p>
                                    <button className='button-general'>
                                        <NavLink to={`/checkout/`} className='button-general-link'>
                                            <span>{t('renewLicense')}</span>
                                        </NavLink>
                                    </button>

                                    <button className='button-general'>
                                        <NavLink to={`/invoices/`} className='button-general-link'>
                                            <span>{t('myLastPayments')}</span>
                                        </NavLink>
                                    </button></>
                            ) : (!licenceStatus || licenceStatus?.status !== 'active') ? (<>
                                <p style={{ color: 'black' }}>{t('freeVersion')}</p>
                                <button>
                                    <NavLink to={`/checkout/`} className='button-general-link'>
                                        <span>{t('getLicense')}</span>
                                    </NavLink></button>
                            </>) : (<>
                                <p style={{ color: 'black' }}>{t('notEligible')}</p>
                            </>)}
                        </div>
                    </div>
                    </>
)}





                    {canals?.length > 0 && (<>
                        <h3 className="user-page-bloc-settings-title">{t('channelsYouAreAMemberOf')}</h3>
                        <div className="user-page-bloc-settings">

                            {canals?.map((canal) => (
                                <div key={canal?.id}>

                                    {canal.adminId !== userId ? (<>
                                        <p style={{ color: 'black' }}>{canal?.name}</p>
                                        {showConfirmationUnlinkedCanal && selectedCanalId === canal?.id && (
                                            <>
                                                <p style={{ color: 'black' }}>Voulez-vous vraiment supprimer la liaison avec ce canal ?</p>
                                                <button onClick={() => confirmUnlinkUserCanal(userId, canal.id)} className='button-supp' style={{ background: 'rgb(135, 41, 41)', color: 'white' }}>OUI</button>
                                                <button onClick={() => { setShowConfirmationUnlinkCanal(false); setSelectedCanalId(null); }}>NON</button>
                                            </>
                                        )}
                                        {!showConfirmationUnlinkedCanal && (
                                            <button onClick={() => { handleDeleteConnexionCanal(canal.id); setSelectedCanalId(canal.id); }} className='button-supp' style={{ background: 'rgb(135, 41, 41)', color: 'white' }}>Retirer de vos canaux</button>
                                        )}
                                    </>) : (<> <p style={{ color: 'black' }}>{canal.name} (admin)</p></>)}
                                </div>
                            ))}
                        </div>
                    </>)}


                    {associationsDetails?.length > 0 && (<>
                        <h3 className="user-page-bloc-settings-title">Associations dont vous êtes membres</h3>
                        <div className="user-page-bloc-settings">

                            {associationsDetails?.map((association) => (
                                <div key={association?.id}>

                                    {association?.adminId !== userId ? (<>
                                        <p style={{ color: 'black' }}>{association?.name}</p>
                                        {showLeftAssoPopup && selectedAssociationId === association?.id && (
                                            <>
                                                <p style={{ color: 'black' }}>Voulez-vous vraiment supprimer la liaison avec cette association ?</p>
                                                <button onClick={(e) => handleConfirmLeftAsso(e, user.id, user.isLinkedToAnAssociation, association.id)} className='button-supp' style={{ background: 'rgb(135, 41, 41)', color: 'white' }}>OUI</button>
                                                <button onClick={() => { setShowLeftAssoPopup(false); setSelectedAssociationId(null); }}>NON</button>
                                            </>
                                        )}
                                        {!showLeftAssoPopup && (
                                            <button onClick={() => { handleDeleteConnexionAssociation(association.id); setSelectedAssociationId(association.id); }} className='button-supp' style={{ background: 'rgb(135, 41, 41)', color: 'white' }}>Retirer de vos associations</button>
                                        )}
                                    </>) : (<> <p style={{ color: 'black' }}>{association?.name} (admin)</p></>)}
                                </div>
                            ))}
                        </div>
                    </>)}

                    {creatorCanal?.length > 0 && (<>
                        <h3 className="user-page-bloc-settings-title">{t('channelsYouManage')}</h3>
                        <div className="user-page-bloc-settings">
                            {creatorCanal?.map((asso) => (

                                <div key={asso.id}>
                                    <p key={asso.id} style={{ color: 'black' }}>{asso.name} ({asso.adminId === userId ? 'Super admin' : 'admin'})</p>
                                    {showConfirmationDeleteCanal && selectedCanalId === asso.id && (
                                        <>
                                            <p style={{ color: 'black' }}>Voulez-vous vraiment supprimer ce canal ? Cette action est irréversible</p>
                                            <button onClick={() => confirmDeleteCanal(userId, asso.id)} className='button-supp' style={{ background: 'rgb(135, 41, 41)', color: 'white' }}>OUI</button>
                                            <button onClick={() => { setShowConfirmationDeleteCanal(false); setSelectedCanalId(null); }}>NON</button>
                                        </>
                                    )}
                                    {!showConfirmationDeleteCanal && (
                                        <button onClick={() => { handleDeleteCanal(asso.id); setSelectedCanalId(asso.id); }} className='button-supp' style={{ background: 'rgb(135, 41, 41)', color: 'white', marginLeft: 0 }}>{t('deleteThisChannel')}</button>
                                    )}
                                </div>

                            ))}
                        </div></>)}

                </div>



            </div>
        </div>
    );
};

export default Settings;
