import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polygon, useMap, useMapEvents, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from '../../assets/logos/location-pin-kappze.svg';

const fetchCommuneData = async (code) => {
    const response = await fetch(`https://geo.api.gouv.fr/communes/${code}?fields=nom,code,codeParent,codesPostaux,siren,centre,contour,bbox,codeEpci,codeDepartement,codeRegion,population&format=json&geometry=centre`);
    const data = await response.json();
    return data;
};

const calculateCenter = (coordinates) => {
    let latSum = 0;
    let lngSum = 0;
    const len = coordinates.length;

    coordinates.forEach(coord => {
        latSum += coord[1];
        lngSum += coord[0];
    });

    return {
        lat: latSum / len,
        lng: lngSum / len
    };
};

const calculateBounds = (coordinates) => {
    let minLat = Infinity, maxLat = -Infinity, minLng = Infinity, maxLng = -Infinity;

    coordinates.forEach(coord => {
        minLat = Math.min(minLat, coord[1]);
        maxLat = Math.max(maxLat, coord[1]);
        minLng = Math.min(minLng, coord[0]);
        maxLng = Math.max(maxLng, coord[0]);
    });

    return [
        [minLat, minLng],
        [maxLat, maxLng]
    ];
};

const AddMarker = ({ onMarkerAdded }) => {
    useMapEvents({
        click(e) {
            const { lat, lng } = e.latlng;
            onMarkerAdded({ lat, lng });
        },
    });
    return null;
};

const DynamicMap = ({ hasAnOwner, communeCode, markers, onMarkerAdded }) => {
    const [center, setCenter] = useState({ lat: 48.8566, lng: 2.3522 });
    const [bounds, setBounds] = useState(null);
    const [regionCoordinates, setRegionCoordinates] = useState([]);
    const [userLocation, setUserLocation] = useState(null);


    const customIcon = new L.Icon({
        iconUrl: markerIcon,
        iconSize: [42, 42], // Taille de l'icône
        iconAnchor: [26, 42], // Point de l'icône qui correspond à la position du marqueur
        popupAnchor: [-42, -42] // Point où la popup apparaît par rapport à l'icône
    });

    useEffect(() => {
        if (communeCode) {
            fetchCommuneData(communeCode).then(data => {
                const coordinates = data.contour.coordinates[0];
                const newCenter = calculateCenter(coordinates);
                const newBounds = calculateBounds(coordinates);
                const formattedCoordinates = coordinates.map(coord => ({
                    lat: coord[1],
                    lng: coord[0]
                }));

                setCenter(newCenter);
                setBounds(newBounds);
                setRegionCoordinates(formattedCoordinates);
            });
        }
    }, [communeCode]);

    const mapBounds = [
        { lat: 85, lng: -180 },
        { lat: 85, lng: 180 },
        { lat: -85, lng: 180 },
        { lat: -85, lng: -180 },
        { lat: 85, lng: -180 } // Ferme le polygone
    ];

    const UpdateMapView = () => {
        const map = useMap();
        useEffect(() => {
            if (bounds) {
                map.fitBounds(bounds);
                map.setMaxBounds(bounds);
                map.setMinZoom(map.getZoom());
            } else {
                map.setView(center, 13);
            }
        }, [map, center, bounds]);
        return null;
    };


    const handleLocateUser = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ lat: latitude, lng: longitude });
                    onMarkerAdded({ lat: latitude, lng: longitude });
                },
                error => {
                    console.error("Geolocation error:", error);
                    alert("Error getting location. Please try again.");
                },
                {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };
    return (
        <div className="div">
        {hasAnOwner ? (<h3 style={{marginBottom: '1rem'}}>Localisation</h3>) : (<h3 style={{marginBottom: '1rem'}}>Lieu du signalement</h3>)}
            
            
            <p>Cliquez à l'endroit du repérage</p>
            {/* <p style={{textDecoration: 'underline'}} onClick={handleLocateUser}>(Le chat est sur ma position)</p> */}
        <div className="map-container">


            <MapContainer
                center={center}
                zoom={13}
                className="leaflet-container"
                maxBounds={bounds}
                maxBoundsViscosity={1.0}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Polygon
                    positions={[mapBounds, regionCoordinates]}
                    pathOptions={{ fillColor: 'black', fillOpacity: 0.7, strokeWeight: 0 }}
                />
                <Polygon
                    positions={regionCoordinates}
                    pathOptions={{ weight: 2, color: '#2f4f4f', fillColor: 'black' }}
                />
                {markers?.map((marker, idx) => (
                    <Marker key={idx} position={[marker.lat, marker.lng]} icon={customIcon} />
                ))}
                <AddMarker onMarkerAdded={onMarkerAdded} />
                <UpdateMapView />
            </MapContainer>



        </div>
        </div>
    );
};

export default DynamicMap;
