import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCanals, joinCanal } from '../../features/canals/canalSlice';
import CanalCard from '../../components/canal/CanalCard';
import CreateCanalPopup from '../../components/canal/createCanalPopup';
import JoinCanalPopup from '../../components/canal/joinCanalPopup';
import LoadingPage from '../../components/general/LoadingPage'; // Importez votre composant de chargement ici
import iconadd from '../../assets/icon-add.svg';
import iconhouse from '../../assets/icon-house.svg';
import iconhouseBlack from '../../assets/icon-house-black.svg';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { addCanal } from '../../features/canals/canalSlice';
import CitySelectByCp from '../../components/general/CitySelect';
import logocolor from '../../assets/transparent-without-circle.png';
import { Tooltip } from 'react-tooltip';
import helpOutline from '../../assets/help-outline.svg';
import { useTranslation } from 'react-i18next';
import QuickSearchComponent from '../../components/animals/QuickSearch';
import { fetchAnimals } from '../../features/animals/animalSlice';
import Sparkles from "../../assets/sparkles-outline-gold.svg";
import { Helmet } from 'react-helmet';
import { updateLicenceStatus } from '../../features/licences/licenceSlice';
import ResendVerificationEmail from '../../components/user/SendVerificationEmail';
import { fetchAssociationsFromUserId } from '../../features/user/fetchingUserSlice';
import AssociationCard from '../../components/association/AssociationCard';
import { fetchAnimalsByAssociation, fetchAssociationsDetails, fetchSingleAssociationDetails } from '../../features/association/associationSlice';


const ListingAssociations = () => {
    const { t } = useTranslation();
    const { uid, isAuthenticated, name, surname, isMairie, isAssociation, licenceNumber, mairieName, associationName, productType, licenceStatus, emailWasVerified } = useSelector((state) => state.auth);
    const { data: canals, status, error } = useSelector((state) => state.canals);
    const { licence } = useSelector((state) => state.licences);
    const { data: animals } = useSelector((state) => state.animals)
    const  associations  = useSelector((state) => state.users?.associationsIdsContributor)
    // const associationsDetails = useSelector((state) => state.associations?.associations);
    const publishedAnimals = animals.filter((animal) => animal.published === true);
    const [canalsIds, setCanalsIds] = useState('');
    const [canalId, setCanalId] = useState('');
    const [joinCanalAlias, setJoinCanalAlias] = useState('');
    const [nameCanal, setName] = useState('');
    const [canalAlias, setAlias] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showCreateCanalPopup, setShowCreateCanalPopup] = useState(false);
    const [showJoinCanalPopup, setShowJoinCanalPopup] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const [modalQuickSearchOpen, setModalQuickSearchOpen] = useState(false);
    const [codeCommune, setCodeCommune] = useState('');
    const [nomCommune, setNomCommune] = useState('');
    // const [showCreateCanalPopup, setShowCreateCanalPopup] = useState(false);

    const [associationsDetails, setAssociationsDetails] = useState([]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCanals(uid));
        }
    }, [dispatch, uid, isAuthenticated]);

    // Utilisation d'effet pour mettre à jour canalsIds quand canals change
    useEffect(() => {
        if (canals) {
            // Créer un nouveau tableau avec seulement les IDs des canals
            const extractedIds = canals.map(canal => canal?.id); // Remplacez 'id' par la clé appropriée pour l'ID du canal
            setCanalsIds(extractedIds);
        }
    }, [canals]); // Dépendance à canals pour que l'effet s'exécute quand canals change

    useEffect(() => {
        if (canals) {
            dispatch(fetchAnimals(canalsIds))

        }
        if (licenceNumber) {
            dispatch(updateLicenceStatus({ licenceId: licenceNumber, canalsId: canalsIds, userId: uid }))
        }
    }, [dispatch, canalsIds, canals, licenceNumber]);


    useEffect(() => {
        if (isAuthenticated && uid) {
            // Créer un nouveau tableau avec seulement les IDs des canals
            dispatch(fetchAssociationsFromUserId({userId: uid}))
        }
    }, [uid]); // Dépendance à canals pour que l'effet s'exécute quand canals change


    useEffect(() => {
        const fetchDetails = async () => {
            if (associations?.length) {
                const detailsPromises = associations.map(siret => dispatch(fetchSingleAssociationDetails(siret)).unwrap());
                const details = await Promise.all(detailsPromises);
                setAssociationsDetails(details);
            }
        };
        fetchDetails();
    }, [associations, dispatch]);
        
    if (status === 'loading') {
        return <LoadingPage isLoading={true} />;
    }


    return (
        <div className='kappze-page user-page'>

            <Helmet>
                <meta name="description" content="Kappze est le premier outil dans la gestion des chats errants à destination des mairies et associations. Les chats libres, vraiment libres." />
            </Helmet>
            <LoadingPage isLoading={false} />
            <>

                {associations?.length ?

                
                    (<div style={{ padding: '2rem' }}>

                        <div className="header-listing" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', columnGap: '1rem', rowGap: '1rem' }}>
                            <h2 style={{textTransform: 'uppercase'}}>{t('associations')} de {surname} {name} </h2>
                        </div>

                        <div className='list-canal'>
                            {
                                associationsDetails.map((association, index) => (
                               
                                    <AssociationCard key={index} association={association} />
                                ))}
                        </div>
                    </div>) : (
                        <></>
                    )}
            </>
        </div>
    );


};



export default ListingAssociations;


