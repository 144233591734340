import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import helpOutline from '../../assets/help-outline.svg';
import arrowRight from '../../assets/arrow-forward-outline.svg';
import { fetchCitiesSector } from '../../features/citiesSector/citySectorSlice';
import { fetchAnimalsByCanal } from '../../features/animals/animalSlice';
import { fetchCanalUsers } from '../../features/canals/canalUsersSlice';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'animate.css';
import copy from '../../assets/copypaste.svg';
import BackButton from '../../components/general/BackButton';
import LoadingPage from '../../components/general/LoadingPage';
import CapturesGraph from '../../components/graphs/trappingByYear';
import CurvesGraph from '../../components/graphs/sterilizationByYear';
import PieChart from '../../components/graphs/animalsBySector';
import SignalementsGraph from '../../components/graphs/signalementsGraph';
import TotalSterilizationGraph from '../../components/graphs/totalSterilization';
import TotalIdentificationGraph from '../../components/graphs/totalIdentification';
import AddedSectorsGraph from '../../components/graphs/addedSectorsGraph';
import NewMembersGraph from '../../components/graphs/newMembers';
import TotalTrappedGraph from '../../components/graphs/totalTrapped';
import CapturesSexGraph from '../../components/graphs/trappingBySex';
import SterilizationSexGraph from '../../components/graphs/sterilizationBySex';
import SignalementsCurvesGraph from '../../components/graphs/signalementsGraphCurves';
import CurvesIdentificationGraph from '../../components/graphs/identificationByYear';
import IdentificationSexGraph from '../../components/graphs/identificationBySex';
import MultipleMarkersMap from '../Maps/multipleMapMarkers';
import { useTranslation } from 'react-i18next';
import BannerPlaceHolder from '../../assets/placeholder-banner-kappze.png'



const Statistics = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { data: canals } = useSelector((state) => state.canals);
    const { isAuthenticated, uid } = useSelector((state) => state.auth);
    const { data: users } = useSelector((state) => state.canalUsers);
    const { data: animals } = useSelector((state) => state.animals);




    const { data: citiesSector } = useSelector((state) => state.citiesSector)
    const animalsStatus = useSelector((state) => state.animals.status);
    const canalsStatus = useSelector((state) => state.canals.status);
    const [canal, setCanal] = useState(canals.find((asso) => asso.id === id));
    const [isCopied, setIsCopied] = useState(false);
    const backgroundUrl = canal?.imageCover?.url?.url ? canal?.imageCover?.url?.url : canal?.imageCover?.url ? canal?.imageCover?.url : BannerPlaceHolder;


    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear);
    // Générer une liste d'années pour le sélecteur
    const years = Array.from({ length: 20 }, (_, index) => currentYear - index);

    const dispatch = useDispatch();
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCitiesSector(id));
            dispatch(fetchAnimalsByCanal(id))
            dispatch(fetchCanalUsers(id));
        }
    }, [canal, dispatch, id, isAuthenticated]);




    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(canal?.alias);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Réinitialiser après 2 secondes
        } catch (err) {
            console.error('Erreur de copie', err);
        }
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };



    if (animalsStatus === 'loading' || canalsStatus === 'loading') {
        return <LoadingPage isLoading={true} />;
    }


    return (
        <div className='kappze-page canal-page filtered-page'>
            <BackButton />
            <LoadingPage isLoading={false} />
            <div className="page-main">
                <div className="canal-page_content canal-statistics-header">
                    <div className="canal-page_content-title canal-statistics-header" style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', marginBottom: '1rem', backgroundImage: `url(${backgroundUrl})` }}>
                        <h1 className='page-title'>{canal?.name} - {t('statistics')} {selectedYear}</h1>
                        <Tooltip id="my-tooltip-share-canal" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', zIndex: 2 }}>
                            <div className='page-title canal-statistics-header' style={{ backgroundColor: '#122' }}>
                                <h3>{t('alias.shareChannel.title')}</h3>
                                <p>{t('alias.shareChannel.text1')}</p>
                                <ul>
                                    <li>{t('alias.shareChannel.list1')}</li>
                                    <li>{t('alias.shareChannel.list2')}</li>
                                </ul>
                            </div>
                        </Tooltip>
                        {/* <div className='canal-statistics-header'>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: 'none', marginTop: '1rem' }}>
                                <h2 style={{ fontSize: '16px', color: '#fff', alignSelf: 'flex-start', padding: '0rem', marginRight: '10px' }}>{t('shareCanal')}</h2>
                                <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-share-canal" />
                            </div>
                            <button style={{ display: 'flex', flexDirection: 'row', columnGap: '10px', color: '#122', backgroundColor: '#ddd', marginLeft: '0px' }} onClick={handleCopy}>

                                {isCopied ? `${t('aliasCopied')}` : canal?.alias}
                                <img src={copy} style={{ width: '20px', marginLeft: '10px' }} />
                            </button>
                        </div> */}
                        <div className="canal-statistics-header" style={{ paddingBottom: '1rem' }}>
                            <label htmlFor="year-selector">{t('chooseAYear')} : </label>
                            <select id="year-selector" value={selectedYear} onChange={handleYearChange}>
                                {years.map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="graph">
                        <span className="graph-scroll">
                            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><p style={{ color: '#000' }}>Scroll</p><img src={arrowRight} style={{ width: '16px' }} /></span>

                        </span>
                        <div id='graph-1'  className="graph-self" style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>
                            <Tooltip id="my-tooltip" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem' }}>
                                <div style={{ backgroundColor: '#122' }}>
                                    <h3 style={{ textTransform: 'capitalize' }}>{t('catches')}</h3>
                                    <p>{t('tooltips.graph.captures.text1')}</p>
                                    <ul>
                                        <li>{t('tooltips.graph.captures.list1')}</li>
                                        <li>{t('tooltips.graph.captures.list2')}</li>
                                    </ul>
                                </div>
                            </Tooltip>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: 'none' }}>
                                <h2 style={{ color: '#122', alignSelf: 'flex-start', padding: '1rem' }}>{t('capture')}</h2>
                                <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip" />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '2rem', background: 'transparent' }}>

                                <span style={{ backgroundColor: '#fff', width: '100%' }}>
                                    <CapturesGraph animals={animals} year={selectedYear} />
                                </span>
                                <span style={{ backgroundColor: '#fff', width: '100%', display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
                                    <TotalTrappedGraph animals={animals} year={selectedYear} />
                                    <CapturesSexGraph animals={animals} year={selectedYear} />
                                </span>
                            </div>
                        </div>

                        <div id='graph-2' className="graph-self"  style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '2rem', background: 'transparent' }}>

                            <Tooltip id="my-tooltip-sterilization-title" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem' }}>
                                <div style={{ backgroundColor: '#122' }}>
                                    <h3>{t('sterilizations')}</h3>
                                    <p>{t('tooltips.graph.sterilizations.text1')}</p>
                                    <ul>
                                        <li>{t('tooltips.graph.sterilizations.list1')}</li>
                                        <li>{t('tooltips.graph.sterilizations.list2')}</li>
                                    </ul>
                                </div>
                            </Tooltip>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: 'none' }}>
                                <h2 style={{ color: '#122', alignSelf: 'flex-start', padding: '1rem', textTransform: 'capitalize' }}>{t('sterilisations')}</h2>
                                <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-sterilization-title" />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '2rem', background: 'transparent' }}>

                                <span style={{ backgroundColor: '#fff', width: '100%' }}>
                                    <CurvesGraph animals={animals} year={selectedYear} />

                                </span>
                                <span style={{ backgroundColor: '#fff', width: '100%', display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>

                                    <TotalSterilizationGraph animals={animals} year={selectedYear} />
                                    <SterilizationSexGraph animals={animals} year={selectedYear} />

                                </span>
                            </div>

                        </div>

                        <div id='graph-3' className="graph-self"  style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>
                            <Tooltip id="my-tooltip-identification-title" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem' }}>
                                <div style={{ backgroundColor: '#122' }}>
                                    <h3>{t('identifications')}</h3>
                                    <p>{t('tooltips.graph.identifications.text1')} </p>
                                    <ul>
                                        <li>{t('tooltips.graph.identifications.list1')}</li>
                                        <li>{t('tooltips.graph.identifications.list2')}</li>
                                    </ul>
                                </div>
                            </Tooltip>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: 'none' }}>
                                <h2 style={{ color: '#122', alignSelf: 'flex-start', padding: '1rem', textTransform: 'capitalize' }}>{t('identifications')}</h2>
                                <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-identification-title" />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>

                                <span style={{ backgroundColor: '#fff', width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', rowGap: '1rem', columnGap: '1rem' }}>
                                    <CurvesIdentificationGraph animals={animals} year={selectedYear} />
                                </span>
                                <span style={{ backgroundColor: '#fff', width: '100%' }}>
                                    <TotalIdentificationGraph animals={animals} year={selectedYear} />
                                    <IdentificationSexGraph animals={animals} year={selectedYear} />
                                </span>
                            </div>
                        </div>

                        <div id='graph-4' className="graph-self"  style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>

                            <Tooltip id="my-tooltip-signalements-title" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem' }}>
                                <div style={{ backgroundColor: '#122' }}>
                                    <h3>{t('reports')}</h3>
                                    <p>{t('tooltips.graph.reports.text1')}</p>
                                    <ul>
                                        <li>{t('tooltips.graph.reports.list1')}</li>
                                        <li>{t('tooltips.graph.reports.list2')}</li>
                                        <li>{t('tooltips.graph.reports.list3')}</li>
                                    </ul>
                                </div>
                            </Tooltip>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: 'none' }}>
                                <h2 style={{ color: '#122', alignSelf: 'flex-start', padding: '1rem', textTransform: 'capitalize' }}>{t('reports')}</h2>
                                <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-signalements-title" />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>

                                <span style={{ backgroundColor: '#fff', width: '100%' }}>
                                    <SignalementsCurvesGraph animals={animals} year={selectedYear} />

                                </span>

                                <span style={{ backgroundColor: '#fff', width: '100%' }}>
                                    <SignalementsGraph animals={animals} year={selectedYear} />
                                </span>

                            </div>
                        </div>
                        <div id='graph-5' className="graph-self"  style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>

                            <Tooltip id="my-tooltip-sectors-title" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem' }}>
                                <div style={{ backgroundColor: '#122' }}>
                                    <h3>{t('sectors')}</h3>
                                    <p>{t('tooltips.graph.sectors.text1')}</p>
                                    <ul>
                                        <li>{t('tooltips.graph.sectors.list1')}</li>
                                        <li>{t('tooltips.graph.sectors.list2')}</li>
                                    </ul>
                                </div>
                            </Tooltip>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: 'none' }}>
                                <h2 style={{ color: '#122', alignSelf: 'flex-start', padding: '1rem' }}>{t('sectors')}</h2>
                                <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-sectors-title" />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>

                                <span style={{ backgroundColor: '#fff', width: '100%' }}>
                                    <PieChart />
                                </span>
                                <span style={{ backgroundColor: '#fff', width: '100%' }}>
                                    <AddedSectorsGraph sectors={citiesSector} year={selectedYear} />
                                </span>

                            </div>
                        </div>

                        <div id='graph-6' className="graph-self"  style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>

                            <h2 style={{ color: '#122', padding: '1rem', textTransform: 'capitalize' }}>{t('members')}</h2>

                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>

                                <span style={{ backgroundColor: '#fff', width: '100%' }}>
                                    <NewMembersGraph users={users} year={selectedYear} />
                                </span>
                            </div>
                        </div>

                        {/* <div id='graph-7' style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>

                            <h2 style={{ color: '#122', padding: '1rem', textTransform: 'capitalize' }}>{t('animals')}</h2>

                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', columnGap: '1rem', rowGap: '1rem', background: 'transparent' }}>

                                <span style={{ width: '100%' }}>
                                    <MultipleMarkersMap communeCode={canal?.codeCommune} animals={animals} />
                                </span>
                            </div>
                        </div> */}



                    </div>



                </div>
            </div>
        </div>
    );
};


export default Statistics;
