import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, where, doc, query, getDocs, addDoc, getDoc, deleteDoc, writeBatch, updateDoc, arrayUnion } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
/*
* Retrieve animals by Canal
***    
*/

export const fetchAnimalsByCanal = createAsyncThunk('animals/fetchAnimalsByCanal', async (canalId) => {
    const db = getFirestore();
    const q = query(collection(db, 'animals'), where('canalId', '==', canalId));
    const querySnapshot = await getDocs(q);
    const animalsData = [];
    querySnapshot.forEach((doc) => {
        animalsData.push({ id: doc.id, ...doc.data() });
    });
    return animalsData;
});


/*
* Retrieve every animals with given canal IDS 
***
*/

export const fetchAnimals = createAsyncThunk('animals/fetchAnimals', async (canalsId) => {
    const db = getFirestore();
    const q = query(collection(db, 'animals'), where('canalId', 'in', canalsId));
    const querySnapshot = await getDocs(q);
    const animalsData = [];
    querySnapshot.forEach((doc) => {
        animalsData.push({ id: doc.id, ...doc.data() });
    });

    // console.log('animalsData -> ', animalsData)
    return animalsData;
});

/*
* Retrieve animal by ID
***    
*/

export const fetchAnimalById = createAsyncThunk('animals/fetchAnimalById', async (animalId) => {
    const db = getFirestore();
    const animalRef = doc(collection(db, 'animals'), animalId);
    const animalSnapshot = await getDoc(animalRef);
    return { id: animalSnapshot.id, ...animalSnapshot.data() };
});

/*
* Retrieve mother by ID
***    
*/

export const fetchMotherById = createAsyncThunk('animals/fetchMotherById', async (motherId, { rejectWithValue }) => {

    try {
        const db = getFirestore();
        const animalRef = doc(collection(db, 'animals'), motherId);
        const animalSnapshot = await getDoc(animalRef);
        return { id: animalSnapshot.id, ...animalSnapshot.data() };
    } catch (error) {
        return rejectWithValue(error.message);
    }

});


/*
* Retrieve animals by mother ID
***
*/

export const fetchAnimalsByMother = createAsyncThunk('animals/fetchAnimalsByMother', async (motherId) => {
    const db = getFirestore();
    const q = query(collection(db, 'animals'), where('motherAppId', '==', motherId));
    const querySnapshot = await getDocs(q);
    const animalsData = [];
    querySnapshot.forEach((doc) => {
        animalsData.push({ id: doc.id, ...doc.data() });
    });
    return animalsData;
});


/*
* Retrieve animals by CitySector
***    
*/

export const fetchAnimalsByCitySector = createAsyncThunk('animals/fetchAnimalsByCitySector', async (citySectorId) => {
    const db = getFirestore();
    const q = query(collection(db, 'animals'), where('citySectorId', '==', citySectorId));
    const querySnapshot = await getDocs(q);
    const animalsData = [];
    querySnapshot.forEach((doc) => {
        animalsData.push({ id: doc.id, ...doc.data() });
    });
    return animalsData;
});


/*
* Retrieve animals by CitySector
***    
*/

export const fetchAnimalsBySubSector = createAsyncThunk('animals/fetchAnimalsBySubSector', async (subSectorId) => {
    const db = getFirestore();
    const q = query(collection(db, 'animals'), where('subSectorId', '==', subSectorId));
    const querySnapshot = await getDocs(q);
    const animalsData = [];
    querySnapshot.forEach((doc) => {
        animalsData.push({ id: doc.id, ...doc.data() });
    });
    return animalsData;
});



/*
* Add new animal
***    
*/


export const addAnimal = createAsyncThunk(
    'animals/addAnimal',
    async (animalData, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const animalRef = collection(db, 'animals');
            const newAnimalRef = await addDoc(animalRef, animalData);
            const newAnimalSnapshot = await getDoc(newAnimalRef);
            const newAnimal = { id: newAnimalRef.id, ...newAnimalSnapshot.data() };
            return newAnimal;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);



/* Update animal citySector name
***    
*/

export const updateAnimalCitySectorName = createAsyncThunk(
    'animals/updateAnimalCitySectorName',
    async ({ citySectorId, newCitySectorName }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const q = query(collection(db, 'animals'), where('citySectorId', '==', citySectorId));
            const querySnapshot = await getDocs(q);

            const batch = writeBatch(db);
            querySnapshot.forEach((animalDoc) => {
                const animalRef = doc(db, 'animals', animalDoc.id);
                batch.update(animalRef, { citySectorName: newCitySectorName });
            });
            await batch.commit();

            // Return the payload
            return { citySectorId, newCitySectorName };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

/* Update animal subSector name
***    
*/

export const updateAnimalSubSectorName = createAsyncThunk(
    'animals/updateAnimalSubSectorName',
    async ({ subSectorId, newSubSectorName }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const q = query(collection(db, 'animals'), where('citySectorId', '==', subSectorId));
            const querySnapshot = await getDocs(q);

            const batch = writeBatch(db);
            querySnapshot.forEach((animalDoc) => {
                const animalRef = doc(db, 'animals', animalDoc.id);
                batch.update(animalRef, { citySectorName: newSubSectorName });
            });
            await batch.commit();

            // Return the payload
            return { subSectorId, newSubSectorName };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

/*
* Remove animal citySector
***    
*/

export const removeCitySectorFromAnimals = createAsyncThunk(
    'animals/removeCitySectorFromAnimals',
    async (citySectorId, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const q = query(collection(db, 'animals'), where('citySectorId', '==', citySectorId));
            const querySnapshot = await getDocs(q);

            const batch = writeBatch(db);
            querySnapshot.forEach((animalDoc) => {
                const animalRef = doc(db, 'animals', animalDoc.id);
                batch.update(animalRef, { citySectorId: '', citySectorName: '' });
            });
            await batch.commit();
            return citySectorId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


/*
* Remove animal citySector
***    
*/

export const removeSubSectorFromAnimals = createAsyncThunk(
    'animals/removeSubSectorFromAnimals',
    async (subSectorId, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const q = query(collection(db, 'animals'), where('subSectorId', '==', subSectorId));
            const querySnapshot = await getDocs(q);

            const batch = writeBatch(db);
            querySnapshot.forEach((animalDoc) => {
                const animalRef = doc(db, 'animals', animalDoc.id);
                batch.update(animalRef, { subSectorId: '', subSectorName: '' });
            });
            await batch.commit();
            return subSectorId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

/*
* Add animal profile image
***    
*/


export const uploadImage = async (image) => {
    try {
        const storage = getStorage();
        const imageName = uuidv4();
        const storageRef = ref(storage, `images/${imageName}`);
        await uploadBytes(storageRef, image);
        const imageUrl = await getDownloadURL(storageRef);
        return {
            url: imageUrl,
            name: imageName
        };
    } catch (error) {
        throw new Error(`Une erreur s'est produite lors du téléchargement de l'image : ${error.message}`);
    }
};

/*
* Update animal image
***
*/


export const updateAnimalImage = createAsyncThunk(
    "animals/updateAnimalImage",
    async ({ animalId, image }, { rejectWithValue, dispatch }) => {
        try {
            const db = getFirestore();
            const animalRef = doc(db, "animals", animalId);

            // Récupérer l'image actuelle de l'animal
            const animalDoc = await getDoc(animalRef);
            const animalData = animalDoc.data();

            // Si l'animal a déjà une image, la supprimer de Firebase Storage
            if (animalData && animalData.image && animalData.image.name) {
                dispatch(deleteImageFromStorage(animalData.image.name));
            }

            // Uploader la nouvelle image
            const newImageUrl = await uploadImage(image);

            // Mettre à jour la référence de l'image dans Firestore
            await updateDoc(animalRef, { image: newImageUrl });

            return { animalId, imageUrl: newImageUrl };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


/*
* Add animal pdf
***    
*/

export const addDocumentToAnimal = createAsyncThunk(
    'animals/addDocumentToAnimal',
    async ({ animalId, documents }, thunkAPI) => {
        try {
            // Assuming you have a Firestore reference to your animals collection
            const db = getFirestore();
            const animalRef = doc(db, 'animals', animalId);
            await updateDoc(animalRef, { documents: arrayUnion(...documents) });
            return animalId;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const uploadFile = createAsyncThunk(
    'animals/uploadFile',
    async (file, thunkAPI) => {
        try {
            const storage = getStorage();
            const storageRef = ref(storage, `files/${file.name}`);
            await uploadBytes(storageRef, file);
            const fileUrl = await getDownloadURL(storageRef);
            return {
                name: file.name,
                url: fileUrl
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const uploadDocumentsAndUpdateAnimal = createAsyncThunk(
    'animals/uploadDocumentsAndUpdateAnimal',
    async ({ animalId, documents }, thunkAPI) => {
        try {
            // Map each document to a Promise that will resolve to an object {name, url}
            const uploadPromises = documents.map((doc) => thunkAPI.dispatch(uploadFile(doc)));
            // Wait for all uploads to complete

            const uploadedDocuments = await Promise.all(uploadPromises);

            // Add the uploaded documents to the animal
            await thunkAPI.dispatch(addDocumentToAnimal({ animalId, documents: uploadedDocuments }));

            return uploadedDocuments;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const uploadSingleFile = async (file, animalName) => {
    try {
        const storage = getStorage();
        const fileName = animalName + '_' + file.name + '_' + Date.now();
        const storageRef = ref(storage, `files/${fileName}`);
        await uploadBytes(storageRef, file);
        const fileUrl = await getDownloadURL(storageRef);
        return {
            name: fileName,
            url: fileUrl
        };
    } catch (error) {
        throw new Error(error.message);
    }
};

export const convertAndUploadPDF = async (file) => {
    try {
        const storage = getStorage();
        const fileName = 'pdf_' + file.name + '_' + Date.now();
        const storageRef = ref(storage, `pdfs/${fileName}`);
        await uploadBytes(storageRef, file);
        const fileUrl = await getDownloadURL(storageRef);
        return {
            name: fileName,
            url: fileUrl
        };
    } catch (error) {
        throw new Error(error.message);
    }
};


export const processPDFWithAdobe = async (pdfUrl) => {
    try {
        const response = await fetch('https://us-central1-kappze.cloudfunctions.net/processAdobe', {
            method: 'POST',
            body: JSON.stringify({ fileUrl: pdfUrl }),
        });

        if (!response.ok) {
            throw new Error(`Erreur lors du traitement du PDF: ${response.statusText}`);
        }

        return response.json();
    } catch (error) {
        console.error('Erreur lors du traitement du PDF:', error);
        throw error;
    }
};


export const cleanUpFiles = async (imageFileName, pdfFileName, isDirectUpload) => {
    try {
        await fetch('https://us-central1-kappze.cloudfunctions.net/cleanUpFiles', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ imageFileName, pdfFileName, isDirectUpload }),
        });
    } catch (error) {
        console.error('Erreur lors du nettoyage des fichiers:', error);
    }
};


/*
* chat-perdu API
***    
*/

export const fetchLostCatsByPostalCode = async (zip, region) => {

    try {
        const url = `https://us-central1-kappze.cloudfunctions.net/fetchLostCatsByPostalCode?zip=${zip}&region=${region}`;
        const response = await fetch(url, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error(`Erreur lors du traitement ${response.statusText}`);
        }

        return response.json();
    } catch (error) {
        console.log('Erreur lors du traitement', error);
        // throw error;
        return;
    }
};

/*
* Delete file
***    
*/

export const deleteFileFromStorage = createAsyncThunk(
    'animals/deleteFileFromStorage',
    async (fileName, thunkAPI) => {
        try {
            const storage = getStorage();
            const fileRef = ref(storage, `files/${fileName}`);
            await deleteObject(fileRef);
            return fileName;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteImageFromStorage = createAsyncThunk(
    "animals/deleteImageFromStorage",
    async (fileName, thunkAPI) => {
        try {
            const storage = getStorage();
            const fileRef = ref(storage, `images/${fileName}`);
            await deleteObject(fileRef);
            return fileName;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteDocumentFromAnimal = createAsyncThunk(
    'animals/deleteDocumentFromAnimal',
    async (payload, thunkAPI) => {
        try {
            const { animalId, documentName } = payload;
            const animals = thunkAPI.getState().animals;
            const animal = animals.data.find(a => a.id === animalId);

            if (!animal) {
                throw new Error('Animal not found');
            }

            // Create a new copy of the animal and modify the copy
            const animalData = {
                ...animal,
                documents: animal.documents.filter(singleDocument => singleDocument.name !== documentName),
            };


            // Here you should persist the changes in your backend as well
            // For example, by calling a function like `updateAnimal(animalId, animal)`
            const updatePayload = {
                animalId: animalId,
                animalData: animalData
            };

            await thunkAPI.dispatch(updateAnimal(updatePayload));

            return { animalId: animalId, documentName: documentName }; // Returning updated animal's id and documentName
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



/*
* Update animal informations
***    
*/

export const updateAnimal = createAsyncThunk(
    'animals/updateAnimal',
    async ({ animalId, animalData }, { rejectWithValue }) => {

        try {
            const db = getFirestore();
            const animalRef = doc(db, 'animals', animalId);
            await updateDoc(animalRef, animalData);
            return { animalId, animalData };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


/*
* Publish animal
***
*/
export const publishAnimal = createAsyncThunk(
    'animals/publishAnimal',
    async (animalId, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const animalRef = doc(db, 'animals', animalId);
            await updateDoc(animalRef, { published: true });
            return { animalId, published: true };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

/*
* Delete animal
***
*/

export const deleteAnimal = createAsyncThunk(
    "animals/deleteAnimal",
    async (animalId, { rejectWithValue, dispatch }) => {
        try {
            const db = getFirestore();
            const animalRef = doc(db, "animals", animalId);

            // Récupérer les informations associées à l'animal
            const animalDoc = await getDoc(animalRef);
            const animalData = animalDoc.data();

            // console.log("animalData : ", animalData);
            const fileDocuments = animalData.documents;

            // Supprimer chaque document de Google Storage
            if (fileDocuments) {
                for (let document of fileDocuments) {
                    await dispatch(deleteFileFromStorage(document.name));
                }
            }

            // Supprimer l'image associée à l'animal
            if (animalData && animalData.image && animalData.image.name) {
                dispatch(deleteImageFromStorage(animalData.image.name));
            }

            const commentsCollectionRef = collection(animalRef, "commentaires");
            const commentsPrivateCollectionRef = collection(animalRef, "privateComments");

            // Supprimer tous les commentaires
            const commentDocsSnapshot = await getDocs(commentsCollectionRef);
            const commentPrivateDocsSnapshot = await getDocs(commentsPrivateCollectionRef);
            const batch = writeBatch(db);

            commentDocsSnapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });

            commentPrivateDocsSnapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });

            // Appliquer la suppression par batch
            await batch.commit();

            // Enfin, supprimer l'animal
            await deleteDoc(animalRef);

            return animalId;
        } catch (error) {
            console.log(error);
            return rejectWithValue(error.message);
        }
    }
);

/*
* * * Animal Slice
***    
*/

const animalsSlice = createSlice({
    name: 'animals',
    initialState: {
        status: 'idle',
        data: [],
        selectedAnimal: null,
        motherAnimal: null,
        motherAnimalsList: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAnimalsByCitySector.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAnimalsByCitySector.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchAnimalsByCitySector.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAnimalsBySubSector.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAnimalsBySubSector.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchAnimalsBySubSector.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAnimals.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAnimals.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchAnimals.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAnimalsByCanal.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAnimalsByCanal.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchAnimalsByCanal.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(fetchAnimalById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAnimalById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedAnimal = action.payload;
            })
            .addCase(fetchAnimalById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(fetchMotherById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMotherById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.motherAnimal = action.payload;
            })
            .addCase(fetchMotherById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(fetchAnimalsByMother.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAnimalsByMother.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.motherAnimalsList = action.payload;
            })
            .addCase(fetchAnimalsByMother.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                // state.motherAnimal = '';
            })

            .addCase(addAnimal.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addAnimal.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data.push(action.payload);
            })
            .addCase(addAnimal.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateAnimalCitySectorName.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAnimalCitySectorName.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = state.data.map((animal) => {
                    if (animal.citySectorId === action.payload.citySectorId) {
                        return { ...animal, citySectorName: action.payload.newCitySectorName };
                    }
                    return animal;
                });
            })
            .addCase(updateAnimalCitySectorName.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateAnimalSubSectorName.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAnimalSubSectorName.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = state.data.map((animal) => {
                    if (animal.subSectorId === action.payload.subSectorId) {
                        return { ...animal, subSectorName: action.payload.newSubSectorName };
                    }
                    return animal;
                });
            })
            .addCase(updateAnimalSubSectorName.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(removeCitySectorFromAnimals.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = state.data.map((animal) => {
                    if (animal.citySectorId === action.payload) {
                        return { ...animal, citySectorId: '' };
                    }
                    return animal;
                });
            })
            .addCase(removeSubSectorFromAnimals.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = state.data.map((animal) => {
                    if (animal.subSectorId === action.payload) {
                        return { ...animal, subSectorId: '' };
                    }
                    return animal;
                });
            })
            .addCase(deleteAnimal.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteAnimal.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = state.data.filter((animal) => animal.id !== action.payload);
            })
            .addCase(deleteAnimal.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateAnimal.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAnimal.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = state.data.map((animal) => {
                    if (animal.id === action.payload.animalId) {
                        return { ...animal, ...action.payload.animalData };
                    }
                    return animal;
                });
                if (state.selectedAnimal && state.selectedAnimal.id === action.payload.animalId) {
                    state.selectedAnimal = { ...state.selectedAnimal, ...action.payload.animalData };
                }
            })
            .addCase(updateAnimal.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateAnimalImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAnimalImage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { animalId, imageUrl } = action.payload;
                const animal = state.data.find((animal) => animal.id === animalId);
                if (animal) {
                    animal.image.url = imageUrl;
                }
                if (state.selectedAnimal && state.selectedAnimal.id === animalId) {
                    state.selectedAnimal.image = imageUrl;
                }
            })
            .addCase(updateAnimalImage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(publishAnimal.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(publishAnimal.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = state.data.map((animal) => {
                    if (animal.id === action.payload.animalId) {
                        // Ici, on ne met à jour que le champ 'published'
                        return { ...animal, published: action.payload.published };
                    }
                    return animal;
                });
                if (state.selectedAnimal && state.selectedAnimal.id === action.payload.animalId) {
                    state.selectedAnimal = { ...state.selectedAnimal, published: action.payload.published };
                }
            })

            .addCase(publishAnimal.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteFileFromStorage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteFileFromStorage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // supprimer l'entrée correspondante de documents dans le state
                state.selectedAnimal.documents = state.selectedAnimal.documents.filter(document => document.name !== action.payload);
            })
            .addCase(deleteFileFromStorage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteDocumentFromAnimal.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteDocumentFromAnimal.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = state.data.map((animal) => {
                    if (animal.id === action.payload.animalId) {
                        const newDocuments = animal.documents.filter(document => {
                            return document.name !== action.payload.documentName
                        });
                        animal.documents = newDocuments;
                        return {
                            ...animal
                        };
                    }
                    return animal;
                });
            })
            .addCase(deleteDocumentFromAnimal.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(uploadDocumentsAndUpdateAnimal.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    },
});

export default animalsSlice.reducer;
