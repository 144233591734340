import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import { addLicence } from "../features/licences/licenceSlice";
import axios from 'axios';
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import LoadingPage from "../components/general/LoadingPage";
import logopremium from '../assets/logos/kappze_logo_circle_noir_gold.png';
import logostandard from '../assets/logos/kappze_logo_circle_noir_vert.png';
import logoupgrade from '../assets/logos/kappze_logo_circle_noir_red.png';
import { useTranslation } from 'react-i18next';
import CatNotFound from '../assets/cat-not-found.png'

export const CheckoutForm = () => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [licenceError, setLicenceError] = useState(null);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [cgvError, setCgvError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [oldBillingDetails, setOldBillingDetails] = useState(null);
    const navigate = useNavigate();
    const [paymentInProcess, setPaymentInProcess] = useState(null);

    const [billingDetails, setBillingDetails] = useState({
        name: '',
        email: '',
        address: {
            line1: '',
            city: '',
            postal_code: '',
            country: ''
        }
    });

    const products = [
        // {
        //     id: 1,
        //     name: `${t('payments.product1')}`,
        //     price: 299, // en centimes pour Stripe
        //     description: `${t('payments.product1Description')}`,
        //     roundedPrice: 2.99,
        //     image: logopremium

        // },
        {
            id: 2,
            name: `${t('payments.product2')}`,
            price: 14900, // en centimes pour Stripe
            description: `${t('payments.product2Description')}`,
            roundedPrice: 149,
            image: logostandard

        },
        {
            id: 3,
            name: `${t('payments.product3')}`,
            price: 44900, // en centimes pour Stripe
            description: `${t('payments.product3Description')}`,
            roundedPrice: 449,
            image: logopremium

        },
        {
            id: 4,
            name: `${t('payments.product4')}`,
            price: 30000, // en centimes pour Stripe
            description: `${t('payments.product4Description')}`,
            roundedPrice: 300,
            image: logoupgrade,
            upgrade: true

        }
    ];

    const { uid, status, userType, licenceStatus, productType } = useSelector((state) => state.auth);
    const currentLicence = useSelector((state) => state.auth.licenceNumber);
    const isLicenceActive = useSelector((state) => state.auth.licenceStatus);

    // console.log('CURRENT PRODUCT TYPE ->', currentProductType)
    // console.log('CURRENT LICENCE ACTIVE ->', isLicenceActive)


    useEffect(() => {
        const fetchBillingInfo = async () => {
            const db = getFirestore();
            const billingInfoRef = doc(db, "billingInfo", uid);
            const billingInfoDoc = await getDoc(billingInfoRef);

            if (billingInfoDoc.exists()) {

                setOldBillingDetails(billingInfoDoc.data());
                setBillingDetails(billingInfoDoc.data());
            } else {
                console.log('error')
            }
        };

        fetchBillingInfo();
    }, [uid]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!acceptTerms) {
            setCgvError(`${t('modal.youNeedToAcceptConditions')}`);
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });
        if (!error) {
            const { id } = paymentMethod;

            const getDifferences = () => {

                let differences = {};
                if (!oldBillingDetails) {
                    return differences
                }
                for (let key in billingDetails) {
                    if (typeof billingDetails[key] === 'object') {
                        for (let subKey in billingDetails[key]) {
                            if (oldBillingDetails[key][subKey] !== billingDetails[key][subKey]) {
                                differences[subKey] = billingDetails[key][subKey];
                            }
                        }
                    } else {
                        if (oldBillingDetails[key] !== billingDetails[key]) {

                            differences[key] = billingDetails[key];
                        }
                    }
                }
                return differences;
            };

            const keyMapping = {
                name: "Nom",
                email: "Email",
                "line1": "Adresse",
                "citySector": "Ville",
                "postal_code": "Code postal",
                "country": "Pays"
            };

            const formatDifferences = (differences, currentBilling) => {
                let formattedString = "";

                for (let key in differences) {
                    const friendlyKey = keyMapping[key] || key; // Utilisez la clé traduite si elle existe, sinon la clé originale

                    if (currentBilling[key] && differences[key]) {
                        formattedString += `${friendlyKey}: ${currentBilling[key]} -> ${differences[key]}\n`;
                    } else if (!currentBilling[key] && differences[key]) {
                        formattedString += `${friendlyKey} :  ${differences[key]}\n`;
                    }
                }

                return formattedString;
            };

            const differences = getDifferences();
            if (Object.keys(differences).length > 0) {

                Swal.fire({
                    title: `${t('modal.confirmation')}`,
                    text: `${t('modal.theseBillingInformationsWillBeChanged')} \n\n" + formattedDifferences + "\n\n ${t('modal.doYouWantToContinue')}`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2F4F4F',
                    cancelButtonColor: '#d33',
                    confirmButtonText: `${t('modal.confirm')}`,
                    cancelButtonText: `${t('modal.cancel')}`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        performPayment(id, billingDetails);
                    }
                });
            } else {
                await performPayment(id, billingDetails);
            }
        } else {
            console.log(error.message);
            console.error("Stripe error:", error.message);
        }
    };


    const performPayment = async (id, billingDetails) => {
        let customerId;
        setPaymentInProcess(true);

        // Vérifiez si le client existe
        const findCustomerResponse = await axios.get("https://us-central1-kappze.cloudfunctions.net/stripeapi/stripe/find-customer", {
            params: {
                email: billingDetails.email,
                name: billingDetails.name
            }

        });

        if (findCustomerResponse.data.exists) {
            customerId = findCustomerResponse.data.customerId;
        } else {
            // Si le client n'existe pas, créez-en un nouveau
            const customerResponse = await axios.post("https://us-central1-kappze.cloudfunctions.net/stripeapi/stripe/create-customer", {
                name: billingDetails.name,
                email: billingDetails.email,
                address: {
                    line1: billingDetails.address.line1,
                    citySector: billingDetails.address.citySector,
                    postal_code: billingDetails.address.postal_code,
                    country: billingDetails.address.country
                },
            });

            if (customerResponse.data.success) {
                customerId = customerResponse.data.customerId;
                // Stockez le customerId dans Firebase
                const db = getFirestore();
                const userRef = doc(db, "users", uid);
                await setDoc(userRef, { stripeCustomerId: customerId }, { merge: true });
            } else {
                console.log("Erreur lors de la création du client");
                return;
            }
        }

        // Effectuer le paiement en utilisant l'ID du client
        const paymentResponse = await axios.post("https://us-central1-kappze.cloudfunctions.net/stripeapi/stripe/charge", {
            amount: selectedProduct ? selectedProduct.price : 0,
            id: id,
            customerId: customerId,
            return_url: 'https://app.kappze.com',
            receipt: billingDetails.email,
            billingDetails: billingDetails,
            description: selectedProduct ? selectedProduct.name : ''
        });

        if (paymentResponse.data.success) {
            setPaymentInProcess(false)
            const actionResult = await dispatch(addLicence({ userId: uid, licenceId: currentLicence, productType: selectedProduct?.id, upgradeProduct: selectedProduct?.upgrade }));
            if (addLicence.fulfilled.match(actionResult)) {
                // console.log('Licence ajoutée avec succès');

                Swal.fire({
                    title: `${t('modal.paymentSuccessTitle')}`,
                    text: `${t('modal.paymentSuccess')}`,
                    icon: 'success',
                    confirmButtonText: `${t('modal.backToHomePage')}`,
                    confirmButtonColor: '#2F4F4F',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });

            } else {
                setLicenceError(actionResult.payload);
            }
            const db = getFirestore();
            const billingInfoRef = doc(db, "billingInfo", uid);
            await setDoc(billingInfoRef, billingDetails);
        } else {
            console.log("Erreur lors du paiement");
            setLicenceError(`${t('modal.anErrorOccuredDuringPaymentProcess')}`);
        }
    };


    if (status === 'loading') {
        return <LoadingPage isLoading={true} />;
    }

    return (
        <div className="checkout-form page-main">
            <LoadingPage isLoading={false} />

            <div className="products">
                {(userType === 'association' || 'mairie')  && productType !== 2 ? (
                    products.filter(product => product.id === 2 || product.id === 3).map(product => (
                        <div key={product.id} className="product-card">
                            <input
                                type="radio"
                                id={product.id}
                                name="product"
                                value={product.id}
                                onChange={() => setSelectedProduct(product)}
                                className="product-input"
                            />
                            <label htmlFor={product.id} className="product-label">
                                <img src={product.image} alt={product.name} className="product-image" />
                                <h2 className="product-title">{product.name}</h2>
                                <p className="product-description">{product.description}</p>
                                <p className="product-price">{product.price / 100}€ /an</p>
                                <span className="select-text">{t('payments.select')}</span>
                            </label>
                        </div>
                    ))
                ) : ((userType === 'association' || 'mairie')  && productType === 2) ? (
                    products.filter(product => product.id === 4).map(product => (
                        <div key={product.id} className="product-card">
                            <input
                                type="radio"
                                id={product.id}
                                name="product"
                                value={product.id}
                                onChange={() => setSelectedProduct(product)}
                                className="product-input"
                            />
                            <label htmlFor={product.id} className="product-label">
                                <img src={product.image} alt={product.name} className="product-image" />
                                <h2 className="product-title">{product.name}</h2>
                                <p className="product-description">{product.description}</p>
                                <p className="product-price"> <span style={{ textDecoration: 'line-through', fontWeight: '500', marginRight: '1rem' }}>349€</span>{product.price / 100}€</p>
                                <span className="select-text">{t('payments.select')}</span>
                            </label>
                        </div>
                    ))) : (
                    products.filter(product => product.id === 1).map(product => (
                        <div key={product.id} className="product-card">
                            <input
                                type="radio"
                                id={product.id}
                                name="product"
                                value={product.id}
                                onChange={() => setSelectedProduct(product)}
                                className="product-input"
                            />
                            <label htmlFor={product.id} className="product-label">
                                <img src={product.image} alt={product.name} className="product-image" />
                                <h2 className="product-title">{product.name}</h2>
                                <p className="product-description">{product.description}</p>
                                <p className="product-price">{product.price / 100}€ /an</p>
                                <span className="select-text">{t('payments.select')}</span>
                            </label>
                        </div>
                    ))
                )}


                {/* {!isMairie && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ color: '#122' }}>Les avantages de Kappze+ : </p>
                        <ul>
                            <li style={{ color: 'black' }}>0 publicité</li>
                            <li style={{ color: 'black' }}>Notifications activables</li>
                            <li style={{ color: 'black' }}>Ajout de canaux illimités</li>
                            <li style={{ color: 'black' }}>Accès à la section commentaire</li>
                        </ul>

                        <img src={CatNotFound} style={{ maxWidth: '80px' }} />
                    </div>


                )} */}

            </div>


            {licenceError && <p style={{ color: 'rgb(135, 41, 41)' }}>{licenceError}</p>}
            {selectedProduct &&
                <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
                    {selectedProduct?.price && (
                        <p style={{ color: '#2f4f4f', textAlign: 'center' }}>Prix total : {selectedProduct.roundedPrice} € {selectedProduct.upgrade === true ? ('') : ('pour un an')}</p>
                    )}

                    <p style={{ color: '#2f2f2f' }}>Facturation : </p>
                    <input
                        type="text"
                        placeholder="Nom complet"
                        value={billingDetails.name}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, name: e.target.value }))}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={billingDetails.email}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, email: e.target.value }))}
                    />
                    <input
                        type="text"
                        placeholder="Adresse"
                        value={billingDetails.address.line1}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, address: { ...prev.address, line1: e.target.value } }))}
                    />
                    <input
                        type="text"
                        placeholder="Ville"
                        value={billingDetails.address.city}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, address: { ...prev.address, city: e.target.value } }))}
                    />
                    <input
                        type="text"
                        placeholder="Code postal"
                        value={billingDetails.address.postal_code}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, address: { ...prev.address, postal_code: e.target.value } }))}
                    />
                    <input
                        type="text"
                        placeholder="Pays"
                        value={billingDetails.address.country}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, address: { ...prev.address, country: e.target.value } }))}
                    />

                    <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>

                        <div className="">J'accepte les Conditions Générales de Vente<input
                            type="checkbox"
                            checked={acceptTerms}
                            onChange={(e) => setAcceptTerms(e.target.checked)}
                            style={{ width: 'unset' }}
                        /></div>


                    </div>

                    <p style={{ color: '#2f2f2f' }}>Paiement par carte : </p>
                    <CardElement options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#32325d',
                                '::placeholder': {
                                    color: '#aab7c4'
                                }
                            },
                            invalid: {
                                color: '#fa755a',
                                iconColor: '#fa755a'
                            }
                        }
                    }}
                    />

                    <button className='checkout-button' disabled={!acceptTerms}>Payer</button>
                    {paymentInProcess && <div className="loaderPayment"></div>}

                </form>}
        </div>
    );
}
