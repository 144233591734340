import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, where, query, getDocs, addDoc, getDoc, setDoc, doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { deleteImageFromStorage, uploadImage } from "../animals/animalSlice";
import { fetchLicenceById } from '../licences/licenceSlice';
import { setLicenceStatus } from '../user/userSlice';


/*
* Fetch Canals
***    
*/

export const fetchCanals = createAsyncThunk('canals/fetchCanals', async (userId, { dispatch, rejectWithValue }) => {
  const db = getFirestore();
  const userCanalsRef = collection(db, 'userCanals');
  const userCanalsQuery = query(userCanalsRef, where('userId', '==', userId));
  const userCanalsSnapshot = await getDocs(userCanalsQuery);
  const canalIds = userCanalsSnapshot.docs.map((doc) => doc.data().canalId);
  const canalsData = [];

  for (const canalId of canalIds) {
    const canalRef = doc(db, 'canals', canalId);
    const canalSnapshot = await getDoc(canalRef);

    if (canalSnapshot.exists()) {
      const canalData = canalSnapshot.data();
      let isAdmin = false;

      // Check if the user is an admin
      if (Array.isArray(canalData.role)) {
        for (let role of canalData.role) {
          if (role.uid === userId && role.isAdmin) {
            isAdmin = true;
            break;
          }
        }
      }

            const licence = await dispatch(fetchLicenceStatusByCanalId(canalData.id));
            // console.log('probleme ici dans fetchCanals')
      //       console.log("canalData --> ", canalData)
      // console.log('licence status -->', licence)
      const licenceStatus = licence.payload ? licence.payload : 'la licence est inactive';

      // console.log(licenceStatus)

      canalsData.push({ id: canalId, isAdmin, licenceMaire: licenceStatus, ...canalData });

      // console.log(canalsData)
    }
  }


  return canalsData;
});


/* 
** fetchCanalsByIds
*/ 


export const fetchCanalsByIds = createAsyncThunk('canals/fetchCanalsByIds', 
    async (canalIds, { dispatch, rejectWithValue }) => {
        const db = getFirestore();
        const canalsData = [];

        for (const canalId of canalIds) {
            const canalRef = doc(db, 'canals', canalId);
            const canalSnapshot = await getDoc(canalRef);

            if (canalSnapshot.exists()) {
                const canalData = canalSnapshot.data();

                // Supposons que tu veuilles vérifier une licence ou autre condition
                const licence = await dispatch(fetchLicenceStatusByCanalId(canalData.id));
                const licenceStatus = licence.payload ? licence.payload : 'la licence est inactive';

                // Collecte des données du canal
                canalsData.push({
                    id: canalId,
                    licenceMaire: licenceStatus,
                    ...canalData
                });
            }
        }

        return canalsData;
    }
);


/*
* Fetch LICENSE 
***
*/

export const fetchLicenceStatusByCanalId = createAsyncThunk(
  'licence/fetchLicenceStatusByCanalId',
  async (canalId, { dispatch, rejectWithValue }) => {
    try {
      const db = getFirestore();

      // 1. Obtenez les données du canal à l'aide de l'ID du canal
      const canalRef = doc(db, 'canals', canalId);
      const canalSnapshot = await getDoc(canalRef);

      if (!canalSnapshot.exists()) {
        return rejectWithValue('Le canal spécifié n\'existe pas.');
      }

      const canalData = canalSnapshot.data();
      const associatedAccountId = canalData.adminId;  // Supposons qu'il y ait un champ "accountId" dans les données du canal

      // 2. Obtenez le numéro de licence à l'aide de l'ID de compte associé
      const accountRef = doc(db, 'users', associatedAccountId);  // Supposons que la collection soit appelée 'accounts'
      const accountSnapshot = await getDoc(accountRef);

      if (!accountSnapshot.exists()) {
        return rejectWithValue('Le compte spécifié n\'existe pas.');
      }

      const accountData = accountSnapshot.data();
      const licenceNumber = accountData.licenceNumber;

      // 3. Récupérez le statut de la licence à l'aide du numéro de licence
      const licence = await dispatch(fetchLicenceById(licenceNumber));

      // if (licence.payload && licence.payload.status) {
      //   dispatch(setLicenceStatus(licence.payload.status));  // Stockez le statut dans le store
      // } else {
      //   dispatch(setLicenceStatus('inactive'));  // Ou une valeur par défaut si le statut ne peut pas être déterminé
      // }

      // console.log('return du licence.payload et payload status', licence.payload, licence.payload.status)

      return licence.payload.status;

    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);


/*
* Create Canal
***    
*/

export const addCanal = createAsyncThunk(
  'canals/addCanal',
  async ({ userId, canalData, codeCommune }, { rejectWithValue }) => {
    let joiningDate = Timestamp.now()
    joiningDate = joiningDate.toDate()

    // console.log('USER ID : ', userId)
    // console.log('CANAL DATA : ', canalData)
    // console.log('codeCommune : ', codeCommune)
    try {
      const db = getFirestore();
      const canalRef = collection(db, 'canals');

      // Assurez-vous que l'alias est unique
      if(canalData?.isCanalAssociation !== true){
      const existingAliasSnapshot = await getDocs(query(collection(db, 'canals'), where("codeCommune", "==", codeCommune)));
      if (!existingAliasSnapshot.empty) {
        throw new Error('Un canal existe déjà pour cette commune.');
      }
      }


      // Créez une référence pour un nouveau canal (avec un ID généré automatiquement)
      const newCanalRef = doc(canalRef);

      // Ajoutez l'ID du canal à canalData avant de l'enregistrer
      const completeCanalData = { id: newCanalRef.id, codeCommune: codeCommune, ...canalData };

      // Enregistrez les données complètes du canal
      await setDoc(newCanalRef, completeCanalData);

      // Ajoutez l'ID du canal à la collection "userCanals" de l'utilisateur
      const userCanalsRef = collection(db, 'userCanals');
      await addDoc(userCanalsRef, { userId, canalId: newCanalRef.id, joiningDate });

      return completeCanalData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



/*
* Update canal informations
***    
*/


export const updateCanal = createAsyncThunk(
  'canal/updateCanal',
  async ({ canalId, canalData }, { rejectWithValue }) => {
    try {
      const db = getFirestore();
      const canalRef = doc(db, 'canals', canalId);
      await updateDoc(canalRef, canalData);

      // Mise à jour des animaux associés
      const animalsRef = collection(db, 'animals');
      const querySnapshot = await getDocs(query(animalsRef, where('canalId', '==', canalId)));

      const promises = querySnapshot.docs.map((doc) => {
        const animalRef = doc.ref;
        return updateDoc(animalRef, { canalName: canalData.name });
      });

      await Promise.all(promises);

      return { canalId, canalData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
* Delete Canal
***
*/

export const removeCanal = createAsyncThunk(
  'canals/removeCanal',
  async ({ userId, canalId }, { rejectWithValue, dispatch }) => {

    try {
      const db = getFirestore();

      // Supprimer le canal de la collection "canals"
      const canalRef = doc(db, 'canals', canalId);
      // Récupérer les informations associées au canal
      const canalDoc = await getDoc(canalRef);
      const docData = canalDoc.data();
      // Supprimer l'image associée au canal
      if (docData && docData.image && docData.image.name) {
        // console.log("NAME :", docData.image.name);
        dispatch(deleteImageFromStorage(docData.image.name));
      }
      if (docData && docData.imageCover && docData.imageCover.name) {
        // console.log("NAME :", docData.image.name);
        dispatch(deleteImageFromStorage(docData.imageCover.name));
      }
      await deleteDoc(canalRef);

      // Supprimer l'ID du canal de la collection "userCanals" de l'utilisateur
      const userCanalsQuery = query(collection(db, 'userCanals'), where('userId', '==', userId), where('canalId', '==', canalId));
      const userCanalSnapshot = await getDocs(userCanalsQuery);

      if (!userCanalSnapshot.empty) {
        // Supposons qu'il n'y ait qu'un seul document qui correspond à la requête
        const docId = userCanalSnapshot.docs[0].id;
        await deleteDoc(doc(db, 'userCanals', docId));
      } else {
        throw new Error('La liaison entre l\'utilisateur et l\'canal n\'a pas été trouvée');
      }

      return { message: 'Canal supprimé avec succès', canalId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


/*
* Join Canal
***    
*/

export const joinCanal = createAsyncThunk(
  'canals/joinCanal',
  async ({ userId, codeCommune, isAdmin = false, cid }, { rejectWithValue }) => {
    try {
      const db = getFirestore();
      const joiningDate = Timestamp.now().toDate();

      // Vérifiez si l'utilisateur existe
      const userRef = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userRef);
      if (!userSnapshot.exists()) {
        throw new Error('Utilisateur introuvable');
      }

      // Recherchez les canaux par leur code commune
      const canalQuery = query(collection(db, 'canals'), where("codeCommune", "==", codeCommune), where("id", "==", cid));
      const canalQuerySnapshot = await getDocs(canalQuery);

      if (canalQuerySnapshot.empty) {
        throw new Error('Canal introuvable');
      }

      const userCanalsRef = collection(db, 'userCanals');
      let updatedCanal = null;

      for (const canalDoc of canalQuerySnapshot.docs) {
        const canalId = canalDoc.id;
        const canalData = canalDoc.data();

        // Vérifiez si l'utilisateur est déjà membre du canal
        const querySnapshot = await getDocs(query(userCanalsRef, where("userId", "==", userId), where("canalId", "==", canalId)));
        if (!querySnapshot.empty) {
          // Si l'utilisateur est déjà membre d'un canal avec isCanalAssociation, rejeter
          if (canalData.isCanalAssociation && canalData.canalId === 'canalId') {
            throw new Error('L\'utilisateur est déjà membre de ce canal avec association');
          }
          continue; // Ignore ce canal si l'utilisateur est déjà membre et que ce n'est pas une association
        }

        // Ajoutez l'ID du canal à la collection "userCanals" de l'utilisateur
        await addDoc(userCanalsRef, { userId, canalId, joiningDate });

        // Ajoutez le rôle de l'utilisateur au canal
        const newRole = { uid: userId, isAdmin };
        const updatedRoles = canalData.role ? [...canalData.role, newRole] : [newRole];

        // Mettez à jour le document du canal avec les nouvelles données
        const canalRef = doc(db, 'canals', canalId);
        await updateDoc(canalRef, { role: updatedRoles });

        // Récupérez les données du canal mis à jour
        updatedCanal = { id: canalId, ...canalData, role: updatedRoles };

        // Sortie de la boucle après mise à jour d'un canal
        break;
      }

      if (!updatedCanal) {
        throw new Error('Aucun canal disponible à rejoindre.');
      }

      return updatedCanal;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
* Join canal by email
***
*/

export const joinCanalByEmail = createAsyncThunk(
  'canals/joinCanalByEmail',
  async ({ email, isAdmin = true, cid }, { rejectWithValue }) => {
    try {
      const db = getFirestore();
      const joiningDate = Timestamp.now().toDate();

      // Recherchez l'utilisateur par email
      const userQuery = query(collection(db, 'users'), where("email", "==", email));
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        throw new Error('Utilisateur introuvable avec cet email');
      }

      // Récupérez le document utilisateur
      const userDoc = userSnapshot.docs[0];
      const userId = userDoc.id;

      // Recherchez les canaux par leur code commune et l'ID du canal
      const canalQuery = query(collection(db, 'canals'), where("id", "==", cid));
      const canalQuerySnapshot = await getDocs(canalQuery);

      if (canalQuerySnapshot.empty) {
        throw new Error('Canal introuvable');
      }

      const userCanalsRef = collection(db, 'userCanals');
      let updatedCanal = null;

      for (const canalDoc of canalQuerySnapshot.docs) {
        const canalId = canalDoc.id;
        const canalData = canalDoc.data();

        // Vérifiez si l'utilisateur est déjà membre du canal
        const querySnapshot = await getDocs(query(userCanalsRef, where("userId", "==", userId), where("canalId", "==", canalId)));
        if (!querySnapshot.empty) {
          // Si l'utilisateur est déjà membre d'un canal avec isCanalAssociation, rejeter
          if (canalData.isCanalAssociation && canalData.canalId === 'canalId') {
            throw new Error('L\'utilisateur est déjà membre de ce canal avec association');
          }
          continue; // Ignore ce canal si l'utilisateur est déjà membre et que ce n'est pas une association
        }

        // Ajoutez l'ID du canal à la collection "userCanals" de l'utilisateur
        await addDoc(userCanalsRef, { userId, canalId, joiningDate });

        // Ajoutez le rôle de l'utilisateur au canal
        const newRole = { uid: userId, isAdmin };
        const updatedRoles = canalData.role ? [...canalData.role, newRole] : [newRole];

        // Mettez à jour le document du canal avec les nouvelles données
        const canalRef = doc(db, 'canals', canalId);
        await updateDoc(canalRef, { role: updatedRoles });

        // Récupérez les données du canal mis à jour
        updatedCanal = { id: canalId, ...canalData, role: updatedRoles };

        // Sortie de la boucle après mise à jour d'un canal
        break;
      }

      if (!updatedCanal) {
        throw new Error('Cet utilisateur semble membre de ce canal.');
      }

      return updatedCanal;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);






/*
* Update canal image
***
*/

export const updateCanalImage = createAsyncThunk(
  "animals/updateCanalImage",
  async ({ canalId, image }, { rejectWithValue, dispatch }) => {
    try {
      const db = getFirestore();
      const animalRef = doc(db, "canals", canalId);

      // Récupérer l'image actuelle du canal
      const animalDoc = await getDoc(animalRef);
      const animalData = animalDoc.data();

      // Si le canal a déjà une image, la supprimer de Firebase Storage
      if (animalData && animalData.image && animalData.image.name) {
        dispatch(deleteImageFromStorage(animalData.image.name));
      }

      // Uploader la nouvelle image
      const newImageUrl = await uploadImage(image);
      // Mettre à jour la référence de l'image dans Firestore
      await updateDoc(animalRef, { image: newImageUrl });

      return { canalId, imageUrl: newImageUrl };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


/*
* Modify canal cover Image
***    
*/


export const updateCanalCoverImage = createAsyncThunk(
  "animals/updateCanalCoverImage",
  async ({ canalId, image }, { rejectWithValue, dispatch }) => {
    try {
      const db = getFirestore();
      const animalRef = doc(db, "canals", canalId);

      // Récupérer l'image actuelle de l'animal
      const animalDoc = await getDoc(animalRef);
      const animalData = animalDoc.data();

      // Si le canal a déjà une image, la supprimer de Firebase Storage
      if (animalData && animalData.imageCover && animalData.imageCover.name) {
        dispatch(deleteImageFromStorage(animalData.imageCover.name));
      }

      // Uploader la nouvelle image
      const newImageUrl = await uploadImage(image);
      // Mettre à jour la référence de l'image dans Firestore
      await updateDoc(animalRef, { imageCover: newImageUrl });

      return { canalId, imageCoverUrl: newImageUrl };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



/*
* Modify canal list preferencies
***    
*/


export const updateCanalList = createAsyncThunk(
  "animals/updateCanalList",
  async ({ newValue, canalId }, { rejectWithValue }) => {
    try {
      const db = getFirestore();
      const canalRef = doc(db, "canals", canalId);

      await updateDoc(canalRef, { visibilityListEnabled: newValue });
      return { canalId, visibilityListEnabled: newValue };
    } catch (error) {
      console.error("Error updating canal list preference: ", error);
      return rejectWithValue(error.message);
    }
  }
);

/*
* Modify canal sector preferencies
***    
*/


export const updateCanalSectors = createAsyncThunk(
  "canals/updateCanalSectors",
  async ({ newValue, canalId }, { rejectWithValue }) => {
    try {
      const db = getFirestore();
      const canalRef = doc(db, "canals", canalId);

      await updateDoc(canalRef, { visibilitySectorsEnabled: newValue });
      return { canalId, visibilitySectorsEnabled: newValue };
    } catch (error) {
      console.error("Error updating sectors preference: ", error);
      return rejectWithValue(error.message);
    }
  }
);


/*
* Modify canal visibility preferencies
***    
*/


export const updateCanalReachabilty = createAsyncThunk(
  "canals/updateCanalReachabilty",
  async ({ newValue, canalId }, { rejectWithValue }) => {
    try {
      const db = getFirestore();
      const canalRef = doc(db, "canals", canalId);

      await updateDoc(canalRef, { canalReachable: newValue });
      return { canalId, canalReachable: newValue };
    } catch (error) {
      console.error("Error updating reachability preference: ", error);
      return rejectWithValue(error.message);
    }
  }
);

/*
* Modify user role
***    
*/


export const changeUserRole = createAsyncThunk(
  'canals/changeUserRole',
  async ({ userId, canalId, newIsAdmin }, { rejectWithValue }) => {


    try {
      const db = getFirestore();

      // Vérifiez si l'utilisateur existe dans la collection "users"
      const userRef = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userRef);
      if (!userSnapshot.exists()) {
        throw new Error('Utilisateur introuvable');
      }

      // Vérifiez si l'canal existe dans la collection "canals"
      const canalRef = doc(db, 'canals', canalId);
      const canalSnapshot = await getDoc(canalRef);
      if (!canalSnapshot.exists()) {
        throw new Error('Canal introuvable');
      }

      // Mettre à jour le rôle de l'utilisateur dans l'canal
      const canalData = canalSnapshot.data();
      for (let role of canalData.role) {
        if (role.uid === userId) {
          role.isAdmin = newIsAdmin;
          break;
        }
      }

      await updateDoc(canalRef, canalData);

      // Récupérez les données du canal
      const updatedCanalData = { id: canalSnapshot.id, ...canalData };

      return updatedCanalData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);




/*
* Update admin message
***    
*/

export const updateAdminMessageInCanal = createAsyncThunk(
  'canal/updateAdminMessage',
  async ({ canalId, adminMessage, adminMessageActive }, { rejectWithValue }) => {
    try {
      const db = getFirestore();
      const canalRef = doc(db, 'canals', canalId);

      // Mettre à jour le document avec le message administratif et son état (actif/inactif)
      await updateDoc(canalRef, { adminMessage, adminMessageActive });

      return { canalId, adminMessage, adminMessageActive };


    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


/*
* * * Fetch signalements by users
***    
*/

// Définition de l'action asynchrone
// export const fetchUserSignalementsInCanal = createAsyncThunk(
//   'animals/fetchUserSignalementsInCanal',
//   async ({ uid, canalId }) => {
//       const db = getFirestore();
//       const animalsRef = collection(db, "animals");
//       const q = query(animalsRef, where("userCreatorId", "==", uid), where("canalId", "==", canalId), where("signal", "==", true));
//       const querySnapshot = await getDocs(q);

//       let animals = [];
//       querySnapshot.forEach((doc) => {
//           animals.push({ id: doc.id, ...doc.data() });
//       });

//       return animals;
//   }
// );

export const fetchUserSignalementsInCanal = createAsyncThunk(
  'canals/fetchUserSignalementsInCanal',
  async ({ uid, canalId }, { getState }) => {
      const db = getFirestore();
      const animalsRef = collection(db, "animals");
      const q = query(animalsRef, where("userCreatorId", "==", uid), where("canalId", "==", canalId), where("signal", "==", true));
      const querySnapshot = await getDocs(q);

      // Compte les signalements
      const signalsCount = querySnapshot.size;

      return { uid, signalsCount };
  }
);


/*
* * * Canal slice
***    
*/

const canalsSlice = createSlice({
  name: 'canals',
  initialState: {
    status: 'idle',
    data: [],
    canalsAvailableForAssociations: [],
    error: null,
    usersSignalementsAnimals: [],
    usersSignalsCount: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCanals.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCanals.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchCanals.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCanalsByIds.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCanalsByIds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.canalsAvailableForAssociations = action.payload;
      })
      .addCase(fetchCanalsByIds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addCanal.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCanal.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.push(action.payload);
      })
      .addCase(addCanal.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateCanal.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCanal.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = state.data.map((canal) => {
          if (canal.id === action.payload.canalId) {
            return { ...canal, ...action.payload.canalData };
          }
          return canal;
        });
        if (state.selectedCanal && state.selectedCanal.id === action.payload.canalId) {
          state.selectedCanale = { ...state.selectedCanal, ...action.payload.canalData };
        }
      })
      .addCase(updateCanal.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
    builder.addCase(joinCanal.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(joinCanal.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.push(action.payload);
      })
      .addCase(joinCanal.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
      builder.addCase(joinCanalByEmail.pending, (state) => {
        state.status = 'loading';
      })
        .addCase(joinCanalByEmail.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.data.push(action.payload);
        })
        .addCase(joinCanalByEmail.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        });

    builder.addCase(changeUserRole.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(changeUserRole.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.push(action.payload);
      })
      .addCase(changeUserRole.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });


    builder.addCase(updateCanalList.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(updateCanalList.fulfilled, (state, action) => {
        state.status = 'succeeded';

        // Trouver l'index du canal à mettre à jour dans le tableau 'data'
        const index = state.data.findIndex(canal => canal.id === action.payload.canalId);

        if (index !== -1) {
          // Mettre à jour seulement la propriété 'visibilitySectorsEnabled' du canal trouvé
          state.data[index].visibilitySectorsEnabled = action.payload.visibilityListEnabledi;
        } else {
          // Gérer le cas où le canal n'est pas trouvé, si nécessaire
          console.log('Canal not found');
        }
      })
      .addCase(updateCanalList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });

    builder
      .addCase(updateCanalSectors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCanalSectors.fulfilled, (state, action) => {
        state.status = 'succeeded';

        // Trouver l'index du canal à mettre à jour dans le tableau 'data'
        const index = state.data.findIndex(canal => canal.id === action.payload.canalId);

        if (index !== -1) {
          // Mettre à jour seulement la propriété 'visibilitySectorsEnabled' du canal trouvé
          state.data[index].visibilitySectorsEnabled = action.payload.visibilitySectorsEnabled;
        } else {
          // Gérer le cas où le canal n'est pas trouvé, si nécessaire
          console.log('Canal not found');
        }
      })
      builder
      .addCase(updateCanalReachabilty.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCanalReachabilty.fulfilled, (state, action) => {
        state.status = 'succeeded';

        // Trouver l'index du canal à mettre à jour dans le tableau 'data'
        const index = state.data.findIndex(canal => canal.id === action.payload.canalId);

        if (index !== -1) {
          // Mettre à jour seulement la propriété 'visibilitySectorsEnabled' du canal trouvé
          state.data[index].canalReachable = action.payload.visibilitySectorsEnabled;
        } else {
          // Gérer le cas où le canal n'est pas trouvé, si nécessaire
          console.log('Canal not found');
        }
      })
    builder
      .addCase(updateAdminMessageInCanal.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateAdminMessageInCanal.fulfilled, (state, action) => {
        state.updateStatus = 'succeeded';
        const index = state.data.findIndex(canal => canal.id === action.payload.canalId);
        if (index !== -1) {
          // Mettre à jour seulement la propriété 'visibilitySectorsEnabled' du canal trouvé
          state.data[index].adminMessage = action.payload.adminMessage;
          state.data[index].adminMessageActive = action.payload.adminMessageActive;
        } else {
          // Gérer le cas où le canal n'est pas trouvé, si nécessaire
          console.log('Canal not found');
        }
      })
      .addCase(updateAdminMessageInCanal.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.error = action.payload;
      });

    builder.addCase(updateCanalSectors.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload; // Assurez-vous que l'erreur est transmise correctement
    });


    builder.addCase(updateCanalImage.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(updateCanalImage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { canalId, imageUrl } = action.payload;
        const canal = state.data.find((canal) => canal.id === canalId);
        if (!canal.image) {
          canal.image = {};
        }
        canal.image.url = imageUrl;
      })
      .addCase(updateCanalImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
    builder.addCase(updateCanalCoverImage.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(updateCanalCoverImage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { canalId, imageCoverUrl } = action.payload;
        const canal = state.data.find((canal) => canal.id === canalId);
        if (!canal.imageCover) {
          canal.imageCover = {};
        }
        canal.imageCover.url = imageCoverUrl;
      })
      .addCase(updateCanalCoverImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

    builder.addCase(removeCanal.pending, (state) => {
      state.status = 'loading';
    })
    builder.addCase(removeCanal.fulfilled, (state, action) => {
      state.status = 'succeeded';
      // remove the canal from the state
      state.data = state.data.filter(canal => canal.id !== action.payload.id);
    })
      .addCase(removeCanal.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });

      builder
      .addCase(fetchUserSignalementsInCanal.pending, (state) => {
          state.isLoading = true;
          state.error = null;
      })
      .addCase(fetchUserSignalementsInCanal.fulfilled, (state, action) => {
        const { uid, signalsCount } = action.payload;

        if (!state.usersSignalsCount) {
          state.usersSignalsCount = {}; // Assurez-vous que l'objet existe
      }
        state.usersSignalsCount[uid] = signalsCount;
    })
      .addCase(fetchUserSignalementsInCanal.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
      });
  },
});

export default canalsSlice.reducer;




