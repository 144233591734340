import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import DynamicMap from '../Maps/standardMap';
import Swal from 'sweetalert2';
import 'animate.css';
import MotherSelect from '../../components/animals/MotherAnimalSelect';
import { useTranslation } from 'react-i18next';
import { RACES } from '../../data/races';
import { HAIRS } from '../../data/hair';
import { COLORS } from '../../data/colors';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ContactModal from '../../components/modals/contactSignalModal.jsx';

import { fetchAnimalById, deleteAnimal, updateAnimal, updateAnimalImage, uploadSingleFile, addDocumentToAnimal, fetchMotherById, fetchAnimalsByMother, deleteFileFromStorage, deleteDocumentFromAnimal, fetchAnimalsByCanal, publishAnimal } from '../../features/animals/animalSlice';
import { createAndSendNotification, resetNotificationState } from '../../features/notifications/notificationSlice'


import EditableField from '../../components/general/EditableField';
import ColorSelect from '../../components/animals/ColorSelect';
import { calculateAge } from '../../utils/getAge.js';
import { NavLink } from 'react-router-dom';
import AnimalFilters from '../../components/animals/AnimalFilters';
import CommentSection from '../../components/general/Comments';
import PrivateCommentSection from '../../components/general/PrivateComments.jsx';
import Genealogy from '../../pages/Animals/genealogy'
import iconupdate from '../../assets/icon-update.svg';
import LoadingPage from '../../components/general/LoadingPage';
import chatbubbles from '../../assets/chatbubbles-outline.svg';
import chatbubbleswhite from '../../assets/chatbubbles-outline-white.svg';
import chatbubbleslockedwhite from '../../assets/speech-locked.svg';
import chatbubbleslockedorange from '../../assets/speech-locked-orange.svg';
import pins from '../../assets/logos/location-outline.svg'
import pinsOrange from '../../assets/logos/location-outline-orange.svg'

import documentattachoutline from '../../assets/document-attach-outline.svg';
import documentattachoutlinewhite from '../../assets/document-attach-outline-white.svg';


import ellipsishorizontaloutline from '../../assets/ellipsis-horizontal-outline.svg';
import ellipsishorizontaloutlinewhite from '../../assets/ellipsis-horizontal-outline-white.svg';

import idcardoutline from '../../assets/id-card-outline.svg';
import idcardoutlinewhite from '../../assets/id-card-outline-white.svg';


import informationcircleoutline from '../../assets/information-circle-outline.svg';
import informationcircleoutlinewhite from '../../assets/information-circle-outline-white.svg';

import pricetagoutline from '../../assets/pricetag-outline.svg';
import pricetagoutlinewhite from '../../assets/pricetag-outline-white.svg';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import logo from '../../assets/kappze_logo_without_square_bw.png';
import logocolor from '../../assets/transparent-without-circle.png'
import copy from '../../assets/copypaste.svg';
import BackToCanal from '../../components/general/BackToCanal';
import HairSelect from '../../components/animals/HairSelect';
import RaceSelect from '../../components/animals/RaceSelect';
import SituationSelect from '../../components/animals/SituationCatSelect';
import { Tooltip } from 'react-tooltip';
import helpOutline from '../../assets/help-outline.svg';
import ImageAnalyzer from '../../components/general/ImageUploader.jsx';
import { fetchSubSectors } from '../../features/citiesSector/subSectorSlice';
import PatternSelect from '../../components/animals/BreadSelect.jsx';
import AssociationSelect from '../../components/animals/AssociationSelect.jsx';
import { fetchAllAssociations, fetchAssociationsDetails } from '../../features/association/associationSlice.js';
import BackButton from '../../components/general/BackButton.jsx';
import SingleMarkerMap from '../Maps/signaledCatMap.jsx';

const AnimalDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { data: users } = useSelector((state) => state.canalUsers);
    const { allAssociations } = useSelector(state => state.associations);
    const associations = users.filter(user => user.isAssociation);
// console.log('USERs : ', users)

    const { isAuthenticated, uid, licenceStatus, email } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { status } = useSelector((state) => state.animals);
    const animal = useSelector((state) => state.animals?.selectedAnimal);
    const { data: citiesSector } = useSelector((state) => state.citiesSector);
    const { statusNotification, errorNotification } = useSelector((state) => state.notifications);





    const { data: subSectors } = useSelector((state) => state.subSectors);
    const { data: canals } = useSelector((state) => state.canals);
    const canal = canals.find((canal) => canal.id === animal?.canalId);

    const associationsValidate = useSelector((state) => state.associations?.associationsDetails);
    const canalId = canal?.id;
    // const users = useSelector(state => state.canalUsers.data);
    // const associationsInCanal = users.filter(user => user.isAssociation);

    const associationsInCanal = useMemo(() => {
        return users.filter(user => user.isAssociation && user.isAdmin);
    }, [users]); // Assurez-vous que canalId est bien la dépendance correcte ici





    const canalLicenseStatus = canal?.licenceMaire;
    const sectorsEnabled = canal?.visibilitySectorsEnabled;
    const { data: animals } = useSelector((state) => state.animals)
    const visitorAnimals = animals.filter((animal) => uid === animal.userCreatorId)
    const motherAnimals = animals.filter((animal) => animal.isMother);
    const motherAnimal = useSelector((state) => state.animals.motherAnimal);
    const motherAnimalsVisitor = visitorAnimals.filter((animal) => animal.isMother);
    const motherAnimalVisitor = useSelector((state) => state.animals.motherAnimal);

    const animalsStatus = useSelector((state) => state.animals.status);


    const [city, setCity] = useState('');
    const [subSector, setSubSector] = useState('');
    const [cityId, setCityId] = useState('');
    const [editableFields, setEditableFields] = useState([]);
    const [prevCityId, setPrevCityId] = useState('');
    const [currentAnimalId, setCurrentAnimalId] = useState(null);
    const [motherCatId, setMotherCat] = useState('');
    const [fatherCat, setFatherCat] = useState(null);
    const [motherId, setMotherId] = useState(motherAnimal?.id);
    const [mother, setMother] = useState(motherAnimal?.name);

    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedPatterns, setSelectedPatterns] = useState([]);
    const [selectedHairs, setSelectedHairs] = useState([]);
    const [selectedRaces, setSelectedRaces] = useState([]);
    const [initialColors, setInitialColors] = useState([]);
    const [initialOatterns, setInitialPatterns] = useState([]);
    const [isEditingColors, setIsEditingColors] = useState(false);
    const [initalHairs, setInitialHairs] = useState([]);
    const [isEditingHairs, setIsEditingHairs] = useState(false);
    const [initialRaces, setInitialRaces] = useState([]);
    const [isEditingRaces, setIsEditingRaces] = useState(false);
    const [situationCat, setSituationCat] = useState('');
    const [selectedSituationCat, setAnimalSituation] = useState(null);
    const [selectedAssociationCat, setAnimalAssociation] = useState(null);
    const [markers, setMarkers] = useState([]);

    const [situationId, setSituationId] = useState(null);
    const [associationId, setAssociationId] = useState(animal?.linkedAssociationId);


    const [selectedImage, setSelectedImage] = useState(null);
    const [isEditingImage, setIsEditingImage] = useState(false);
    const [filteredAnimals, setFilteredAnimals] = useState([]);
    const [filteredAnimalsVisitor, setFilteredAnimalsVisitor] = useState([]);

    const [showColorPopup, setShowColorPopup] = useState(false);
    const [showHairsPopup, setShowHairsPopup] = useState(false);
    const [showRacesPopup, setShowRacesPopup] = useState(false);
    const [showImagePopup, setShowImagePopup] = useState(false);
    const [showIAPopup, setShowIAPopup] = useState(false);
    const [userIsAdmin, setUserRole] = useState({});
    const [userIsCreator, setUserCreatorRole] = useState({});
    const [documents, setDocuments] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [isCatMissing, setCatIsMissing] = useState(animal?.isCatMissing);
    const [activeTab, setActiveTab] = useState('general-infos');
    const [tempData, setTempData] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const [updateFlags, setUpdateFlags] = useState({});
    const [open, setOpen] = useState(false);
    const [modalContactOwnerIsOpen, setOpenModalContactOwner] = useState(false);

    const [showSubSectors, setShowSubSectors] = useState('');

    const marker = {
        lat: animal?.location?.lat,
        lng: animal?.location?.lng
    };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };


    const handleMarkerAdded = (marker) => {
        setMarkers([marker]); // Garde seulement un marqueur à la fois
    };

    const sexAvailable = [{ name: `${t('male')}`, id: '0' }, { name: `${t('female')}`, id: '1' }, { name: `${t('unknown')}`, id: '2' }]


    const handleShowColorPopup = () => {

        setAnimationClass('popup-entering');
        setShowColorPopup(true);;
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleOpenIA = () => {
        setAnimationClass('popup-entering');
        setShowIAPopup(true);;
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleCloseIAPopup = () => {
        setTempData(null);
        setAnimationClass('popup-exiting');
        setTimeout(() => {
            setShowIAPopup(false);
            setShowPreview(null);
        }, 450);
        setTimeout(() => {
            setAnimationClass(null);
        }, 500);
    };


    const extractAndMapAttributes = (inputStr, mapping) => {
        if (inputStr) {
            const attributes = inputStr.toLowerCase().split(/\s+et\s+/i);
            return attributes
                .map(attr => {
                    const normalizedAttr = attr.trim(); // Supprimez les espaces inutiles
                    return mapping[normalizedAttr] || attr; // Utilisez la clé normalisée pour trouver la correspondance
                })
                .filter(Boolean);
        }
        return [];
    };

    const handleAnalysisComplete = (extractedData) => {

        const formattedBirthdate = extractedData.dateNaissance.split('/').reverse().join('-');
        const formattedIdentificationDate = extractedData.dateIdentification.split('/').reverse().join('-');

        const matchedRobe = extractAndMapAttributes(extractedData.robe, COLORS);
        const matchedTypeRacial = extractAndMapAttributes(extractedData.typeRacial, RACES); // Assurez-vous d'avoir une liste similaire pour les types raciaux
        const matchedHair = extractAndMapAttributes(extractedData.poil, HAIRS); // Assurez-vous d'avoir une liste similaire pour les types raciaux

        setTempData({
            age: formattedBirthdate,
            dateIdentification: formattedIdentificationDate,
            colors: matchedRobe !== 'non trouvé' ? matchedRobe : '/',
            races: matchedTypeRacial !== 'non trouvé' ? matchedTypeRacial : '/',
            hairs: matchedHair !== 'non trouvé' ? matchedHair : '/',
            name: extractedData.nomUsage,
            idNumber: extractedData.insert !== 'Non trouvé' ? extractedData.insert : extractedData.tatouage ? extractedData.tatouage : '',
            isSterilise: extractedData.sterilise === 'OUI' ? true : extractedData.sterilise === 'NON' ? false : '',
            sex: extractedData.sexe === 'MALE' ? 'Mâle' : extractedData.sexe === 'FEMELLE' ? 'Femelle' : '',
            hasIdNumber: extractedData.insert || extractedData.tatouage !== 'Non trouvé' ? true : false
        });

        setShowPreview(true);
    };


    const confirmUpdate = async () => {

        if (!tempData) {
            Swal.fire({
                icon: 'info',
                title: `${t('modal.noUpdateNecessaryTitle')}`,
                text: `${t('modal.thereIsNoDataToUpdate')}`,
                confirmButtonColor: '#2F4F4F',
                confirmButtonText: 'OK',
            });
            setShowPreview(false);
            setShowIAPopup(false);
            return;
        }
        const updates = {};
        let hasValidUpdates = false;

        Object.keys(tempData).forEach(fieldKey => {
            // Gérer le cas où la valeur est un tableau
            if (Array.isArray(tempData[fieldKey]) && (tempData[fieldKey].includes('Non trouvé') || tempData[fieldKey].includes('non trouvé'))) {
                return;
            }

            // Gérer le cas où la valeur est une chaîne
            if (typeof tempData[fieldKey] === 'string' && (tempData[fieldKey] === 'Non trouvé' || tempData[fieldKey] === 'non trouvé')) {
                return;
            }

            if (updateFlags[fieldKey]) {
                updates[fieldKey] = tempData[fieldKey];
            }

            // updates[fieldKey] = tempData[fieldKey];
            hasValidUpdates = true;
        });

        if (hasValidUpdates) {
            await dispatch(updateAnimal({ animalId: id, animalData: updates }))
                .unwrap()
                .then(() => {


                    Swal.fire({
                        icon: 'success',
                        title: `${t('modal.dataUpdatedTitle')}`,
                        text: `${t('modal.dataUpdated')}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    });

                    const message = `${animal.name} a été mis à jour par la modération. `;
                    const subject = `${animal.name} a été modifié - ${canal.name}.`
                    // const authorized = true;
                    const userIds = [];
                    users.forEach(user => {
                        if (user.licenceStatus === 'active' || (user.isAdmin === true && user.notificationsAdminPreferences?.[canal?.id] === true)) {

                            userIds.push(user?.id)
                        }
                    });

                    dispatch(createAndSendNotification({ userIds, message, subject }));
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.errorUpdatingData')}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    });
                });
        } else {
            // Afficher un message si aucune donnée valide n'est à mettre à jour
            Swal.fire({
                icon: 'info',
                title: `${t('modal.noUpdateNecessaryTitle')}`,
                text: `${t('modal.thereIsNoDataToUpdate')}`,
                confirmButtonColor: '#2F4F4F',
                confirmButtonText: 'OK',
            });
        }

        setShowPreview(false);
        setShowIAPopup(false);
        setTempData({});
    };


    const handleCloseColorPopup = () => {

        setAnimationClass('popup-exiting');

        setTimeout(() => {
            setShowColorPopup(false);
        }, 450);
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleShowRacePopup = () => {

        setAnimationClass('popup-entering');
        setShowRacesPopup(true);;
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };


    const handleCloseRacePopup = () => {

        setAnimationClass('popup-exiting');

        setTimeout(() => {
            setShowRacesPopup(false);
        }, 450);
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleShowHairPopup = () => {

        setAnimationClass('popup-entering');
        setShowHairsPopup(true);;
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleCloseHairPopup = () => {

        setAnimationClass('popup-exiting');

        setTimeout(() => {
            setShowHairsPopup(false);
        }, 450);
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };



    const handleShowImagePopup = () => {

        setAnimationClass('popup-entering');
        setShowImagePopup(true);
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleCloseImagePopup = () => {

        setAnimationClass('popup-exiting');
        setAnimationClass('');
        setShowImagePopup(false);
    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(id);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Réinitialiser après 2 secondes
        } catch (err) {
            console.error('Erreur de copie', err);
        }
    };

    const handleDelete = () => {
        Swal.fire({
            icon: 'danger',
            title: 'Attention',
            text: `${t('modal.doYouReallyWantToDeleteThisAnimal')}`,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#2F4F4F',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler', showClass: {
                popup: 'animate__animated animate__fadeIn'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOut'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteAnimal(id))
                    .unwrap()
                    .then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: `${t('modal.success')}`,
                            text: `${t('modal.theAnimalWasDeletedWithSuccess')}`,
                            confirmButtonColor: '#2F4F4F',
                            confirmButtonText: 'OK',
                            showClass: {
                                popup: 'animate__animated animate__fadeIn'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOut'
                            }
                        }).then(() => {
                            navigate(-1, { from: navigate.location });
                        });
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: `${t('modal.error')}`,
                            text: `${t('modal.errorOccurredWhenTryingToDeleteTheAnimal')} ${error}`,
                            confirmButtonColor: '#2F4F4F',
                            confirmButtonText: 'OK',
                            showClass: {
                                popup: 'animate__animated animate__fadeIn'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOut'
                            }
                        });
                    });
            }
        });
    };


    const handlePublish = () => {
        Swal.fire({
            icon: 'danger',
            title: `${t('modal.attention')}`,
            text: `${t('modal.areYouSureYouWantToPublishThisAnimal')}`,
            showCancelButton: true,
            confirmButtonColor: '#2F4F4F',
            cancelButtonColor: '#2F4F4F',
            confirmButtonText: `${t('modal.publish')}`,
            cancelButtonText: `${t('modal.cancel')}`, showClass: {
                popup: 'animate__animated animate__fadeIn'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOut'
            }
        }).then((result) => {
            if (result.isConfirmed) {

                const message = `Cher membre, votre signalement sur le canal ${canal.name} concernant l'animal ayant l'ID ${animal.id} a été validé, félicitations. Votre Commune et Kappze vous remercie chaleureusement de votre implication dans la protection animale.`;
                const subject = `Votre signalement sur ${canal.name} a été validé`;
                // const authorized = true;
                let userIds = [];
                let adminIds = [];
                const messageAdmin = `Cher membre, le signalement sur le canal ${canal.name} concernant l'animal ayant l'ID ${animal.id} a été validé, félicitations. Kappze vous remercie chaleureusement de votre implication dans la protection animale.`;
                const subjectAdmin = `Un signalement sur ${canal.name} a été validé`;
                users.forEach(user => {
                    if (user.email === animal.userSignal) {
                        userIds.push(user?.id)
                    } else if (user.isAdmin === true && user.notificationsAdminPreferences?.[canal.id] === true) {
                        adminIds.push(user?.id)
                    }
                });
                dispatch(publishAnimal(id))
                dispatch(createAndSendNotification({ userIds: userIds, message: message, subject: subject }))
                dispatch(createAndSendNotification({ userIds: adminIds, message: messageAdmin, subject: subjectAdmin }))

                    .unwrap()
                    .then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: `${t('modal.success')}`,
                            text: `${t('modal.animalPublishedWithSuccess')}`,
                            confirmButtonColor: '#2F4F4F',
                            confirmButtonText: 'OK',
                            showClass: {
                                popup: 'animate__animated animate__fadeIn'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOut'
                            }
                        }).then(() => {
                            navigate(-1, { from: navigate.location });
                        });
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: `${t('modal.error')}`,
                            text: `${t('modal.anErrorOccurendWhenTryingToPublishTheAnimal')} ${error}`,
                            confirmButtonColor: '#2F4F4F',
                            confirmButtonText: 'OK',
                            showClass: {
                                popup: 'animate__animated animate__fadeIn'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOut'
                            }
                        });
                    });
            }
        });
    };


    const handleUpdateCityOrSectorField = (fieldKey, fieldValue, idFieldKey, idValue, type) => {

        const updatedData = { ...animal, [fieldKey]: fieldValue, [idFieldKey]: idValue };
        if (!idValue) {
            return;
        }
        if (type === 'city') {
            dispatch(fetchSubSectors(idValue))
        }
        dispatch(updateAnimal({ animalId: id, animalData: updatedData }))


            .unwrap()
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.dataUpdatedTitle')}`,
                    text: `${t('modal.dataUpdated')}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
                setEditableFields((prevFields) => {
                    const updatedFields = prevFields.map((field) => {
                        if (field.key === fieldKey) {
                            return { ...field, value: fieldValue };
                        } else if (field.key === idFieldKey) {
                            return { ...field, value: idValue };
                        }
                        return field;
                    });
                    return updatedFields;
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.errorUpdatingData')}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                });
            });
    };

    const handleUpdateField = (fieldKey, fieldData) => {

        // console.log(fieldKey, fieldData)

        // const isBooleanField = ["hasIdNumber", "isBelonged", "isSterilise"].includes(fieldKey);

        // console.log('isBooleanfield : ', isBooleanField)
        // const value = isBooleanField ? fieldData === 'true' : fieldData;
        const value = fieldData;
        // console.log('fieldData : ', fieldData)
        // console.log('value', value)
        let updatedData;
        // const updatedData = {
        //     ...animal,
        //     ...(typeof fieldData === 'object' ? fieldData : { [fieldKey]: fieldData }),
        // };
// console.log(fieldKey, fieldData)

        if (fieldKey === 'location' && typeof fieldData === 'object') {
            updatedData = {
                ...animal,
                location: { ...fieldData }
            };
        } else if (fieldKey === 'image' && typeof fieldData === 'object') {

            updatedData = {
                ...animal,
                image: { ...fieldData }
            };
        } else {
            // console.log(value)
            updatedData = {

                
                ...animal,
                [fieldKey]: value
            };
        }

        dispatch(updateAnimal({ animalId: id, animalData: updatedData }))
            .unwrap()
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.dataUpdatedTitle')}`,
                    text: `${t('modal.dataUpdated')}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })

                setEditableFields((prevFields) => {
                    const updatedFields = prevFields.map((field) => {
                        if (field.key === fieldKey) {
                            return { ...field, value: fieldData };
                        }
                        return field;
                    });
                    return updatedFields;
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.errorUpdatingData')} ${fieldKey} : ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                });
            });
    };

    const handleColorChange = (event) => {
        const color = event.target.value;
        if (event.target.checked) {
            setSelectedColors((prevColors) => [...prevColors, color]);
        } else {
            setSelectedColors((prevColors) => prevColors.filter((c) => c !== color));
        }
    };

    const handlePatternChange = (event) => {
        const pattern = event.target.value;
        if (event.target.checked) {
            setSelectedPatterns((prevPatterns) => [...prevPatterns, pattern]);
        } else {
            setSelectedPatterns((prevPatterns) => prevPatterns.filter((c) => c !== pattern));
        }
    };


    const handleHairsChange = (event) => {
        const hair = event.target.value;
        if (event.target.checked) {
            setSelectedHairs((prevHairs) => [...prevHairs, hair]);
        } else {
            setSelectedHairs((prevHairs) => prevHairs.filter((c) => c !== hair));
        }
    };

    const handleRacesChange = (event) => {
        const race = event.target.value;
        if (event.target.checked) {
            setSelectedRaces((prevRaces) => [...prevRaces, race]);
        } else {
            setSelectedRaces((prevRaces) => prevRaces.filter((c) => c !== race));
        }
    };


    const handleUpdateColors = (updatedDataIA) => {
        handleCloseColorPopup(); // Fermer la popup des couleurs
        if (updatedDataIA === 'Non trouvé' || updatedDataIA === 'non trouvé') return;
        const updatedData = { ...animal, colors: selectedColors, patterns: selectedPatterns };
        dispatch(updateAnimal({ animalId: id, animalData: updatedData ? updatedData : updatedDataIA }))

            .unwrap()
            .then(() => {
                setTimeout(() => {
                    Swal.fire({
                        icon: 'success',
                        title: `${t('modal.success')}`,
                        text: `${t('modal.colorsHasBeenUpdated')}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    });
                }, 500);
                setInitialColors(selectedColors);
                setInitialPatterns(selectedPatterns)
                setIsEditingColors(false);

            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.anErrorOccuredWhenTryingToUpdateThisAnimal')} ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                });
            });
    };

    const handleUpdateHairs = (updatedDataIA) => {
        if (updatedDataIA === 'Non trouvé' || updatedDataIA === 'non trouvé') return;
        const updatedData = { ...animal, hairs: selectedHairs };
        dispatch(updateAnimal({ animalId: id, animalData: updatedData ? updatedData : updatedDataIA }))
            .unwrap()
            .then(() => {
                setTimeout(() => {
                    Swal.fire({
                        icon: 'success',
                        title: `${t('modal.catHairTypeHasBeenUpdatedTitle')}`,
                        text: `${t('modal.catHairTypeHasBeenUpdated')}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    });
                }, 500);
                setInitialHairs(selectedHairs);
                setIsEditingHairs(false);
                handleCloseHairPopup(); // Fermer la popup des couleurs
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.anErrorOccuredWhenTryingToUpdateThisAnimal')} ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                });
            });
    };

    const handleUpdateRaces = (updatedDataIA) => {
        if (updatedDataIA === 'Non trouvé' || updatedDataIA === 'non trouvé') return;
        const updatedData = { ...animal, races: selectedRaces };
        dispatch(updateAnimal({ animalId: id, animalData: updatedData ? updatedData : updatedDataIA }))
            .unwrap()
            .then(() => {
                setTimeout(() => {
                    Swal.fire({
                        icon: 'success',
                        title: `${t('modal.breedTitle')}`,
                        text: `${t('modal.breedHasBeenUpdated')}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    });
                }, 500);
                setInitialRaces(selectedRaces);
                setIsEditingRaces(false);
                handleCloseRacePopup(); // Fermer la popup des couleurs
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.anErrorOccuredWhenTryingToUpdateThisAnimal')} ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                });
            });
    };

    const handleImageChange = (event) => {
        setSelectedImage(event.target.files[0]);
    };


    const handleObjectFormAnimalChange = async (id, name) => {
        setMother(name);
        setMotherId(id);

        // S'assurer que chaque mise à jour se termine avant de commencer la suivante
        try {
            await handleUpdateField('motherAppId', id);
        } catch (error) {
            console.error("Error updating motherAppId:", error);
            return; // Arrêter l'exécution si la première mise à jour échoue
        }
    };


    const handleObjectFormAnimalSituationChange = async (id, situation) => {
        setAnimalSituation(situation);
        setSituationId(id);

        try {
            await handleUpdateField('situationCat', situation);

        } catch (error) {
            console.error("Error updating fields:", error);
        }
    };


    const handleUpdateMarker = async (position) => {

        try {
            await handleUpdateField('location', position);
            // Mettez à jour l'état local si nécessaire
        } catch (error) {
            console.error("Error updating marker position:", error);
        }
    };

    const handleDeleteMarker = async (id) => {
        try {
            await handleUpdateField('location', null);
            // Mettez à jour l'état local si nécessaire
        } catch (error) {
            console.error("Error deleting marker:", error);
        }
    };

    const handleObjectFormAnimalAssociationChange = async (id, situation) => {
        setAnimalAssociation(situation);
        setAssociationId(id);

        const isLinkedToAssoc = !!id;  // true si id n'est pas null, false sinon
        const updateData = {
            linkedAssociationId: id,
            isLinkedToAnAssociation: isLinkedToAssoc,
            associationName: situation
        };

        try {
            await handleUpdateField('associationLink', updateData);
            setIsEditMode(false)
        } catch (error) {
            console.error("Error updating fields:", error);
        }
    };





    const handleUpdateImage = (e) => {
        handleCloseImagePopup(); // Fermer la popup de l'image
        setIsEditingImage(false);
        dispatch(
            updateAnimalImage({ animalId: animal.id, image: selectedImage })
        )

            .unwrap()
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.anErrorOccuredWhenTryingToUpdateThisAnimal')} ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                });
            });
    };

    const handleUploadFiles = async (event) => {
        event.preventDefault();
        if (documents?.length > 0) {
            try {
                const uploadedDocuments = await Promise.all(documents.map(uploadSingleFile, animal.name));
                dispatch(
                    addDocumentToAnimal({
                        animalId: currentAnimalId,
                        documents: uploadedDocuments
                    })
                );

                const message = 'Un nouveau document est disponible pour l\'animal : ' + animal?.name;
                const subject = 'Un document a été uploadé sur Kappze';
                // const authorized = true;
                const userIds = [];
                users.forEach(user => {
                    if (user.isAdmin === true) {
                        userIds.push(user?.id)
                    }

                });

                dispatch(createAndSendNotification({ userIds, message, subject }));

                Swal.fire({
                    icon: 'success',
                    title: 'Documents ajoutés',
                    text: 'Les documents ont été ajoutés avec succès.',
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                }).then(() => {
                    navigate(0);
                })



            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.anErrorOccuredWhenTryingToUploadFiles')} ${error.message}`,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                });
            }
        }
    };

    const handleDeleteDocument = (name) => {
        Swal.fire({
            icon: 'warning',
            title: `${t('modal.attention')}`,
            text: `${t('modal.areYouSureYouWantToDeleteThiDocument')}`,
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#2F4F4F',
            confirmButtonText: `${t('modal.delete')}`,
            cancelButtonText: `${t('modal.cancel')}`,
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteFileFromStorage(name))
                    .unwrap()
                    .then(() => {
                        dispatch(deleteDocumentFromAnimal({ animalId: currentAnimalId, documentName: name }));
                        Swal.fire({
                            icon: 'success',
                            title: `${t('modal.success')}`,
                            text: `${t('modal.theFileHasBeenDeletedWithSuccess')}`,
                            confirmButtonColor: '#2F4F4F',
                            confirmButtonText: 'OK',
                            showClass: {
                                popup: 'animate__animated animate__fadeIn'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOut'
                            }
                        })
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: `${t('modal.error')}`,
                            text: `${t('modal.anErrorOccuredWhenTryingToDeleteTheFile')} ${error}`,
                            confirmButtonColor: '#2F4F4F',
                            confirmButtonText: 'OK',
                            showClass: {
                                popup: 'animate__animated animate__fadeIn'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOut'
                            }
                        });
                    });
            }
        });
    };
    useEffect(() => {
        if (id !== currentAnimalId) {
            dispatch(fetchAnimalById(id));
            dispatch(fetchAnimalsByCanal(animal?.canalId));
            setCurrentAnimalId(id);


        }
    }, [id, dispatch, currentAnimalId, animals]);

    useEffect(() => {
        if (animal && animal.id === id) {

            // console.log('animal', animal)
            const fields = [
                { key: 'name', label: 'Nom ', value: animal.name, type: "text" },
                { key: 'age', label: 'Date de naissance ', value: animal.age, type: "date" },
                { key: 'canalName', label: 'Canal ', value: animal.canalName },
                { key: 'linkedAssociationId', label: 'Prise en charge ', value: animal.linkedAssociationId },
                { key: 'cityName', label: 'Secteur ', value: animal.cityName, id: animal.cityId },
                { key: 'subSectorName', label: 'Sous-secteur ', value: animal.subSectorName, id: animal.subSectorId },
                { key: 'hasIdNumber', label: 'Est identifié ? ', value: animal.hasIdNumber, type: "checkbox" },
                { key: 'idNumber', label: 'Numéro d\'identification ', value: animal.idNumber },
                { key: 'dateIdentification', label: 'Date d\'identification ', value: animal.dateIdentification, type: "date" },
                { key: 'isBelonged', label: 'Appartient à un particulier ? ', value: animal.isBelonged, type: "checkbox" },
                { key: 'isSterilise', label: 'Est stérilisé ? ', value: animal.isSterilise, type: "checkbox" },
                { key: 'isTrapped', label: 'A été capturé ? ', value: animal.isTrapped, type: "checkbox" },
                { key: 'isSick', label: 'Semble malade ou blessé ? ', value: animal.isSick, type: "checkbox" },
                { key: 'diseases', label: 'Maladies ', value: animal.diseases },
                { key: 'particularites', label: 'Particularités ', value: animal.particularites },
                { key: 'isDeath', label: 'Décès ', value: animal.death, type: "checkbox" },
                { key: 'isFamily', label: 'Est issu d\'une famille ', value: animal.isFamily, type: "checkbox" },
                { key: 'isMother', label: 'Est une mère ', value: animal.isMother, type: "checkbox" },
                { key: 'motherAppId', label: 'appId de la mère ', value: animal.motherAppId, type: "text" },
                { key: 'fatherAppId', label: 'appId du père ', value: animal.fatherAppId, type: "text" },
            ];
            setEditableFields(fields);
            setCity(animal?.cityName);
            setSubSector(animal?.subSectorName);
            setCityId(animal?.cityId);

            if (animal.cityId !== prevCityId) {
                setPrevCityId(animal.cityId);
            }
        } else {
            setEditableFields([]);
        }
    }, [animal, dispatch, prevCityId]);

    useEffect(() => {
        if (animal) {
            setSelectedColors(animal.colors);
            setInitialColors(animal.colors);
            setSelectedPatterns(animal.patterns);
            setInitialPatterns(animal.patterns)
            setSelectedHairs(animal.hairs);
            setSelectedRaces(animal.races);
            setCatIsMissing(animal.isCatMissing)

        }
    }, [animal]);

    useEffect(() => {
        if ((animal && animal.id === id) && (animal.motherAppId)) {
            dispatch(fetchMotherById(animal.motherAppId));
            setMotherCat(animal.motherAppId);
            setFilteredAnimals(animals.filter(animale => (animale.motherAppId === animal.motherAppId || animale.id == animal.motherAppId) && animale.id !== animal.id));
            setFilteredAnimalsVisitor(animals.filter(animale => (animale.motherAppId === animal.motherAppId || animale.id == animal.motherAppId) && animale.id !== animal.id && animale.published))
        } else if ((animal && animal.id === id) && (animal.isMother && !animal.motherAppId)) {
            dispatch(fetchAnimalsByMother(id))
            setFilteredAnimals(animals.filter(animal => animal.motherAppId === id));
            setMotherCat('');

        } else {
            setMotherCat('');
            // return
        }
    }, [id, dispatch, currentAnimalId, animal, motherCatId, motherId]);

    useEffect(() => {
        // Trouver l'utilisateur actuel par uid
        const currentUser = users.find(user => user.id === uid);

        // Définir le rôle de l'utilisateur actuel, ou 'null' si non trouvé
        // (currentUser.isAdmin && !currentUser.isAssociation) || (currentUser.isAssociation && animal.linkedAssociationId includes currentUser.linkedAssociationids) || 
        // (!currentUser.isAssociation && currentUser.IsLinkedToAnAssociation && animal.linkedAssociationId includes currentUser.linkedAssociationIds) 
        // setUserRole(currentUser ? currentUser.isAdmin : null);

        setUserRole((currentUser?.isAdmin || currentUser?.isAdmin && !currentUser?.isAssociation) || (currentUser?.isAssociation && currentUser?.linkedAssociationIds.includes(animal?.linkedAssociationId))
            || (!currentUser?.isAssociation && currentUser?.isLinkedToAnAssociation && currentUser?.linkedAssociationIds.includes(animal?.linkedAssociationId) || (!currentUser?.isAssociation && currentUser?.isLinkedToAnAssociation && !animal?.linkedAssociationId) ? true : null))
        setUserCreatorRole((!currentUser?.isAdmin && currentUser?.id === animal?.userCreatorId ? true : false))

    }, [users, uid, animal]);


    useEffect(() => {
        const flags = {};

        if (!tempData) {
            return;
        }

        Object.keys(tempData).forEach(key => {
            // Gérer le cas d'une chaîne de caractères
            if (typeof tempData[key] === 'string') {
                flags[key] = !['Non trouvé', 'non trouvé'].includes(tempData[key]);
            }
            // Gérer le cas d'un tableau
            else if (Array.isArray(tempData[key])) {
                flags[key] = !tempData[key].includes('Non trouvé') && !tempData[key].includes('non trouvé');
            }
            // Pour les autres types de données
            else {
                flags[key] = true; // Par défaut, activer le switch
            }
        });

        setUpdateFlags(flags);
    }, [tempData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        if (animal?.cityId) {
            dispatch(fetchSubSectors(animal?.cityId));
        }
    }, [dispatch, animal?.cityId, id]);

    useEffect(() => {
        if (associationsInCanal.length > 0) {
            const sirets = associationsInCanal.map(assoc => assoc.associationId).filter(siret => siret);

            if (sirets.length > 0) {
                dispatch(fetchAssociationsDetails(sirets));
            }
        }
    }, [dispatch, associationsInCanal]); // Utilisation sûre car associationsInCanal est stabilisé par useMemo

    useEffect(() => {
        return () => {
            // Reset state on component unmount
            dispatch(resetNotificationState());
        };
    }, [dispatch]);


    // useEffect(() => {

    //     console.log('associationInCanal : ', associationsInCanal)


    //     dispatch(fetchAssociationsDetails(associationsInCanal.map(assoc => assoc.siretAsso)));
    // }, [dispatch]);

    // useEffect(() => {
    //     if (associationsInCanal.length > 0) {
    //         const validSirets = associationsInCanal
    //             .map(assoc => assoc.siretAsso) // Transformer les associations en un tableau de SIRET
    //             .filter(siret => siret !== undefined); // Filtrer pour exclure les valeurs undefined

    //         if (validSirets.length > 0) {
    //             dispatch(fetchAssociationsDetails(validSirets));
    //         } else {
    //             console.log("No valid SIRETs found in the current canal.");
    //         }
    //     }
    // }, [dispatch, associationsInCanal]);

    const handleSendEmail = (description) => {
        const messageHtml = `
          <div style="font-family: Arial, sans-serif; color: #333;">
            <h1 style="color: #2f4f4f;">Contact concernant votre animal perdu, ${animal?.name}</h1>
            <p>Ce message vous a été envoyé par un utilisateur de la commune de : <strong>${canal?.city}</strong>.</p>
            <p>${description}</p>
            <br/>
            <p>----</p>
            <p>Vous pouvez le recontacter à cet email : <a href="mailto:${email}" style="color: #4A90E2;">${email}</a> </p>
            <p style="font-size: 0.9em; color: #666;">Veuillez ne pas répondre directement à cet email.</p>
          </div>
        `;

        const subject = `Un utilisateur provenant de la commune de : ${canal?.city} souhaite vous contacter concernant votre animal.`;
        const authorized = true;
        const userIds = [];
        userIds.push(animal?.userCreatorId);

        // Dispatch the notification with HTML content
        dispatch(createAndSendNotification({ userIds, message: messageHtml, subject, authorized }));
    };

    useEffect(() => {
        if (modalContactOwnerIsOpen === false) {
            dispatch(resetNotificationState());
        }
    }, [modalContactOwnerIsOpen, dispatch]);



    if (animalsStatus === 'loading') {

        return <LoadingPage isLoading={true} />;
    }

    return (
        <div className={`kappze-page kappze-form user-page animal-page ${!animal?.published ? 'unpublished-animal' : ''}`}>
            <LoadingPage isLoading={false} />
            <div className='page-content-afterload'>
                {/* <BackButton /> */}
                {/* <BackToCanal id={animal?.canalId} isSignal={true} /> */}

                <BackButton />
                <div className={`profile-cat page-main ${!animal?.published ? 'unpublished-animal' : ''}`} style={{ color: 'white', padding: '3rem' }}>

                    {animal && status === 'succeeded' ? (
                        <div className='profile-cat_section'>

                            {(animal?.isCatMissing && isAuthenticated) && (
                                <div className='information-unpublished' style={{ display: 'flex', flexDirection: 'column', borderRadius: '20px', marginBottom: '2rem' }}>
                                    <div className="information-unpublished-text">
                                        <p style={{ textAlign: 'center', fontWeight: 'bolder', color: 'rgb(209, 94, 65)' }}>Cet animal est recherché.</p>
                                        <p style={{ textAlign: 'center' }}>Vous pensez l'avoir aperçu ?</p>
                                    </div>

                                    <div className="information-unpublished-text">
                                        <button style={{ backgroundColor: 'rgb(209, 94, 65)' }} className='information-unpublished_btn information-unpublished_btn-validate' onClick={() => setOpenModalContactOwner(true)}>Contacter le propriétaire</button>
                                        {statusNotification === true && <p style={{ color: '#000', textAlign: 'center' }}>Email envoyé avec succès !</p>}
                                        {statusNotification === false && <p style={{ color: '#c40030' }}>Error: {errorNotification}</p>}
                                    </div>


                                    {modalContactOwnerIsOpen && (<>
                                        <ContactModal
                                            isOpen={modalContactOwnerIsOpen}
                                            onClose={() => setOpenModalContactOwner(false)}
                                            sendEmail={handleSendEmail}
                                        />
                                    </>)}

                                    {/* {marker.lat && marker.lng && (<SingleMarkerMap communeCode={canal?.codeCommune} marker={marker} animalImage={animal?.image?.url} />
                                    )} */}


                                </div>
                            )}



                            {(!animal?.published && isAuthenticated) && (

                                <div className='information-unpublished' style={{ display: 'flex', flexDirection: 'column', borderRadius: '20px' }}>
                                    <div className="information-unpublished-text">
                                        {animal?.signalByOwner ? (<p style={{ textAlign: 'center' }}>Le propriétaire a signalé son animal.</p>) : (<p style={{ textAlign: 'center' }}>Cet animal a été signalé sans propriétaire.</p>)}

                                        <p style={{ textAlign: 'center' }}><b>L'email de contact est : </b> {animal.userSignal}</p>

                                        {animal.signalByOwner ? (<></>) : (<p style={{ textAlign: 'center' }}><b>Description :</b> {animal.commentSignal}</p>)}

                                    </div>

                                    <div className="information-unpublished-text">
                                        <button className='information-unpublished_btn information-unpublished_btn-validate' onClick={() => handlePublish(id)}>Publier</button>
                                        <button className='information-unpublished_btn information-unpublished_btn-refuse button-supp' onClick={() => handleDelete(id)}>Refuser</button>
                                    </div>

                                    {marker.lat && marker.lng && (<SingleMarkerMap communeCode={canal?.codeCommune} marker={marker} animalImage={animal?.image?.url} />
                                    )}


                                </div>


                            )}

                            <div className="profile-cat-first-row">
                                <div className="profile-cat-first-row_column-1">
                                    <div className='profile-cat-first-row_column-1_div-img'>
                                        <div className="profile-cat-first-row_column-1_img">
                                            {animal?.image?.url ? (<LazyLoadImage effect="blur" src={animal?.image?.url} alt={`Image de ${animal.name}`} style={{ ...(animal.death ? { filter: 'grayscale(100%)' } : {}), cursor: 'pointer' }} onClick={() => setOpen(true)} />) : !animal?.image?.url ? (
                                                <LazyLoadImage effect="blur" src={logo} style={{ objectFit: 'contain', background: '#26252d', ...(animal.death ? { filter: 'grayscale(100%)' } : {}) }} alt={`Image de ${animal.name}`} />
                                            ) : (null)}
                                            {showImagePopup && (
                                                <div className={`popup-container-img popup-container ${animationClass}`}>
                                                    <div className="popup popup-img">
                                                        <div className="popup-logo">
                                                            <img src={logocolor} />
                                                        </div>
                                                        <h2 className='editable-fields-label popup-image-label'>Modifier l'image de profil</h2>
                                                        <input type="file" id="upload" onChange={handleImageChange} hidden />
                                                        <label className="label-upload" htmlFor="upload">Sélectionner un fichier</label>
                                                        <span id="file-chosen" className="popup-chosen-image" style={{ color: '#122' }}>{selectedImage ? selectedImage.name : ('Aucun fichier choisi')}</span>

                                                        <div className="popup-content popup-content-image">
                                                            <button className="btn-cancel" onClick={handleCloseImagePopup}>Annuler</button>
                                                            <button className="btn-validate" onClick={handleUpdateImage}>Valider</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <Lightbox
                                            open={open}
                                            close={() => setOpen(false)}
                                            slides={[
                                                { src: animal?.image?.url },
                                            ]}
                                        />

                                        <div className="btn-update-img">
                                            {(userIsAdmin || userIsCreator) && (
                                                <button className={`button-update ${isEditMode ? 'visible' : 'hidden'}`} onClick={handleShowImagePopup}>
                                                    <img style={{ width: "14px", margin: "2px", color: '#FFF' }} src={iconupdate} alt="Icon Update" className="button-icon" />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="profile-cat-frist-row_column-1_name-age">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <EditableField
                                                label={'Modifier le nom '}
                                                value={animal.name ? animal.name : 'Aucun nom'}
                                                onSave={(value) => handleUpdateField('name', value)}
                                                type='text'
                                                isAdmin={userIsAdmin || userIsCreator}
                                                customClass={'animalName'}
                                                isEditMode={isEditMode}
                                            />
                                            {animal.death && (
                                                <p style={{ color: '#fff' }}>✝ Cet animal a été renseigné comme décédé. </p>
                                            )}

                                            <EditableField
                                                label="Modifier le sexe "
                                                value={animal.sex}
                                                onSave={(value) => {
                                                    const selectedOption = sexAvailable.find((sex) => sex.id === value);
                                                    const name = selectedOption ? selectedOption.name : "";
                                                    handleUpdateField('sex', name)
                                                }}
                                                type="select"
                                                options={sexAvailable}
                                                optionsKey='id'
                                                displayValue={animal.sex ? (animal.sex === 'Mâle' ? `${t('male')}` : (animal.sex === 'Femelle' ? `${t('female')}` : `${t('sexUnknown')}`)) : `${t('sexUnknown')}`}
                                                isAdmin={userIsAdmin || userIsCreator}
                                                isEditMode={isEditMode}
                                            />


                                            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem', rowGap: '1rem', flexWrap: 'wrap', width: '100%' }}>
                                                {animal.isBelonged === true && (
                                                    <div className="pastille_situation pastille_situation-belonged pastille_situation-profile">
                                                        <span className="pastille_role-editor-text">{t('owner')}</span>
                                                    </div>
                                                )}



                                                {(!animal.isBelonged && !animal.hasIdNumber) && (
                                                    <div className="pastille_situation pastille_situation-errant pastille_situation-profile">
                                                        <span className="pastille_role-editor-text">{t('wandering')}</span>
                                                    </div>
                                                )}

                                                {(!animal.isBelonged && animal.hasIdNumber) && (
                                                    <div className="pastille_situation pastille_situation-libre pastille_situation-profile">
                                                        <span className="pastille_role-editor-text">{t('free')}</span>
                                                    </div>
                                                )}

                                                {(animal.isSterilise === true && userIsAdmin) && (
                                                    <div className="pastille_situation pastille_situation-sterilized pastille_situation-profile">
                                                        <span className="pastille_role-editor-text">{t('sterilized')}</span>
                                                    </div>
                                                )}

                                                {(!animal.isSterilise && userIsAdmin) && (
                                                    <div className="pastille_situation pastille_situation-sterilized-not pastille_situation-profile">
                                                        <span className="pastille_role-editor-text">{t('isNotSterilized')}</span>
                                                    </div>
                                                )}

                                                {(animal.isTrapped && userIsAdmin) && (
                                                    <div className="pastille_situation pastille_situation-trapped pastille_situation-profile">
                                                        <span className="pastille_role-editor-text">Fut capturé</span>
                                                    </div>
                                                )}

                                                <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-status-cat2" />


                                                <Tooltip id="my-tooltip-status-cat2" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem' }}>
                                                    <div style={{ backgroundColor: '#122' }}>
                                                        <h3>{t('alias.statuses.title')}</h3>
                                                        <p>{t('alias.statuses.text1')}</p>
                                                        <ul>
                                                            <li>{t('alias.statuses.list1')}</li>
                                                            <li>{t('alias.statuses.list2')}</li>
                                                            <li>{t('alias.statuses.list3')}</li>
                                                            <li>{t('alias.statuses.list4')}</li>
                                                            <li>{t('alias.statuses.list5')}</li>
                                                            <li>{t('alias.statuses.list6')}</li>
                                                        </ul>
                                                    </div>
                                                </Tooltip>

                                            </div>
                                        </div>
                                        <div className="animal-age">
                                            {animal.age ? `${t('age')} : ${calculateAge(animal.age)} ` : 'Date de naissance non fournie'}
                                            <>&nbsp; <EditableField
                                                label={'Modifier la date de naissance '}
                                                value={animal.age}
                                                onSave={(value) => handleUpdateField('age', value)}
                                                type="date"
                                                isAdmin={userIsAdmin}
                                                isEditMode={isEditMode}
                                            /></>
                                        </div>

                                        {(motherCatId && motherAnimal?.name && userIsAdmin) ? (
                                            <>
                                                <p>{t('mothersName')} : <NavLink to={`/animal/${motherAnimal.name}/${motherAnimal.id}`} style={{ textDecoration: 'underline' }}>{motherAnimal.name}</NavLink></p>

                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        {(animal?.associationLink?.associationName && userIsAdmin) ? (
                                            <div className="animal-association">
                                                {!isEditMode && (<p><span style={{ fontWeight: 'bold' }}>Association : </span>{animal?.associationLink?.associationName}</p>)}
                                                {isEditMode && (<div className="button-update-association-visible" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', columnGap: ' 1rem', flexWrap: 'wrap', marginTop: '1rem' }}><span style={{ fontWeight: 'bold' }}><p>Association : </p></span>
                                                    <AssociationSelect
                                                        associations={associationsValidate}
                                                        idPrefix="modal"
                                                        animals={motherAnimals}
                                                        currentAssociation={animal?.associationLink?.associationName}
                                                        selectedAssociationCat={associationId ? associationId : null}
                                                        onAssociationChange={handleObjectFormAnimalAssociationChange}
                                                        needsLabel={false}
                                                        isEditMode={isEditMode}
                                                        currentSituationAnimal={animal?.associationName}
                                                        placeholder={associationId ? associationId : "Choisir une association"}
                                                        animalId={id}

                                                    /></div>)}
                                            </div>
                                        ) : (userIsAdmin) ? (
                                            <div className='animal-association'>
                                                {!isEditMode && (<p><span style={{ fontWeight: 'bold' }}>Association : </span>Non lié à une association.</p>)}
                                                {isEditMode && (<div className="button-update-association-visible" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', columnGap: ' 1rem' }}><span style={{ fontWeight: 'bold' }}><p>Association : </p></span>
                                                    <AssociationSelect
                                                        associations={associationsValidate}
                                                        idPrefix="modal"
                                                        animals={motherAnimals}
                                                        currentAssociation={animal?.associationName}
                                                        selectedAssociationCat={associationId}
                                                        onAssociationChange={handleObjectFormAnimalAssociationChange}
                                                        needsLabel={false}
                                                        isEditMode={isEditMode}
                                                        currentSituationAnimal={animal?.associationName}
                                                        animalId={id}
                                                    /></div>)}
                                            </div>
                                        ) : (<></>)}
                                    </div>
                                </div>
                                <div className="profile-cat-first-row_column-2">
                                    {((userIsAdmin || userIsCreator) && canalLicenseStatus === 'active') && (<div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem', flexWrap: 'wrap' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', flexWrap: 'wrap' }}>
                                            <label htmlFor="editing">{t('editMode')} </label>
                                            <label className='switch'>
                                                <input type="checkbox"
                                                    checked={isEditMode} onChange={(e) => setIsEditMode(e.target.checked)} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>


                                        <button className="button-general button-general-ia" onClick={handleOpenIA}>Editer avec l'IA</button>

                                        <button className="button-supp" onClick={handleDelete}>{t('delete')}</button>
                                    </div>
                                    )}
                                </div>
                            </div>

                            <div className="section-informations">
                                <div className="tabs">
                                    <label
                                        htmlFor="tab-general-infos"
                                        onClick={() => { handleTabClick('general-infos') }}
                                        className={activeTab === 'general-infos' ? 'active' : ''}
                                    >
                                        <span className="tab-text-label">{t('generalInformations')}</span>
                                        <img className="tab-icon-label" src={informationcircleoutlinewhite} style={{ width: '20px', marginLeft: '10px' }} />
                                        <img className="tab-icon-label active" src={informationcircleoutline} style={{ width: '20px', marginLeft: '10px' }} />
                                    </label>

                                    {(userIsAdmin || userIsCreator) && (
                                        <label
                                            htmlFor="tab-identification"
                                            onClick={() => { handleTabClick('identification') }}
                                            className={activeTab === 'identification' ? 'active' : ''}
                                        >
                                            <span className="tab-text-label">{t('identification')}</span>
                                            <img className="tab-icon-label" src={idcardoutlinewhite} style={{ width: '20px', marginLeft: '10px' }} />
                                            <img className="tab-icon-label active" src={idcardoutline} style={{ width: '20px', marginLeft: '10px' }} />
                                        </label>
                                    )}

                                    {(userIsAdmin || userIsCreator) && (
                                        <label
                                            htmlFor="tab-relations"
                                            onClick={() => { handleTabClick('relations') }}
                                            className={activeTab === 'relations' ? 'active' : ''}
                                        >
                                            <span className="tab-text-label">{t('relationShip')}</span>
                                            <img className="tab-icon-label" src={pricetagoutlinewhite} style={{ width: '20px', marginLeft: '10px' }} />
                                            <img className="tab-icon-label active" src={pricetagoutline} style={{ width: '20px', marginLeft: '10px' }} />
                                        </label>
                                    )}


                                    <label
                                        htmlFor="tab-autre"
                                        onClick={() => { handleTabClick('autre') }}
                                        className={activeTab === 'autre' ? 'active' : ''}
                                    >
                                        <span className="tab-text-label">{t('other')}</span>
                                        <img className="tab-icon-label" src={ellipsishorizontaloutlinewhite} style={{ width: '20px', marginLeft: '10px' }} />
                                        <img className="tab-icon-label active" src={ellipsishorizontaloutline} style={{ width: '20px', marginLeft: '10px' }} />
                                    </label>


                                    {(userIsAdmin || userIsCreator) && (<label
                                        htmlFor="tab-documents"
                                        onClick={() => { handleTabClick('documents') }}
                                        className={activeTab === 'documents' ? 'active' : ''}
                                    >
                                        <span className="tab-text-label">{t('documents')}</span>
                                        <img className="tab-icon-label" src={documentattachoutlinewhite} style={{ width: '20px', marginLeft: '10px' }} />
                                        <img className="tab-icon-label active" src={documentattachoutline} style={{ width: '20px', marginLeft: '10px' }} />

                                    </label>)}

                                    {((isAuthenticated) && (userIsAdmin)) && (<label
                                        htmlFor="tab-commentaires"
                                        onClick={() => setActiveTab('commentaires')}
                                        className={activeTab === 'commentaires' ? 'active' : ''}
                                    >
                                        <span className="tab-text-label">{t('comments')}</span>
                                        <img className="tab-icon-label" src={chatbubbleswhite} style={{ width: '20px', marginLeft: '10px' }} />
                                        <img className="tab-icon-label active" src={chatbubbles} style={{ width: '20px', marginLeft: '10px' }} />

                                    </label>)}

                                    {isAuthenticated && userIsAdmin && (<label
                                        htmlFor="tab-privatecommentaires"
                                        onClick={() => setActiveTab('privatecommentaires')}
                                        className={activeTab === 'privatecommentaires' ? 'active' : ''}
                                    >
                                        <span className="tab-text-label">{t('privateComments')}</span>
                                        <img className="tab-icon-label" src={chatbubbleslockedwhite} style={{ width: '20px', marginLeft: '10px' }} />
                                        <img className="tab-icon-label active" src={chatbubbleslockedorange} style={{ width: '20px', marginLeft: '10px' }} />

                                    </label>)}




                                    {isAuthenticated && userIsAdmin && (<label
                                        htmlFor="tab-geomap"
                                        onClick={() => setActiveTab('geomap')}
                                        className={activeTab === 'geomap' ? 'active' : ''}
                                    >
                                        <span className="tab-text-label">Localisation</span>
                                        <img className="tab-icon-label" src={pins} style={{ width: '20px', marginLeft: '10px' }} />
                                        <img className="tab-icon-label active" src={pinsOrange} style={{ width: '20px', marginLeft: '10px' }} />

                                    </label>)}



                                    {/* Contenu des onglets */}


                                    <div className={`tabs-content ${activeTab === 'general-infos' ? 'active' : 'not'}`}>
                                        <div className='content-animaux' style={{ padding: '1rem' }}>



                                            {activeTab === 'general-infos' && (
                                                <>
                                                    <div className='content-bloc'>

                                                        <div className="column-bloc">
                                                            <div className="span-div-bloc">
                                                                <span className='span-div-label-bloc-informations'>{t('channel')}</span>
                                                                <p style={{ color: 'black' }}>{animal.canalName}</p>
                                                            </div>

                                                            {(userIsAdmin || sectorsEnabled) &&
                                                                (
                                                                    <div className="span-div-bloc">
                                                                        <span className='span-div-label-bloc-informations'>{t('sector')}</span>
                                                                        {citiesSector.length > 0 ? (<EditableField
                                                                            label="Modifier le secteur "
                                                                            value={animal?.cityId}
                                                                            onSave={(value) => {
                                                                                const selectedOption = citiesSector.find((city) => city.id === value);
                                                                                const name = selectedOption ? selectedOption.name : "";
                                                                                handleUpdateCityOrSectorField('citySectorName', name, 'citySectorId', value, 'city');
                                                                            }}
                                                                            type="select"
                                                                            options={citiesSector}
                                                                            optionsKey="id"
                                                                            displayValue={animal.citySectorName ? animal.citySectorName : `${t('undefined')}`}
                                                                            isAdmin={userIsAdmin}
                                                                            isEditMode={isEditMode}
                                                                        />) : (<p style={{ color: '#000' }}>{t('undefined')}</p>)}

                                                                    </div>
                                                                )}

                                                            {(userIsAdmin || sectorsEnabled) &&
                                                                (
                                                                    <div className="span-div-bloc">
                                                                        <span className='span-div-label-bloc-informations'>{t('subSector')}</span>
                                                                        {subSectors.length > 0 ? (<EditableField
                                                                            label="Modifier le sous-secteur "
                                                                            value={animal?.subSectorId}
                                                                            onSave={(value) => {
                                                                                const selectedOption = subSectors.find((subSector) => subSector.id === value);
                                                                                const name = selectedOption ? selectedOption.name : "";
                                                                                handleUpdateCityOrSectorField('subSectorName', name, 'subSectorId', value);
                                                                            }}
                                                                            type="select"
                                                                            options={subSectors}
                                                                            optionsKey="id"
                                                                            displayValue={animal.subSectorName ? animal.subSectorName : `${t('undefined')}`}
                                                                            isAdmin={userIsAdmin}
                                                                            isEditMode={isEditMode}
                                                                        />) : (<p style={{ color: '#000' }}>{t('undefined')}</p>)}

                                                                    </div>
                                                                )}


                                                            <div className="span-div-bloc">
                                                                {userIsAdmin && (
                                                                    <>
                                                                        <span className='span-div-label-bloc-informations'>{t('Capture')}</span>
                                                                        <EditableField
                                                                            label="A été capturé  "
                                                                            value={animal.isTrapped}
                                                                            onSave={(value) => handleUpdateField('isTrapped', value)}
                                                                            type="checkbox"
                                                                            isAdmin={userIsAdmin}
                                                                            isEditMode={isEditMode}

                                                                        />
                                                                    </>
                                                                )}

                                                            </div>

                                                            <div className="span-div-bloc">
                                                                {userIsAdmin && (
                                                                    <>
                                                                        <span className='span-div-label-bloc-informations'>{t('catchesIn')}</span>
                                                                        <EditableField
                                                                            label="Modifier la date de capture "
                                                                            value={animal.trappedDate ? animal.trappedDate : `${t('undefined')}`}
                                                                            onSave={(value) => handleUpdateField('trappedDate', value)}
                                                                            type="date"
                                                                            isAdmin={userIsAdmin}
                                                                            isEditMode={isEditMode}
                                                                        />
                                                                    </>
                                                                )}

                                                            </div>

                                                            <div className="span-div-bloc" style={{ height: '38px' }}>
                                                                {(userIsAdmin || userIsCreator) && (
                                                                    <>
                                                                        <span className='span-div-label-bloc-informations'>{t('situation')}</span>
                                                                        <SituationSelect
                                                                            idPrefix="modal"
                                                                            animals={userIsAdmin ? motherAnimals : (userIsCreator && !userIsAdmin) ? motherAnimalsVisitor : null}
                                                                            currentSituation={animal?.situationCat}
                                                                            selectedSituationCat={situationId}
                                                                            onSituationChange={handleObjectFormAnimalSituationChange}
                                                                            needsLabel={false}
                                                                            isEditMode={isEditMode}
                                                                            currentSituationAnimal={animal?.situationCat}
                                                                        />
                                                                    </>
                                                                )}


                                                            </div>
                                                        </div>


                                                        <div className="column-bloc">

                                                            <div className="span-div-bloc span-div-bloc-colors">
                                                                <div className="label-colors">
                                                                    <span className='span-div-label-bloc-informations'>Couleurs & motifs</span>


                                                                </div>
                                                                <div className="colors" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    {(selectedColors?.length > 0 || selectedPatterns?.length > 0) ? (
                                                                        <div style={{ display: 'flex', flexDirection: 'row', columnGap: '5px' }}>
                                                                            {selectedColors.map((color, index) => (
                                                                                <span key={color} style={{ color: 'black', fontSize: '18px', marginTop: '18px', marginBottom: '18px' }}>
                                                                                    {color}{index < selectedColors.length - 1 ? ', ' : ''}
                                                                                </span>
                                                                            ))}
                                                                            {selectedPatterns?.map((pattern, index) => (
                                                                                <span key={pattern} style={{ color: 'black', fontSize: '18px', marginTop: '18px', marginBottom: '18px' }}>
                                                                                    {pattern}{index < selectedPatterns.length - 1 ? ', ' : ''}
                                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                    ) : (
                                                                        <p style={{ color: 'black' }}>{t('undefined')}</p>
                                                                    )}
                                                                    {(userIsAdmin || userIsCreator) && (


                                                                        <button className={`button-update ${isEditMode ? 'visible' : 'hidden'}`} onClick={handleShowColorPopup}>
                                                                            <img style={{ width: "14px", margin: "2px", color: '#FFF', height: 'max-content' }} src={iconupdate} alt="Icon Update" className="button-icon" />
                                                                        </button>
                                                                    )}
                                                                </div>

                                                            </div>


                                                            {showIAPopup && (
                                                                <div className={`popup-container ${animationClass}`}>
                                                                    <div className="popup">
                                                                        <div className="popup-logo">
                                                                            <img src={logocolor} />
                                                                        </div>
                                                                        <h2 className='editable-fields-label'>Mettre à jour les infos</h2>
                                                                        <p style={{ color: '#000', fontWeight: 'bolder' }}>Veuillez sélectionner le formulaire de traçabilité professionnel.</p>
                                                                        <ImageAnalyzer onAnalysisComplete={handleAnalysisComplete} blackFont={true} />
                                                                        {showPreview && tempData && (
                                                                            <div className={`preview-container ${(showPreview && tempData) ? 'visible' : 'hidden'}`}>
                                                                                <div className="preview-section" style={{ background: "#eee" }}>
                                                                                    <h3 style={{ color: "#000", fontWeight: 'bolder', textAlign: "center", marginBottom: '2rem' }}>Données actuelles</h3>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0 }}> <p style={{ textTransform: 'uppercase', fontWeight: 'bolder', color: '#000' }}>Nom : {animal?.name ? animal.name : 'Non renseigné'}</p></div>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0 }}><p style={{ fontWeight: 'bolder', color: '#000' }}>Date de naissance : {animal?.age}</p></div>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0 }}><p style={{ fontWeight: 'bolder', color: '#000' }}>Poils : {animal?.hairs.join(', ') || 'Non renseigné'}</p></div>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0 }}><p style={{ fontWeight: 'bolder', color: '#000' }}>Race : {animal?.races.join(', ') || 'Non renseigné'}</p></div>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0 }}> <p style={{ fontWeight: 'bolder', color: '#000' }}>Robe : {animal?.colors.join(', ') || 'Non renseigné'}</p></div>

                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0 }}> <p style={{ fontWeight: 'bolder', color: '#000' }}>Numero d'identification : {animal.idNumber ? animal.idNumer : 'Non renseigné'}</p></div>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0 }}> <p style={{ fontWeight: 'bolder', color: '#000' }}>Date d'identification : {animal.dateIdentification ? animal.dateIdentification : 'Non renseigné'}</p></div>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0 }}> <p style={{ fontWeight: 'bolder', color: '#000' }}>Est stérilisé : {animal.isSterilise ? 'Oui' : 'Non / Non renseigné'}</p></div>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0 }}> <p style={{ fontWeight: 'bolder', color: '#000' }}>Sexe : {animal?.sex ? animal.sex : 'Non renseigné'}</p></div>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0 }}> <p style={{ fontWeight: 'bolder', color: '#000' }}>Est identifié : {animal.hasIdNumber ? 'Oui' : 'Non / Non renseigné'}</p></div>
                                                                                </div>
                                                                                <div className="preview-section" style={{ backgroundColor: 'rgb(53 81 81)' }}>
                                                                                    <h3 style={{ color: '#ddd', textAlign: 'center', marginBottom: "2rem" }}>Données détectées</h3>
                                                                                    {/* Ici, affichez les nouvelles données extraites */}
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0, justifyContent: 'space-between' }}><p style={{ textTransform: 'uppercase', color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>Nom : {tempData?.name ? tempData.name : 'Non renseigné'}</p>


                                                                                        <label className='switch'>
                                                                                            <input type="checkbox"
                                                                                                checked={updateFlags.name}
                                                                                                onChange={() => setUpdateFlags({ ...updateFlags, name: !updateFlags.name })}
                                                                                                disabled={tempData?.name === 'Non trouvé'} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>


                                                                                    </div>

                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0, justifyContent: 'space-between' }}><p style={{ textTransform: 'initial', color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>Date de naissance : {tempData?.age ? tempData.age : 'Non renseigné'}</p>


                                                                                        <label className='switch'>
                                                                                            <input type="checkbox"
                                                                                                checked={updateFlags.age}
                                                                                                onChange={() => setUpdateFlags({ ...updateFlags, age: !updateFlags.age })}
                                                                                                disabled={tempData?.age === 'Non trouvé'} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>


                                                                                    </div>

                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0, justifyContent: 'space-between' }}><p style={{ textTransform: 'initial', color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>Poils : {tempData?.hairs[0] !== 'non trouvé' ? tempData?.hairs?.join(', ') : 'Non renseigné'}</p>


                                                                                        <label className='switch'>
                                                                                            <input type="checkbox"
                                                                                                checked={updateFlags.hairs}
                                                                                                onChange={() => setUpdateFlags({ ...updateFlags, hairs: !updateFlags.hairs })}
                                                                                                disabled={tempData?.hairs?.[0] === 'non trouvé'} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>


                                                                                    </div>

                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0, justifyContent: 'space-between' }}><p style={{ textTransform: 'initial', color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>Race : {tempData?.races[0] !== 'non trouvé' ? tempData?.races?.join(', ') : 'Non renseigné'}</p>


                                                                                        <label className='switch'>
                                                                                            <input type="checkbox"
                                                                                                checked={updateFlags.races}
                                                                                                onChange={() => setUpdateFlags({ ...updateFlags, races: !updateFlags.races })}
                                                                                                disabled={tempData?.races?.[0] === 'non trouvé'} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>


                                                                                    </div>


                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0, justifyContent: 'space-between' }}><p style={{ textTransform: 'initial', color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>Robe : {tempData?.colors[0] !== 'non trouvé' ? tempData?.colors?.join(', ') : 'Non renseigné'}</p>


                                                                                        <label className='switch'>
                                                                                            <input type="checkbox"
                                                                                                checked={updateFlags.colors}
                                                                                                onChange={() => setUpdateFlags({ ...updateFlags, colors: !updateFlags.colors })}
                                                                                                disabled={tempData?.colors?.[0] === 'non trouvé'} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>


                                                                                    </div>

                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0, justifyContent: 'space-between' }}><p style={{ textTransform: 'initial', color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>Numero d'identification : {tempData?.idNumber ? tempData.idNumber : 'Non renseigné'}</p>


                                                                                        <label className='switch'>
                                                                                            <input type="checkbox"
                                                                                                checked={updateFlags.idNumber}
                                                                                                onChange={() => setUpdateFlags({ ...updateFlags, idNumber: !updateFlags.idNumber })}
                                                                                                disabled={tempData?.idNumber === 'Non trouvé'} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>


                                                                                    </div>

                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0, justifyContent: 'space-between' }}><p style={{ textTransform: 'initial', color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>Date d'identification : {tempData?.dateIdentification ? tempData.dateIdentification : 'Non renseigné'}</p>


                                                                                        <label className='switch'>
                                                                                            <input type="checkbox"
                                                                                                checked={updateFlags.dateIdentification}
                                                                                                onChange={() => setUpdateFlags({ ...updateFlags, dateIdentification: !updateFlags.dateIdentification })}
                                                                                                disabled={tempData?.dateIdentification === 'Non trouvé'} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>


                                                                                    </div>

                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0, justifyContent: 'space-between' }}><p style={{ textTransform: 'initial', color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>Est stérilisé : {tempData?.isSterilise ? 'Oui' : 'Non / Non renseigné'}</p>


                                                                                        <label className='switch'>
                                                                                            <input type="checkbox"
                                                                                                checked={updateFlags.isSterilise}
                                                                                                onChange={() => setUpdateFlags({ ...updateFlags, isSterilise: !updateFlags.isSterilise })}
                                                                                                disabled={tempData?.isSterilise === 'Non trouvé'} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>


                                                                                    </div>

                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0, justifyContent: 'space-between' }}><p style={{ textTransform: 'initial', color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>Sexe : {tempData?.sex ? tempData.sex : 'Non renseigné'}</p>


                                                                                        <label className='switch'>
                                                                                            <input type="checkbox"
                                                                                                checked={updateFlags.sex}
                                                                                                onChange={() => setUpdateFlags({ ...updateFlags, sex: !updateFlags.sex })}
                                                                                                disabled={tempData?.sex === 'Non trouvé'} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>


                                                                                    </div>

                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', lineHeight: 0, justifyContent: 'space-between' }}><p style={{ textTransform: 'initial', color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>Est identifié : {tempData?.hasIdNumber ? 'Oui' : 'Non / Non renseigné'}</p>


                                                                                        <label className='switch'>
                                                                                            <input type="checkbox"
                                                                                                checked={updateFlags.hasIdNumber}
                                                                                                onChange={() => setUpdateFlags({ ...updateFlags, hasIdNumber: !updateFlags.hasIdNumber })}
                                                                                                disabled={tempData?.hasIdNumber === 'Non trouvé'} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div className="popup-content">
                                                                            <button className="btn-cancel" onClick={handleCloseIAPopup}>Annuler</button>
                                                                            <button className="btn-validate" onClick={confirmUpdate}>Valider</button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}



                                                            {showColorPopup && (
                                                                <div className={`popup-container ${animationClass}`}>
                                                                    <div className="popup">
                                                                        <div className="popup-logo">
                                                                            <img src={logocolor} />
                                                                        </div>
                                                                        <h2 className='editable-fields-label'>Modifier les couleurs et motifs</h2>
                                                                        <div className="" style={{ width: '100%' }}>
                                                                            <ColorSelect selectedColors={selectedColors} onChange={handleColorChange} />
                                                                            <h3 style={{ color: '#000', paddingTop: '1rem' }}>Motif</h3>
                                                                            <PatternSelect selectedPatterns={selectedPatterns} onChange={handlePatternChange} />
                                                                        </div>
                                                                        <div className="popup-content popup-content_resp">
                                                                            <button className="btn-cancel" onClick={handleCloseColorPopup}>Annuler</button>
                                                                            <button className="btn-validate" onClick={handleUpdateColors}>Valider</button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <div className="span-div-bloc span-div-bloc-colors">
                                                                <div className="label-colors">
                                                                    <span className='span-div-label-bloc-informations'>{t('hair')}</span>


                                                                </div>
                                                                <div className="colors" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    {selectedHairs.length > 0 ? (
                                                                        <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                                                                            {selectedHairs.map((hair) => (
                                                                                <p style={{ color: 'black' }} key={hair}>{hair} </p>
                                                                            ))}
                                                                        </div>
                                                                    ) : (<><p style={{ color: 'black' }}>{t('undefined')}</p></>)}
                                                                    {(userIsAdmin || userIsCreator) && (
                                                                        <button className={`button-update ${isEditMode ? 'visible' : 'hidden'}`} onClick={handleShowHairPopup}>
                                                                            <img style={{ width: "14px", margin: "2px", color: '#FFF', height: 'max-content' }} src={iconupdate} alt="Icon Update" className="button-icon" />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>


                                                            {showHairsPopup && (
                                                                <div className={`popup-container ${animationClass}`}>
                                                                    <div className="popup">
                                                                        <div className="popup-logo">
                                                                            <img src={logocolor} />
                                                                        </div>
                                                                        <h2 className='editable-fields-label'>Modifier les poils</h2>
                                                                        <HairSelect selectedHairs={selectedHairs} onChange={handleHairsChange} />
                                                                        <div className="popup-content">
                                                                            <button className="btn-cancel" onClick={handleCloseHairPopup}>Annuler</button>
                                                                            <button className="btn-validate" onClick={handleUpdateHairs}>Valider</button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}



                                                            <div className="span-div-bloc span-div-bloc-colors">
                                                                <div className="label-colors">
                                                                    <span className='span-div-label-bloc-informations'>{t('breed')}</span>


                                                                </div>
                                                                <div className="colors" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    {selectedRaces.length > 0 ? (
                                                                        <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                                                                            {selectedRaces.map((race) => (
                                                                                <p style={{ color: 'black' }} key={race}>{race} </p>
                                                                            ))}
                                                                        </div>
                                                                    ) : (<><p style={{ color: 'black' }}>{t('undefined')}</p></>)}
                                                                    {(userIsAdmin || userIsCreator) && (
                                                                        <button className={`button-update ${isEditMode ? 'visible' : 'hidden'}`} onClick={handleShowRacePopup}>
                                                                            <img style={{ width: "14px", margin: "2px", color: '#FFF', height: 'max-content' }} src={iconupdate} alt="Icon Update" className="button-icon" />
                                                                        </button>
                                                                    )}

                                                                </div>



                                                                {showRacesPopup && (
                                                                    <div className={`popup-container ${animationClass}`}>
                                                                        <div className="popup">
                                                                            <div className="popup-logo">
                                                                                <img src={logocolor} />
                                                                            </div>
                                                                            <h2 className='editable-fields-label'>Modifier la race</h2>
                                                                            <RaceSelect selectedRaces={selectedRaces} onChange={handleRacesChange} />
                                                                            <div className="popup-content">
                                                                                <button className="btn-cancel" onClick={handleCloseRacePopup}>Annuler</button>
                                                                                <button className="btn-validate" onClick={handleUpdateRaces}>Valider</button>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </div>
                                                        </div>

                                                        <div className="column-bloc">
                                                            <div className="span-div-bloc">
                                                                {(userIsAdmin || userIsCreator) && (

                                                                    <>
                                                                        <span className='span-div-label-bloc-informations'>{t('isSterilized')}</span>
                                                                        <EditableField
                                                                            label="Est sterilisé "
                                                                            value={animal.isSterilise}
                                                                            onSave={(value) => handleUpdateField('isSterilise', value)}
                                                                            type="checkbox"
                                                                            isAdmin={userIsAdmin}
                                                                            isEditMode={isEditMode}

                                                                        />

                                                                    </>
                                                                )}
                                                            </div>

                                                            <div className="span-div-bloc">
                                                                {(userIsAdmin || userIsCreator) && (
                                                                    <>
                                                                        <span className='span-div-label-bloc-informations'>{t('sterilizationDate')}</span>
                                                                        <EditableField
                                                                            label="Modifier la date de stérilisation "
                                                                            value={animal.sterilizationDate ? animal.sterilizationDate : `${t('undefined')}`}
                                                                            onSave={(value) => handleUpdateField('sterilizationDate', value)}
                                                                            type="date"
                                                                            isAdmin={userIsAdmin}
                                                                            isEditMode={isEditMode}
                                                                        />
                                                                    </>
                                                                )}

                                                            </div>






                                                            <div className="span-div-bloc">
                                                                <span className='span-div-label-bloc-informations'>{t('looksSick')}</span>
                                                                <EditableField
                                                                    label="Semble malade ou blessé"
                                                                    value={animal.isSick}
                                                                    onSave={(value) => handleUpdateField('isSick', value)}
                                                                    type="checkbox"
                                                                    isAdmin={userIsAdmin || userIsCreator}
                                                                    isEditMode={isEditMode}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {(userIsAdmin || userIsCreator) && (
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', flexWrap: 'wrap' }}>
                                                        <label htmlFor="editing" style={{ color: 'black' }}>Je recherche ce chat </label>
                                                        <label className='switch'>
                                                            <input type="checkbox"
                                                                checked={isCatMissing} onChange={(e) => handleUpdateField('isCatMissing', e.target.checked)} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>

                                                    )}
                                                </>
                                            )

                                            }

                                        </div></div>

                                    <div className={`tabs-content ${activeTab === 'identification' ? 'active' : 'not'}`}>
                                        <div className='content-animaux' style={{ padding: '1rem' }}>

                                            {activeTab === 'identification' && (

                                                <div className='content-bloc'>
                                                    <div className="column-bloc">
                                                        <div className="span-div-bloc">
                                                            <span className='span-div-label-bloc-informations'>{t('isIdentified')}</span>
                                                            <EditableField
                                                                label="Est identifié  "
                                                                value={animal.hasIdNumber}
                                                                onSave={(value) => handleUpdateField('hasIdNumber', value)}
                                                                type="checkbox"
                                                                isAdmin={userIsAdmin || userIsCreator}
                                                                isEditMode={isEditMode}
                                                            />
                                                        </div>

                                                        <div className="span-div-bloc">
                                                            <span className='span-div-label-bloc-informations'>{t('identificationNumber')}</span>
                                                            <EditableField
                                                                label="Numéro d'identification "
                                                                value={animal.idNumber ? animal.idNumber : `${t('undefined')}`}
                                                                onSave={(value) => handleUpdateField('idNumber', value)}
                                                                type="text"
                                                                isAdmin={userIsAdmin || userIsCreator}
                                                                isEditMode={isEditMode}
                                                            />
                                                        </div>
                                                        {userIsAdmin === true && (
                                                            <div className="span-div-bloc">
                                                                <span className='span-div-label-bloc-informations'>{t('dateOfIdentification')}</span>
                                                                <EditableField
                                                                    label="Modifier la date d'identification "
                                                                    value={animal.dateIdentification ? animal.dateIdentification : `${t('undefined')}`}
                                                                    onSave={(value) => handleUpdateField('dateIdentification', value)}
                                                                    type="date"
                                                                    isAdmin={userIsAdmin || userIsCreator}
                                                                    isEditMode={isEditMode}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="column-bloc">

                                                        <div className="span-div-bloc">
                                                            <span className='span-div-label-bloc-informations'>{t('belongsToAnOwner')}</span>
                                                            <EditableField
                                                                label="Appartient à un propriétaire "
                                                                value={animal.isBelonged}
                                                                onSave={(value) => handleUpdateField('isBelonged', value)}
                                                                type="checkbox"
                                                                isAdmin={userIsAdmin}
                                                                isEditMode={isEditMode}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="column-bloc btn-kappze-id">
                                                        <div className="span-div-bloc">
                                                            <div>
                                                                <span className='span-div-label-bloc-informations'>KappzeID</span>
                                                                <button style={{ color: 'black', backgroundColor: 'white', borderRadius: '1px' }} onClick={handleCopy}>
                                                                    {isCopied ? 'ID copié !' : id}
                                                                    <img src={copy} style={{ width: '20px', marginLeft: '10px' }} />
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )}

                                        </div></div>

                                    {(userIsAdmin || userIsCreator) && (<>
                                        <div className={`tabs-content ${activeTab === 'relations' ? 'active' : 'not'}`}>
                                            <div className='content-animaux' style={{ padding: '1rem' }}>
                                                {activeTab === 'relations' && (
                                                    <div className='content-bloc'>

                                                        <div className="column-bloc">
                                                            <div className="span-div-bloc" style={{ height: '38px' }}>
                                                                <span className='span-div-label-bloc-informations'>{t('isRelatedToAFamily')}</span>
                                                                <EditableField
                                                                    label="Est lié à une famille  "
                                                                    value={animal.isFamily}
                                                                    onSave={(value) => handleUpdateField('isFamily', value)}
                                                                    type="checkbox"
                                                                    isAdmin={userIsAdmin}
                                                                    isEditMode={isEditMode}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="column-bloc">
                                                            <div className="span-div-bloc" style={{ height: '38px' }}>
                                                                <span className='span-div-label-bloc-informations'>{t('isAMother')}</span>
                                                                <EditableField
                                                                    label="Est une mère "
                                                                    value={animal.isMother}
                                                                    onSave={(value) => handleUpdateField('isMother', value)}
                                                                    type="checkbox"
                                                                    isAdmin={userIsAdmin}
                                                                    isEditMode={isEditMode}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="column-bloc">
                                                            <div className="span-div-bloc" style={{ height: '38px' }}>
                                                                <span className='span-div-label-bloc-informations'>{t('nameOfTheMother')}</span>
                                                                <MotherSelect
                                                                    idPrefix="modal"
                                                                    animals={userIsAdmin ? motherAnimals : (userIsCreator && !userIsAdmin) ? motherAnimalsVisitor : null}
                                                                    selectedAnimalId={motherAnimal?.id}
                                                                    currentAnimal={motherAnimal?.name ? motherAnimal.name : `${t('undefined')}`}
                                                                    onAnimalChange={handleObjectFormAnimalChange}
                                                                    needsLabel={false}
                                                                    isEditMode={isEditMode}
                                                                    currentEditedAnimal={animal?.id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div></div>

                                    </>)}

                                    <div className={`tabs-content ${activeTab === 'autre' ? 'active' : 'not'}`}>
                                        <div className='content-animaux' style={{ padding: '1rem' }}>
                                            {activeTab === 'autre' && (
                                                <div className='content-bloc'>

                                                    <div className="column-bloc">
                                                        <div className="span-div-bloc">
                                                            <span className='span-div-label-bloc-informations'>{t('diseases')}</span>
                                                            <EditableField
                                                                label="Modifier les maladies "
                                                                value={animal.diseases ? animal.diseases : `${t('undefined')}`}
                                                                onSave={(value) => handleUpdateField('diseases', value)}
                                                                isAdmin={userIsAdmin || userIsCreator}
                                                                isEditMode={isEditMode}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="column-bloc">
                                                        <div className="span-div-bloc">
                                                            <span className='span-div-label-bloc-informations'>{t('specialFeatures')}</span>
                                                            <EditableField
                                                                label="Modifier les particularités "
                                                                value={animal.particularities ? animal.particularities : `${t('undefined')}`}
                                                                onSave={(value) => handleUpdateField('particularities', value)}
                                                                isAdmin={userIsAdmin || userIsCreator}
                                                                isEditMode={isEditMode}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="column-bloc">
                                                        <div className="span-div-bloc">
                                                            <span className='span-div-label-bloc-informations'>{t('death')}</span>
                                                            <EditableField
                                                                label="L'animal est décédé "
                                                                value={animal.death}
                                                                type="checkbox"
                                                                onSave={(value) => handleUpdateField('death', value)}
                                                                isAdmin={userIsAdmin || userIsCreator}
                                                                isEditMode={isEditMode}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div></div>

                                    <div className={`tabs-content ${activeTab === 'documents' ? 'active' : 'not'}`}>
                                        <div className='content-animaux' style={{ padding: '1rem' }}>

                                            {activeTab === 'documents' && (
                                                <>
                                                    {animal.documents?.length > 0 ? (
                                                        <>
                                                            <p style={{ color: '#000', fontWeight: 'bolder' }}>{t('clickOnTheCorrespondingDocumentToDownloadIt')}</p>
                                                            <div style={{ border: '1px solid #ddd', borderRadius: '5px', marginTop: '1rem' }}>

                                                                {(animal.documents.map((document, index) => (
                                                                    <div className="document-container" key={index} style={{ borderBottom: '1px solid #ddd', padding: '5px 0' }} >
                                                                        <a style={{ color: 'black' }} href={document.url} target="_blank" rel="noopener noreferrer">
                                                                            {document.name}
                                                                        </a>
                                                                        {(userIsAdmin || userIsCreator) && (
                                                                            <> <button className='btn-supp' onClick={() => handleDeleteDocument(document.name)}>x</button>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                )))}
                                                                {
                                                                    (userIsAdmin || userIsCreator) && (<>
                                                                        <div style={{ display: 'flex', flexDirection: 'column', columnGap: '1rem', rowGap: '1rem', flexWrap: 'wrap', padding: '1rem' }}>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem', flexWrap: 'wrap', rowGap: '1rem' }}>
                                                                                <label style={{ color: 'black' }} htmlFor="documents">{t('addDocuments')} : </label>
                                                                                <input type="file" id="documents" multiple onChange={(e) => setDocuments([...e.target.files])} className="profile-animal-documents auth-input" />
                                                                            </div>

                                                                            <div className="">
                                                                                <button className='btn-add' style={{ margin: '0', textTransform: 'capitalize' }} onClick={handleUploadFiles}>{t('send')}</button>

                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {documents.length && <h4 style={{ color: 'black' }}>{t('selectedFiles')} :</h4>}
                                                                            {documents.map((file, index) => (
                                                                                <p key={index} style={{ color: 'black' }}>{file.name}</p>
                                                                            ))}
                                                                        </div> </>)
                                                                }
                                                            </div>


                                                        </>) : (<div>
                                                            <p style={{ color: '#000' }}>{t('noDocument')}</p>
                                                            {
                                                                (userIsAdmin || userIsCreator) && (<>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', columnGap: '1rem', rowGap: '1rem', flexWrap: 'wrap', padding: '1rem' }}>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
                                                                            <label style={{ color: 'black' }} htmlFor="documents">{t('addDocuments')} : </label>
                                                                            <input className="profile-animal-documents auth-input" type="file" id="documents" multiple onChange={(e) => setDocuments([...e.target.files])} />
                                                                        </div>

                                                                        <div className="">
                                                                            <button className='btn-add' style={{ margin: '0', textTransform: 'capitalize' }} onClick={handleUploadFiles}>{t('send')}</button>

                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {documents.length && <h4 style={{ color: 'black' }}>{t('selectedFiles')} :</h4>}
                                                                        {documents.map((file, index) => (
                                                                            <p key={index} style={{ color: 'black' }}>{file.name}</p>
                                                                        ))}
                                                                    </div> </>)
                                                            }
                                                        </div>


                                                    )}

                                                </>

                                            )}

                                        </div></div>
                                        {(userIsAdmin || userIsCreator) && (
                                    <div className={`tabs-content ${activeTab === 'commentaires' ? 'active' : 'not'}`} style={{ backgroundColor: '#122' }}>
                                        <div className='content-animaux' style={{ padding: '1rem' }}>
                                            {activeTab === 'commentaires' && (
                                                <>
                                                    <CommentSection animalId={animal.id} animalName={animal?.name} licenceStatus={licenceStatus?.status} userIsAdmin={userIsAdmin} canalId={canal?.id} canalName={canal?.name} />
                                                </>
                                            )}
                                        </div></div>

                    )}

                                    <div className={`tabs-content ${activeTab === 'privatecommentaires' ? 'active' : 'not'}`} style={{ backgroundColor: '#122' }}>
                                        <div className='content-animaux' style={{ padding: '1rem' }}>

                                            {(userIsAdmin & activeTab === 'privatecommentaires') && (
                                                <>
                                                    <PrivateCommentSection animalId={animal.id} animalName={animal?.name} canalId={canal?.id} canalName={canal?.name} />
                                                </>
                                            )}

                                        </div>
                                    </div>

                                    <div className={`tabs-content ${activeTab === 'geomap' ? 'active' : 'not'}`} style={{ backgroundColor: '#122' }}>
                                        <div className='content-animaux' style={{ padding: '1rem' }}>

                                            {/* {(userIsAdmin & activeTab === 'geomap') && (
                                                <>

                                                    {(marker.lat && marker.lng) ? (<SingleMarkerMap communeCode={canal?.codeCommune} marker={marker} animalImage={animal?.image?.url} />
                                                    ) : (<></>)}



                                                </>
                                            )} */}

                                            {userIsAdmin && activeTab === 'geomap' && (
                                                <>
                                                    {marker?.lat !== undefined && marker?.lng !== undefined ? (
                                                        <SingleMarkerMap
                                                            communeCode={canal?.codeCommune}
                                                            marker={marker}
                                                            animalImage={animal?.image?.url}
                                                            isEditMode={isEditMode}
                                                            onUpdateMarker={handleUpdateMarker}
                                                            onDeleteMarker={handleDeleteMarker}


                                                        />
                                                    ) : (
                                                        <><p style={{ color: 'white' }}>Aucun marqueur n'est défini. Vous pouvez le modifier en activant le mode édition.</p>
                                                            {isEditMode && (
                                                                <div>

                                                                    <SingleMarkerMap
                                                                        communeCode={canal?.codeCommune}
                                                                        marker={null}
                                                                        animalImage={animal?.image?.url}
                                                                        isEditMode={isEditMode}
                                                                        onUpdateMarker={handleUpdateMarker}
                                                                        onDeleteMarker={handleDeleteMarker}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>)}
                                                </>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {(filteredAnimals.length > 0 && isAuthenticated) && (userIsAdmin) ? (
                                <>
                                    <h2>{t('fromTheSameFamily')}</h2>
                                    <AnimalFilters animals={filteredAnimals} />
                                    <h2>{t('genealogy')}</h2>
                                    <p>{t('youCanScroll')}.</p>
                                    <Genealogy currentAnimalId={animal.id} animals={animals} />


                                </>
                            ) : (filteredAnimalsVisitor.length > 0 && isAuthenticated) && (userIsCreator) ? (
                                <>
                                    <h2>{t('fromTheSameFamily')}</h2>
                                    <AnimalFilters animals={filteredAnimals} />
                                    <h2>{t('genealogy')}</h2>
                                    <p>{t('youCanScroll')}.</p>
                                    <Genealogy currentAnimalId={animal.id} animals={visitorAnimals} />


                                </>
                            )
                                : (<></>)
                            }
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AnimalDetails;