import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, query, where, getDocs, addDoc, doc, deleteDoc, getDoc, updateDoc, deleteField, Timestamp } from 'firebase/firestore';

// Fetch animals linked to an association
export const fetchAnimalsByAssociation = createAsyncThunk(
    'associations/fetchAnimalsByAssociation',
    async ({ linkedAssociationId, isLinkedToAnAssociation }) => {
        const db = getFirestore();
        const q = query(
            collection(db, 'animals'),
            where('associationLink.linkedAssociationId', '==', linkedAssociationId),
            where('associationLink.isLinkedToAnAssociation', '==', isLinkedToAnAssociation)
        );
        const querySnapshot = await getDocs(q);
        const animals = [];
        querySnapshot.forEach(doc => {
            animals.push({ id: doc.id, ...doc.data() });
        });
        return animals;
    }
);


export const fetchInformationsFromAssociation = createAsyncThunk(
    'associations/fetchInformationsFromAssociation',
    async ({ linkedAssociationId }) => {
        const db = getFirestore();
        const q = query(
            collection(db, 'siretOrganization'),
            where('siret', '==', linkedAssociationId),
        );
        const querySnapshot = await getDocs(q);
        const associations = [];
        querySnapshot.forEach(doc => {
            associations.push({ id: doc.id, ...doc.data() });
        });
        return associations;
    }
);






export const fetchAssociationsDetails = createAsyncThunk(
    'associations/fetchAssociationsDetails',
    async (siretNumbers, { rejectWithValue }) => {

        try {
            const db = getFirestore();
            const promises = siretNumbers.map(siret => {
                const ref = query(collection(db, 'siretOrganization'), where('siret', '==', siret));
                return getDocs(ref);
            });
            const docsArray = await Promise.all(promises);
            let associationsDetails = [];
            docsArray.forEach(querySnapshot => {
                querySnapshot.forEach(doc => {
                    associationsDetails.push({ id: doc.id, ...doc.data() });
                });
            });
            return associationsDetails;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const fetchSingleAssociationDetails = createAsyncThunk(
    'associations/fetchSingleAssociationDetails',
    async (siretNumber, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            // Construire la requête Firestore pour trouver l'association par son numéro SIRET
            const queryRef = query(collection(db, 'siretOrganization'), where('siret', '==', siretNumber));
            const querySnapshot = await getDocs(queryRef);
            let associationDetails = [];
            querySnapshot.forEach(doc => {
                associationDetails.push({ id: doc.id, ...doc.data() });
            });

            // Retourner les détails de l'association ou gérer le cas où aucune association n'est trouvée
            if (associationDetails.length > 0) {
                return associationDetails[0]; // Retourner le premier document trouvé
            } else {
                return rejectWithValue('No association found with the provided SIRET number');
            }
        } catch (error) {
            console.error('Error fetching association details:', error);
            return rejectWithValue(error.message); // Gérer l'erreur
        }
    }
);







/* Check canals for a given Association ID */
export const fetchCanalsAssociation = createAsyncThunk('associations/fetchCanalsAssociation', async (userId, { dispatch, rejectWithValue }) => {
    const db = getFirestore();
    const userCanalsRef = collection(db, 'userCanals');
    const userCanalsQuery = query(userCanalsRef, where('userId', '==', userId));
    const userCanalsSnapshot = await getDocs(userCanalsQuery);
    const canalIds = userCanalsSnapshot.docs.map((doc) => doc.data().canalId);
    const canalsData = [];

    for (const canalId of canalIds) {
        const canalRef = doc(db, 'canals', canalId);
        const canalSnapshot = await getDoc(canalRef);

        if (canalSnapshot.exists()) {
            const canalData = canalSnapshot.data();
            let isAdmin = false;

            // Check if the user is an admin
            if (Array.isArray(canalData.role)) {
                for (let role of canalData.role) {
                    if (role.uid === userId && role.isAdmin) {
                        isAdmin = true;
                        break;
                    }
                }
            }


            // console.log(licenceStatus)

            canalsData.push({ id: canalId, isAdmin, ...canalData });

            // console.log(canalsData)
        }
    }


    return canalsData;
});



// export const fetchAllAssociations = createAsyncThunk(
//     'associations/fetchAllAssociations',
//     async (_, { rejectWithValue }) => {
//         const db = getFirestore();
//         const q = query(collection(db, 'siretOrganization'));
//         try {
//             const querySnapshot = await getDocs(q);
//             const associations = [];
//             querySnapshot.forEach(doc => {
//                 associations.push({ id: doc.id, siret: doc.data().siret, name: doc.data().name });
//             });
//             return associations;
//         } catch (error) {
//             return rejectWithValue(error.message);
//         }
//     }
// );


// Push animal to an association
export const pushAnimalToAssociation = createAsyncThunk(
    'associations/pushAnimalToAssociation',
    async ({ animalId, linkedAssociationId, isLinkedToAnAssociation }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const animalRef = doc(db, 'animals', animalId);
            await updateDoc(animalRef, {
                linkedAssociationId,
                isLinkedToAnAssociation
            });
            const updatedAnimalSnapshot = await getDoc(animalRef);
            return { id: updatedAnimalSnapshot.id, ...updatedAnimalSnapshot.data() };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Delete animal from an association
export const deleteAnimalFromAssociation = createAsyncThunk(
    'associations/deleteAnimalFromAssociation',
    async ({ animalId }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const animalRef = doc(db, 'animals', animalId);
            await updateDoc(animalRef, {
                linkedAssociationId: null,
                isLinkedToAnAssociation: false
            });
            const updatedAnimalSnapshot = await getDoc(animalRef);
            return { id: updatedAnimalSnapshot.id, ...updatedAnimalSnapshot.data() };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);






const associationsSlice = createSlice({
    name: 'associations',
    initialState: {
        status: 'idle',
        animals: [],
        associations: [],
        canals: [],
        associationsDetails: [],
        singleAssociationDetails: [],
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInformationsFromAssociation.pending, state => {
                state.status = 'loading';
                state.associations = []; // Réinitialise les animaux avant de charger les nouveaux
            })
            .addCase(fetchInformationsFromAssociation.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.associations = action.payload;
            })
            .addCase(fetchInformationsFromAssociation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAnimalsByAssociation.pending, state => {
                state.status = 'loading';
                state.animals = []; // Réinitialise les animaux avant de charger les nouveaux

            })
            .addCase(fetchAnimalsByAssociation.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.animals = action.payload;
            })
            .addCase(fetchAnimalsByAssociation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(pushAnimalToAssociation.fulfilled, (state, action) => {
                state.animals.push(action.payload);
            })
            .addCase(deleteAnimalFromAssociation.fulfilled, (state, action) => {
                state.animals = state.animals.filter(animal => animal.id !== action.payload.id);
            })
            .addCase(fetchCanalsAssociation.pending, (state) => {
                state.status = 'loading';
                state.canals = [];
            })
            .addCase(fetchCanalsAssociation.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.canals = action.payload;
            })
            .addCase(fetchCanalsAssociation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAssociationsDetails.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchAssociationsDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.associationsDetails = action.payload;
            })
            .addCase(fetchAssociationsDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchSingleAssociationDetails.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchSingleAssociationDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.singleAssociationDetails = action.payload;
            })
            .addCase(fetchSingleAssociationDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    },
});

export default associationsSlice.reducer;
