import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchCitiesSector } from '../../features/citiesSector/citySectorSlice';
import { fetchAnimalsByCanal, fetchLostCatsByPostalCode } from '../../features/animals/animalSlice';
import { fetchCanalUsers } from '../../features/canals/canalUsersSlice';
import { updateAdminNotificationPreference } from '../../features/user/userSlice';
import { fetchAssociationsDetails } from '../../features/association/associationSlice';
import informationcircleoutline from '../../assets/information-circle-outline.svg';
import informationcircleoutlinewhite from '../../assets/information-circle-outline-white.svg';
import { Tooltip } from 'react-tooltip';
import BannerPlaceHolder from '../../assets/placeholder-banner-kappze.png'
import CatNotFound from '../../assets/cat-not-found.png';
import AddMyCat from '../../assets/trace.svg';
import ImageIcon from '../../assets/image-outline.svg'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import AddCityModal from '../../components/citiesSector/AddCityModal';
import AnimalFilters from '../../components/animals/AnimalFilters';
import iconpaw from '../../assets/icon-paw.svg';
import iconpawblack from '../../assets/icon-paw-black.svg'
import iconsignal from '../../assets/megaphone-outline.svg';
import iconsectorwhite from '../../assets/trail-sign-outline.svg';
import iconsector from '../../assets/trail-sign-outline-black.svg';
import iconadd from '../../assets/icon-add.svg';
import iconMissingCat from '../../assets/icons/cat-solid.svg';
import iconsettings from '../../assets/icon-settings.svg';
import statsIcon from '../../assets/bar-chart-outline.svg'
import BackButton from '../../components/general/BackButton';
import LoadingPage from '../../components/general/LoadingPage';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { fetchLicenceStatusByCanalId } from '../../features/canals/canalSlice';
import MultipleMarkersMap from '../Maps/multipleMapMarkers';
import pins from '../../assets/logos/location-outline.svg';
import pinsOrange from '../../assets/logos/location-outline-orange.svg';



Modal.setAppElement('#root')

const CanalDetails = () => {
    const { t } = useTranslation();
    const { uid,notificationsEnabledAdmin } = useSelector((state) => state.auth);
    const { id } = useParams();
    const { data: canals } = useSelector((state) => state.canals);
    const canal = canals.find((asso) => asso.id === id);
    const enabledSectors = canal?.visibilitySectorsEnabled;


    // const licenceStatus = canal?.licenceMaire ? canal.licenceMaire : canal?.licenceAssociation ? canal.licenceAssociation : null;
    const licenceStatus = useSelector((state) => state.licences?.licence?.status);

    // console.log('licenceStatus --> ', licenceStatus)
    const notificationsPreferences = useSelector((state) => state.auth?.notificationsAdminPreferences);
    // Initialiser l'état local pour la préférence de notification
    const [isEnabledAdmin, setIsEnabled] = useState(
        notificationsPreferences && notificationsPreferences[canal.id] ? notificationsPreferences[canal.id] : false
    );

    const { isAuthenticated } = useSelector((state) => state.auth);
    const { data: citiesSector, status, error } = useSelector((state) => state.citiesSector);
    const { data: animals } = useSelector((state) => state.animals)
    const publishedAnimals = animals.filter((animal) => animal.published === true);
    const lostAnimals = animals.filter((animal) => animal.isCatMissing === true);
    const visitorAnimals = animals.filter((animal) => animal.userCreatorId === uid && animal.published === true);
    const unpublishedAnimals = animals.filter((animal) => animal.published === false);
    const { data: users } = useSelector((state) => state.canalUsers);
    const femaleAnimals = animals.filter((animal) => animal.sex === 'Femelle');
    const femaleAnimalsSterilized = femaleAnimals.filter((animal) => animal.isSterilise === true);

    const animalsStatus = useSelector((state) => state.animals.status);
    const canalsStatus = useSelector((state) => state.canals.status);
    const [editableFields, setEditableFields] = useState([]);
    const [userIsAdmin, setUserRole] = useState({});
    const [canalAdmins, setCanalAdmins] = useState([])
    const [chatsPerdus, setChatsPerdus] = useState([]);
    const [open, setOpen] = useState(false);
    const numSterilizedCats = animals.filter(animal => animal.isSterilise).length;
    const numNotIdentifiedCats = animals.filter(animal => !animal.hasIdNumber).length;
    const [activeTab, setActiveTab] = useState('animaux');
    const sterilizationPercentage = Math.round((numSterilizedCats / animals.length) * 100);
    const sterilizationFemalePercentage = Math.round((femaleAnimalsSterilized.length / femaleAnimals.length) * 100);
    const archiveType = 'canal';
    const backgroundUrl = canal?.imageCover?.url
    const dispatch = useDispatch();
    const associationsValidate = useSelector((state) => state.associations?.associationsDetails);

    const associationsInCanal = useMemo(() => {
        return users.filter(user => user.isAssociation);
    }, [users]); // Assurez-vous que canalId est bien la dépendance correcte ici


    const toggleSwitch = () => {
        setIsEnabled(previousState => !previousState);
        // Ici, appelez également une fonction pour mettre à jour la base de données avec la nouvelle valeur.
        dispatch(updateAdminNotificationPreference({ newValue: !isEnabledAdmin, userId: uid, canalId: canal.id }));
    };

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCitiesSector(id));
            dispatch(fetchAnimalsByCanal(id))
            dispatch(fetchCanalUsers(id));
            dispatch(fetchLicenceStatusByCanalId(id))
        }
    }, [canal, dispatch, id, isAuthenticated, notificationsEnabledAdmin]);

    useEffect(() => {
        if (canal && canal?.id === id) {
            const fields = [
                { key: 'name', label: 'Nom', value: canal?.name },
                { key: 'email', label: 'Email', value: canal?.email },
                { key: 'city', label: 'Secteur', value: canal?.city },
                { key: 'postalCode', label: 'Code Postal', value: canal?.postalCode },
            ];
            setEditableFields(fields);
        } else {
            // Animal not available or ID mismatch, reset editableFields
            setEditableFields([]);
        }
    }, [canal, dispatch, animals])

    useEffect(() => {
        if (canal && canal?.id === id) {
            fetchLicenceStatusByCanalId(canal?.id)
        }
    }, [canal, dispatch]);

    useEffect(() => {
        fetchLostCatsByPostalCode(canal?.postalCode, '').then(response => {
            // Supposons que response contient un objet avec une propriété 'results' qui est un tableau
            const results = response.data.results;
            if (!results) {
                setChatsPerdus([])
                return;
            }
            const lostCats = results.map(cat => {
                // Accéder à l'image large du premier objet 'imgs'
                const imageUrl = cat.imgs && cat.imgs.length > 0 ? cat.imgs[0].large : require('../../assets/logos/placeholder-chatperdu.png');
                return {
                    ...cat,
                    imageUrl
                };
            });
            setChatsPerdus(lostCats);
        });
    }, []);

    // useEffect(() => {
    //     // Trouver l'utilisateur actuel par uid
    //     const currentUser = users.find(user => user.id === uid);
    //     // Assurez-vous que chaqueUsers est correctement défini si nécessaire
    //     const eachUsers = users.filter(user => user.isAdmin === true);

    //     if (currentUser) {
    //         // Vérifier si associationsValidate contient un tableau et extraire les IDs des associations validées
    //         console.log('CURRENT USER ->', currentUser)
    //         const validAssociationIds = associationsValidate
    //             ? associationsValidate.flatMap(assoc => assoc.siret || [])
    //             : [];

    //         // Définir le rôle de l'utilisateur actuel
    //         const isUserRoleValid = (
    //             (currentUser?.isAdmin && !currentUser?.isAssociation && currentUser?.linkedAssociationIds?.some(id => validAssociationIds.includes(id))) ||
    //             (currentUser?.isAssociation && currentUser?.linkedAssociationIds?.some(id => validAssociationIds.includes(id))) ||
    //             (!currentUser?.isAssociation && currentUser?.isLinkedToAnAssociation && currentUser?.linkedAssociationIds?.some(id => validAssociationIds.includes(id)))
    //         );

    //         setUserRole(isUserRoleValid ? true : null);
    //     } else {
    //         setUserRole(null);
    //     }


    //     console.log('admin users : ', eachUsers)
    //     console.log('ID' , id)
    //     // Définir les administrateurs du canal
    //     setCanalAdmins(eachUsers);
    // }, [id]);

    useEffect(() => {
        if (canal) {
            const currentUser = users.find(user => user.id === uid);
            const validAssociationIds = associationsValidate
                ? associationsValidate.flatMap(assoc => assoc.siret || [])
                : [];

            const isUserRoleValid = (
                (currentUser?.isAdmin) ||
                (currentUser?.isAdmin && currentUser?.isAssociation && currentUser?.linkedAssociationIds?.some(id => validAssociationIds.includes(id))) ||
                (!currentUser?.isAssociation && currentUser?.isLinkedToAnAssociation && currentUser?.linkedAssociationIds?.some(id => validAssociationIds.includes(id)))
            );

            setUserRole(isUserRoleValid);
            setCanalAdmins(users.filter(user => user.isAdmin));
        }
    }, [canal, uid, users, associationsValidate]);

    useEffect(() => {
        if (notificationsPreferences && uid && notificationsPreferences[uid] && canal && notificationsPreferences[uid][canal.id] !== undefined) {
            setIsEnabled(notificationsPreferences[uid][canal.id]);
        }
    }, [uid, canal, notificationsPreferences]);

    useEffect(() => {
        if (associationsInCanal.length > 0) {
            const sirets = associationsInCanal.map(assoc => assoc.siretAsso).filter(siret => siret);
            // console.log("sirets : ", sirets)

            if (sirets.length > 0) {
                dispatch(fetchAssociationsDetails(sirets));
            }
        }
    }, [dispatch, associationsInCanal]); // Utilisation sûre car associationsInCanal est stabilisé par useMemo

    if (animalsStatus === 'loading' || canalsStatus === 'loading') {
        return <LoadingPage isLoading={true} />;
    }


    return (
        <div className=' canal-page filtered-page'>
            <LoadingPage isLoading={false} />
            <BackButton needsBackground={true} />

            {(userIsAdmin && licenceStatus !== 'active') && (
                <p style={{ textAlign: 'center', fontWeight: '500' }}>Attention, le canal est en lecture seule. La licence doit être renouvelée.</p>
            )}
            <div className="canal-page_main page-main">
                <div className="canal-page_content">
                    <div className="canal-page_content-title" style={{ backgroundImage: `url(${backgroundUrl ? backgroundUrl : BannerPlaceHolder})` }}>
                        <div className="canal-page_content-title-img">
                            {canal?.image?.url.url ? (<LazyLoadImage effect="none" src={canal?.image?.url.url} alt={`Image de ${canal?.name}`} />) : canal?.image?.url ? (
                                <LazyLoadImage effect="none" src={canal?.image?.url} style={{ objectFit: 'contain' }} alt={`Image de ${canal?.name}`} />
                            ) : (<LazyLoadImage effect="none" src={CatNotFound} style={{ objectFit: 'contain' }} alt={`Image de ${canal?.name}`} />)}

                            <h1 className='page-title'>{canal?.name}</h1>
                        </div>
                        <Tooltip id="my-tooltip-share-canal" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', zIndex: '2' }}>
                            <div style={{ backgroundColor: '#122' }}>
                                <h3>{t('alias.shareChannel.title')}</h3>
                                <p>{t('alias.shareChannel.text1')}</p>
                                <ul>
                                    <li>{t('alias.shareChannel.list1')}</li>
                                    <li>{t('alias.shareChannel.list2')}</li>
                                </ul>
                            </div>
                        </Tooltip>
                        {backgroundUrl && (<div className="city-page_cover-show">
                            <button className={`button-update visible`} onClick={() => setOpen(true)} >
                                <img style={{ width: "25px", margin: "2px" }} src={ImageIcon} alt="Icon Update" className="button-icon" />
                            </button>
                        </div>)}

                    </div>
                    <div className='section-button-general section-informations_button-general'>
                        {(userIsAdmin === true) && (
                            <>
                                <AddCityModal needsReturn={false} canalId={id} licenceStatus={licenceStatus} />
                                <button className='button-general' disabled={!licenceStatus || licenceStatus === 'inactive'}>
                                    <NavLink to={`/create-animal/${id}`} className='button-general-link'>
                                        <span style={{ marginRight: '16px' }}>{t('addCat')}</span>
                                        <img style={{ width: '28px', marginLeft: '5px' }} src={iconpaw} alt="Icon Paw" className="button-icon" />
                                        <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />
                                    </NavLink>
                                </button>
                            </>
                        )}

                        {(!userIsAdmin && licenceStatus) ? (
                            <>
                                <button className='button-general' disabled={!licenceStatus || licenceStatus === 'inactive'}>
                                    <NavLink to={`/signal-animal/${id}`} className='button-general-link'>
                                        <span style={{ marginRight: '16px' }}>{t('reportCat')}</span>
                                        <img style={{ width: '22px', marginLeft: '5px' }} src={iconsignal} alt="Icon Paw" className="button-icon" />
                                        <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />
                                    </NavLink>
                                </button>

                                <button className='button-general' disabled={!licenceStatus || licenceStatus === 'inactive'}>
                                    <NavLink to={`/signal-animal/${id}`} state={true} className='button-general-link'>
                                        <span style={{ marginRight: '16px' }}>{t('reportMyCat')}</span>
                                        <img style={{ width: '22px', marginLeft: '0px' }} src={AddMyCat} alt="Icon Paw" className="button-icon" />
                                        <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />
                                    </NavLink>
                                </button>
                            </>
                        ) : userIsAdmin && unpublishedAnimals.length > 0 ? (<button className='button-general button-general_signalements' disabled={!licenceStatus || licenceStatus === 'inactive'}>
                            <NavLink to={`/canal/${id}/signalements`} className='button-general-link button-general-link_signalements'>
                                <span style={{ marginRight: '16px' }}>{t('reports')} ({unpublishedAnimals.length})</span>
                                <img style={{ width: '22px', marginLeft: '5px' }} src={iconsignal} alt="Icon Paw" className="button-icon" />
                                {/* <p></p> */}
                            </NavLink>
                        </button>) : (null)}

                        {(userIsAdmin === true) && (
                            <div className='buttons-stats-manage'>



                                <button className='button-general'>
                                    <NavLink to={`/statistics/${canal?.id}`} className='button-general-link'>
                                        <span style={{ marginRight: '16px' }}>{t('stats')}</span>
                                        <img style={{ width: '22px', marginLeft: '5px' }} src={statsIcon} alt="Icon Compass" className="button-icon" />                                    </NavLink>
                                </button>

                                <button className='button-general'>
                                    <NavLink to={`/canal/${id}/settings`} className='button-general-link'>
                                        <span style={{ marginRight: '16px' }}>{t('channelManagement')}</span>
                                        <img style={{ width: '22px', marginLeft: '5px' }} src={iconsettings} alt="Icon Compass" className="button-icon" />                                    </NavLink>
                                </button>



                            </div>
                        )}

                        {/* {userIsAdmin && (<ExportButton canalId={id} />)} */}
                        {userIsAdmin && (
                            <>
                                <label htmlFor="editing" style={{ color: '#fff' }}>{t('enableNotifications')}</label>
                                <label className='switch'>
                                    <input type="checkbox"
                                        checked={isEnabledAdmin} onChange={toggleSwitch} />
                                    <span className="slider round"></span>
                                </label>
                            </>
                        )}
                    </div>
                </div>

                <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    slides={[
                        { src: backgroundUrl },
                    ]}
                    plugins={[Zoom]}
                />

                {(canal?.adminMessage && canal?.adminMessageActive === true) &&
                    (
                        <div className="section-informations">
                            <div className="tabs">
                                <div className="tabs-content">
                                    <h2 style={{ color: '#d15e41' }}>Information</h2>
                                    <p style={{ color: '#000' }}>{canal?.adminMessage}</p>
                                </div>
                            </div>
                        </div>
                    )}

                <div className="section-informations">

                    <div className="tabs">

                        <label
                            htmlFor="tab-animaux"
                            onClick={() => setActiveTab('animaux')}
                            className={activeTab === 'animaux' ? 'active' : ''}
                        >
                            <span className="tab-text-label">{t('animals')}</span>
                            <img className="tab-icon-label" src={iconpaw} style={{ width: '20px', marginLeft: '10px' }} />
                            <img className="tab-icon-label active" src={iconpawblack} style={{ width: '20px', marginLeft: '10px' }} />
                        </label>

                        {(userIsAdmin || enabledSectors) && (
                            <label
                                htmlFor="tab-secteurs"
                                onClick={() => setActiveTab('secteurs')}
                                className={activeTab === 'secteurs' ? 'active' : ''}
                            >
                                <span className="tab-text-label">{t('sectors')}</span>
                                <img className="tab-icon-label" src={iconsectorwhite} style={{ width: '20px', marginLeft: '10px' }} />
                                <img className="tab-icon-label active" src={iconsector} style={{ width: '20px', marginLeft: '10px' }} />
                            </label>
                        )}

                        {(userIsAdmin) && (
                            <label
                                htmlFor="tab-catmap"
                                onClick={() => setActiveTab('catmap')}
                                className={activeTab === 'catmap' ? 'active' : ''}
                            >

                                <span className="tab-text-label">Carte</span>
                                <img className="tab-icon-label" src={pins} style={{ width: '20px', marginLeft: '10px' }} />
                                <img className="tab-icon-label active" src={pinsOrange} style={{ width: '20px', marginLeft: '10px' }} />


                            </label>
                        )}

                        <label
                            htmlFor="tab-catsmissing"
                            onClick={() => setActiveTab('catsmissing')}
                            className={activeTab === 'catsmissing' ? 'active' : ''}
                        >

                            <span className="tab-text-label">Chats perdus de la commune</span>
                            <img className="tab-icon-label" src={iconMissingCat} style={{ width: '20px', marginLeft: '10px' }} />
                            <img className="tab-icon-label active" src={iconMissingCat} style={{ width: '20px', marginLeft: '10px' }} />


                        </label>

                        <label
                            htmlFor="tab-informations"
                            onClick={() => setActiveTab('informations')}
                            className={activeTab === 'informations' ? 'active' : ''}
                        >
                            <span className="tab-text-label">{t('generalInformations')}</span>
                            <img className="tab-icon-label" src={informationcircleoutlinewhite} style={{ width: '20px', marginLeft: '10px' }} />
                            <img className="tab-icon-label active" src={informationcircleoutline} style={{ width: '20px', marginLeft: '10px' }} />
                        </label>


                        {/* Contenu des onglets */}
                        <div className={`tabs-content ${activeTab === 'animaux' ? 'active' : 'not'}`}>
                            <div className='content-animaux' style={{ padding: '1rem' }}>
                                {activeTab === 'animaux' && (
                                    <div id="content-animaux">
                                        {animals.length > 0 ? (
                                            <>
                                                <div className="stat-animal">
                                                    <div className="data-animal">
                                                        <p>{animals.length}</p>
                                                    </div>
                                                    <div className="label-animal">
                                                        <p>Chat(s)</p>
                                                        <p className='label-animal-more'>{t('ofWhich')} {femaleAnimals.length} {t('femalesListed')}</p>
                                                    </div>
                                                </div>

                                                {numNotIdentifiedCats > 0 ? (
                                                    <div className="stat-animal">
                                                        <div className="data-animal">
                                                            <p>{numNotIdentifiedCats}</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('unidentifiedCats')}</p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="stat-animal">
                                                        <div className="data-animal" style={{ backgroundColor: '#2f4f4f' }}>
                                                            <p style={{ color: '#fff' }}>OK</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('everyCatsAreIdentified')}</p>
                                                        </div>
                                                    </div>
                                                )}

                                                {sterilizationFemalePercentage > 0 && (
                                                    sterilizationFemalePercentage > 75 ? (
                                                        <div className="stat-animal">
                                                            <div className="data-animal" style={{ backgroundColor: '#2f4f4f' }}>
                                                                <p style={{ color: '#fff' }}>{sterilizationFemalePercentage}%</p>
                                                            </div>
                                                            <div className="label-animal">
                                                                <p>{t('rateOfSterilizedFemales')}</p>
                                                                <p className='label-animal-more'>{t('ieATotalOf')} {femaleAnimalsSterilized.length} {t('sterilizedFemales')} / {femaleAnimals.length} {t('listed')}</p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="stat-animal">
                                                            <div className="data-animal">
                                                                <p>{sterilizationFemalePercentage}%</p>
                                                            </div>
                                                            <div className="label-animal">
                                                                <p>{t('rateOfSterilizedFemales')}</p>
                                                                <p className='label-animal-more'>{t('ieATotalOf')} {femaleAnimalsSterilized.length} {t('sterilizedFemales')} / {femaleAnimals.length} {t('listed')}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                )}

                                                {sterilizationPercentage > 75 ? (
                                                    <div className="stat-animal">
                                                        <div className="data-animal" style={{ backgroundColor: '#2f4f4f' }}>
                                                            <p style={{ color: '#fff' }}>{sterilizationPercentage}%</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('overallSterilizationRate')}</p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="stat-animal">
                                                        <div className="data-animal" style={{ borderColor: '#872929' }}>
                                                            <p>{sterilizationPercentage}%</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('overallSterilizationRate')}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <p style={{ color: '#000' }}>{t('youDontHaveAnyAnimalsYet')}</p>
                                        )}
                                    </div>
                                )}

                            </div></div>

                        <div className={`tabs-content ${activeTab === 'secteurs' ? 'active' : 'not'}`}>
                            <div className='content-animaux' style={{ padding: '1rem' }}>
                                {activeTab === 'secteurs' && (
                                    <div>
                                        <p style={{ color: 'black' }}>{t('clickOnTheSectorToAccessItsPersonalizedData')}</p>
                                        <div id="content-secteurs">

                                            {status === 'loading' ? (
                                                <p>Loading...</p>
                                            ) : status === 'failed' ? (
                                                <p>Error: {error}</p>
                                            ) : (

                                                citiesSector.length > 0 ? (
                                                    citiesSector.map((city, index) => (
                                                        <div key={index} className="stat-animal">
                                                            <div className="data-animal-sector">
                                                                <p key={index} style={{ fontWeight: 'bold' }}>

                                                                    <NavLink to={{ pathname: `/canal/${id}/${city.id}` }} state={city.name} key={index}>{city.name}</NavLink>
                                                                </p>
                                                            </div>
                                                        </div>

                                                    ))
                                                ) : (<p style={{ color: '#000', textAlign: 'center' }}> {t('youDontHaveSectorsYet')}</p>)

                                            )}
                                        </div>
                                    </div>
                                )}

                            </div></div>


                        <div className={`tabs-content ${activeTab === 'catmap' ? 'active' : 'not'}`} style={{ backgroundColor: "#122" }}>
                            <div className='content-animaux' style={{ padding: '1rem' }}>
                                {activeTab === 'catmap' && (
                                    <div style={{ backgroundColor: "#122" }}>
                                        <MultipleMarkersMap communeCode={canal?.codeCommune} animals={animals} />
                                    </div>

                                )}

                            </div></div>
                        <div className={`tabs-content ${activeTab === 'informations' ? 'active' : 'not'}`}>
                            <div className='content-animaux' style={{ padding: '1rem' }}>
                                {activeTab === 'informations' && (
                                    <div id="content-informations">
                                        <div className="content-informations-canal">
                                            <h3>{t('generalInformations')}</h3>
                                            <p><span style={{ fontWeight: "500" }}> {canal?.name}</span></p>
                                            <p><span style={{ fontWeight: "500" }}>{canal?.email}</span></p>
                                            <p><span style={{ fontWeight: "500" }}>{canal?.city}</span></p>
                                            <p><span style={{ fontWeight: "500" }}>{canal?.postalCode}</span></p>
                                        </div>

                                        <div className="content-informations-members">
                                            <h3>{t('administrators')}</h3>
                                            {users.filter(user => user.isAdmin).map((user, index) => (

                                                <div key={index}>
                                                    {user.isAdmin ? (
                                                        <p style={{ color: 'black' }} key={index}> <span style={{ fontWeight: 'bold' }}>{user.surname} {user.name}</span>,  {user.email}, {user.phoneNumber}</p>
                                                    ) : (
                                                        <p style={{ color: 'black' }} key={index}> <span style={{ fontWeight: "bolder", color: 'darkslategray' }}>{t('notAnyMembers')}</span></p>
                                                    )}

                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                )}

                            </div></div>
                        <div className={`tabs-content ${activeTab === 'catsmissing' ? 'active' : 'not'}`} style={{ background: '#2f4f4f' }}>
                            <div className='content-animaux' style={{ padding: '1rem', background: '#2f4f4f' }}>
                                {activeTab === 'catsmissing' && (
                                    <div id="content-chatperdu" style={{ background: '#2f4f4f' }}>
                                        <div className="content-chatperdu_banner">
                                            <p style={{ color: '#fff' }}>Chats recherchés par ses propriétaires</p>
                                        </div>
                                        <AnimalFilters animals={lostAnimals} archiveType={archiveType} isAdmin={userIsAdmin} sectorsEnabled={enabledSectors} />
                                    </div>
                                )}
                            </div></div>
                    </div>
                </div>
            </div>
            {
                publishedAnimals.length ? (
                    userIsAdmin ? (
                        <div className='section-animalFilters'>
                            <AnimalFilters animals={publishedAnimals} archiveType={archiveType} isAdmin={userIsAdmin} sectorsEnabled={enabledSectors} />
                        </div>
                    ) : (
                        visitorAnimals.length && canal?.visibilityListEnabled ? (
                            <>
                                <div className='section-animalFilters'>
                                    <h3 style={{ color: '#fff', fontWeight: '400', textDecoration: 'underline', marginTop: '2rem' }}>Vos chats</h3>

                                    <AnimalFilters animals={visitorAnimals} archiveType={archiveType} isAdmin={userIsAdmin} sectorsEnabled={enabledSectors} />
                                </div>

                                <div className='section-animalFilters'>
                                    <h3 style={{ color: '#fff', fontWeight: '400', textDecoration: 'underline' }}>Chats de votre commune</h3>
                                    <AnimalFilters animals={publishedAnimals} archiveType={archiveType} isAdmin={userIsAdmin} sectorsEnabled={enabledSectors} />
                                </div>
                            </>
                        ) : !visitorAnimals.length && canal?.visibilityListEnabled ? (
                            <>
                                <div className='section-animalFilters'>
                                    <h3 style={{ color: '#fff', fontWeight: '400', textDecoration: 'underline', marginTop: '2rem' }}>Vos chats</h3>
                                    <p>Vos chats apparaîtront ici.</p>            </div>
                                <div className='section-animalFilters'>
                                    <h3 style={{ color: '#fff', fontWeight: '400', textDecoration: 'underline', marginTop: '2rem' }}>Chats de votre commune</h3>
                                    <AnimalFilters animals={publishedAnimals} archiveType={archiveType} isAdmin={userIsAdmin} sectorsEnabled={enabledSectors} />
                                </div>
                            </>
                        ) : (
                            <div className='no-animals' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <p>Vos chats apparaîtront ici.</p>
                                <img src={CatNotFound} style={{ maxWidth: '150px' }} />
                            </div>
                        )
                    )
                ) : (
                    <div className='no-animals' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <p>Il n'y a pas d'animaux à afficher.</p>
                        <img src={CatNotFound} style={{ maxWidth: '150px' }} />
                    </div>
                )


            }
        </div>
    );
};

export default CanalDetails;
