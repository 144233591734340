import { useState } from 'react';
import { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'animate.css';
import iconpawblack from '../../assets/icon-paw-black.svg'
import trashoutline from '../../assets/trash-outline.svg';
import iconsectorwhite from '../../assets/trail-sign-outline.svg';
import iconsector from '../../assets/trail-sign-outline-black.svg';
import LoadingPage from '../../components/general/LoadingPage';
import logocolor from '../../assets/transparent-without-circle.png';
import iconupdate from '../../assets/icon-update.svg';
import sign from '../../assets/trail-sign-outline-black.svg'
import BannerPlaceHolder from '../../assets/placeholder-banner-kappze.png'
import CatNotFound from '../../assets/cat-not-found.png'
import ImageIcon from '../../assets/image-outline.svg'
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import AddSubSectorModal from '../../components/subSectors/AddSubSectorModal';
import { fetchAnimalsBySubSector, updateAnimalSubSectorName, removeSubSectorFromAnimals } from '../../features/animals/animalSlice';
import AnimalFilters from '../../components/animals/AnimalFilters';
import iconpaw from '../../assets/icon-paw.svg';
import iconadd from '../../assets/icon-add.svg';
import { fetchCitiesSector, deleteCitySector, updateCitySector, updateSectorCoverImage } from '../../features/citiesSector/citySectorSlice';
import { fetchSubSectors, deleteSubSector, updateSubSector, updateSubSectorCoverImage } from '../../features/citiesSector/subSectorSlice';
import BackButton from '../../components/general/BackButton';
import { useTranslation } from 'react-i18next';
import copy from '../../assets/copypaste.svg';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const SubSectorDetails = () => {

    const { t } = useTranslation();
    let location = useLocation();

    const citySectorName = location.state;

    const { linkedCanalId, linkedCitySectorId, linkedSubSectorId } = useParams();
    const { isAuthenticated, uid, status } = useSelector((state) => state.auth);
    const { data: canals } = useSelector((state) => state.canals);
    const { data: citiesSector, error } = useSelector((state) => state.citiesSector);
    const { data: subSectors } = useSelector((state) => state.subSectors);

    const { data: animals } = useSelector((state) => state.animals);
    const publishedAnimals = animals.filter((animal) => animal.published === true);
    const { data: users } = useSelector((state) => state.canalUsers);
    const [isCopied, setIsCopied] = useState(false);
    const canal = canals.find((canal) => canal.id === linkedCanalId);
    const canalLicenseStatus = canal?.licenceMaire;
    const subSector = subSectors.find((sector) => sector.id === linkedSubSectorId);
    const enabledSectors = canal?.visibilitySectorsEnabled;
    // Find the city object from the citiesSector list
    const city = citiesSector.find((city) => city.id === linkedCitySectorId);
    const backgroundUrl = subSector?.imageCover?.url ? subSector?.imageCover.url : subSector?.imageCover?.url?.url;
    const [isEditMode, setIsEditMode] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const archiveType = 'city';
    const [currentCitySectorName, setCurrentCitySectorName] = useState(citySectorName);
    const [userIsAdmin, setUserRole] = useState({});
    const [showEditSectorNamePopup, setShowEditSectorNamePopup] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const [sectorName, setSectorName] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImagePopup, setShowImagePopup] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [open, setOpen] = useState(false);
    const numSterilizedCats = publishedAnimals.filter(animal => animal.isSterilise).length;
    const numNotIdentifiedCats = publishedAnimals.filter(animal => animal.hasIdNumber !== true).length;
    const femaleAnimals = publishedAnimals.filter((animal) => animal.sex === 'Femelle');
    const femaleAnimalsSterilized = femaleAnimals.filter((animal) => animal.isSterilise === true);
    const sterilizationPercentage = Math.round((numSterilizedCats / publishedAnimals.length) * 100);
    const sterilizationFemalePercentage = Math.round((femaleAnimalsSterilized.length / femaleAnimals.length) * 100);
    const [activeTab, setActiveTab] = useState('animaux');
    const handleShowEditSubSectorNamePopup = () => {

        setAnimationClass('popup-entering');
        setShowEditSectorNamePopup(true);;
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };


    const handleCloseEditSectorNamePopup = () => {

        setAnimationClass('popup-exiting');
        setTimeout(() => {
            setShowEditSectorNamePopup(false);
        }, 450);
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleConfirmEditSectorName = async () => {

        if (sectorName && sectorName.trim() !== '') {
            const newCitySectorName = sectorName;
            await dispatch(updateSubSector({ subSectorId: subSector.id, name: newCitySectorName }));
            await dispatch(updateAnimalSubSectorName({ subSectorId: subSector.id, newCitySectorName }));
            setCurrentCitySectorName(newCitySectorName);
            handleCloseEditSectorNamePopup();

        }
    }



    /* handle image actions */

    const handleImageChange = (event) => {
        setSelectedFileName(event.target.files[0].name);
        setSelectedImage(event.target.files[0]);
    };

    const handleUpdateImage = () => {

        setShowImagePopup(false);
        setAnimationClass(null);
        dispatch(
            updateSubSectorCoverImage({ subSectorId: subSector.id, image: selectedImage })
        )


            .unwrap()
            .catch((error) => {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.errorUpdatingData')} ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })

            });
    };

    const handleShowImagePopup = () => {
        setAnimationClass('popup-entering');
        setShowImagePopup(true);
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(canal?.alias);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Réinitialiser après 2 secondes
        } catch (err) {
            console.error('Erreur de copie', err);
        }
    };

    const handleCloseImagePopup = () => {
        setAnimationClass('popup-exiting');

        setTimeout(() => {
            setShowImagePopup(false);
        }, 450);

        setTimeout(() => {
            setAnimationClass(null);
        }, 500);
    };

    const handleDeleteSubSector = async (cityId) => {


        Swal.fire({
            icon: 'warning',
            title: `${t('modal.deleteSectorTitle')}`,
            text: `${t('modal.deleteSectorTitle')}`,
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#2F4F4F',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            showClass: {
                popup: 'animate__animated animate__fadeIn'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOut'
            }
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteSubSector(cityId));
                dispatch(removeSubSectorFromAnimals(cityId));

                // Redirect to the previous page
                navigate(-1, { from: navigate.location });
            }
        })



    };


    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchAnimalsBySubSector(linkedSubSectorId))
            dispatch(fetchCitiesSector(linkedCanalId));
            dispatch(fetchSubSectors(linkedCitySectorId));

        }
    }, [dispatch, linkedCitySectorId, isAuthenticated, linkedCanalId, linkedSubSectorId]);

    useEffect(() => {
        // Trouver l'utilisateur actuel par uid
        const currentUser = users.find(user => user.id === uid);

        // Définir le rôle de l'utilisateur actuel, ou 'null' si non trouvé
        setUserRole(currentUser ? currentUser.isAdmin : null);

    }, [dispatch, linkedCitySectorId, users, uid, isAuthenticated, city]); // Ajouter `users` et `uid` comme dépendances si elles peuvent changer


    if (status === 'loading') {
        return <LoadingPage isLoading={true} />;
    }


    return (
        <div className='city-page filtered-page'>
            <LoadingPage isLoading={false} />
            <BackButton needsBackground={true} />
            <div className="city-page_main page-main">

                <div className="city-page_content" style={{ backgroundImage: `url(${backgroundUrl ? backgroundUrl : BannerPlaceHolder})` }}>
                    <div className="city-page_content-title">
                        {city ? (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', columnGap: '1rem' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h3 style={{ color: 'white', fontWeight: '400' }}>{t('subSector')}</h3>
                                    <h2 className='page-title'>{currentCitySectorName} <br /></h2>
                                    <p>Appartient au secteur : {city.name}</p>
                                    <p><b>Description :</b> {subSector?.description}</p>
                                </div>

                                <div className="btns-modify-supp-city btns-modify-supp">
                                    {userIsAdmin && (
                                        <div className='btns-simple-edit' style={{ columnGap: 0 }}>
                                            <button className={`button-update ${isEditMode ? 'visible' : 'hidden'}`} onClick={() => handleShowEditSubSectorNamePopup(city)}>
                                                <img src={iconupdate} alt="Modifier" style={{ width: '20px' }} />
                                            </button>
                                            <button className={`button-update ${isEditMode ? 'visible' : 'hidden'}`} onClick={() => handleDeleteSubSector(city.id)}>
                                                <img src={trashoutline} alt="Supprimer" style={{ width: '20px' }} />
                                            </button>
                                        </div>

                                    )}
                                </div>
                            </div>




                        ) : (
                            <p>Sector not found</p>
                        )}
                    </div>

                    <div className='section-button-general section-informations_button-general'>
                        {(userIsAdmin && canalLicenseStatus === 'active')? (
                            <>
                                <button className='button-general'>
                                    <NavLink to={`/create-animal/${canal.id}`} className='button-general-link'>
                                        <span style={{ marginRight: '16px' }}>{t("addCat")}</span>
                                        <img style={{ width: '30px', marginLeft: '5px' }} src={iconpaw} alt="Icon Paw" className="button-icon" />
                                        <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />
                                    </NavLink>
                                </button>
                            </>
                        ) : (null)}
                    </div>

                    {(userIsAdmin && canalLicenseStatus === 'active') && (
                        <div className="city-page_cover">
                            <button className={`button-update ${isEditMode ? 'visible' : 'hidden'}`} onClick={handleShowImagePopup}>
                                <img style={{ width: "14px", margin: "2px" }} src={iconupdate} alt="Icon Update" className="button-icon" />
                            </button>
                        </div>
                    )}
                    {backgroundUrl && (<div className="city-page_cover-show">
                        <button className={`button-update visible`} onClick={() => setOpen(true)} >
                            <img style={{ width: "25px", margin: "2px" }} src={ImageIcon} alt="Icon Update" className="button-icon" />
                        </button>
                    </div>)}
                    {showImagePopup && (
                        <div className={`popup-container ${animationClass}`}>
                            <div className="popup">
                                <div className="popup-logo">
                                    <img src={logocolor} />
                                </div>
                                <h2 style={{ color: 'black', marginTop: '1rem' }}>Modifier l'image de couverture</h2>

                                <div className="popup-singleinput">
                                    {/* Ajoutez ici le contenu de la pop-up pour la modification de l'image */}

                                    <div className="chooseFile" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <label htmlFor="fileInput" className="custom-file-button">Choisir un fichier</label>

                                        <input type="file" className="hidden-input" id="fileInput" onChange={handleImageChange} />
                                        <span style={{ color: '#000' }}>{selectedFileName}</span>
                                    </div>


                                </div>
                                <div className="popup-btns">
                                    <button className="btn-cancel" onClick={handleCloseImagePopup}>Annuler</button>
                                    {selectedFileName && (
                                        <button className="btn-validate" onClick={handleUpdateImage}>Valider</button>
                                    )}

                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="city-page_main-editmode">
                    {(userIsAdmin && canalLicenseStatus === 'active')&& (<div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', flexWrap: 'wrap' }}>
                            <label htmlFor="editing">{t('editMode')} </label>
                            <label className='switch'>
                                <input type="checkbox"
                                    checked={isEditMode} onChange={(e) => setIsEditMode(e.target.checked)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    )}
                </div>

                <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    slides={[
                        { src: backgroundUrl },
                    ]}
                    plugins={[Zoom]}
                />

                <div className="section-informations">

                    <div className="tabs">

                        <label
                            htmlFor="tab-animaux"
                            onClick={() => setActiveTab('animaux')}
                            className={activeTab === 'animaux' ? 'active' : ''}
                        >
                            <span className="tab-text-label">{t('animals')}</span>
                            <img className="tab-icon-label" src={iconpaw} style={{ width: '20px', marginLeft: '10px' }} />
                            <img className="tab-icon-label active" src={iconpawblack} style={{ width: '20px', marginLeft: '10px' }} />
                        </label>

                        <div className={`tabs-content ${activeTab === 'animaux' ? 'active' : 'not'}`}>
                            <div className='content-animaux' style={{ padding: '1rem' }}>
                                {activeTab === 'animaux' && (
                                    <div id="content-animaux">
                                        {publishedAnimals.length > 0 ? (
                                            <>
                                                <div className="stat-animal">
                                                    <div className="data-animal">
                                                        <p>{publishedAnimals.length}</p>
                                                    </div>
                                                    <div className="label-animal">
                                                        <p>Chat(s)</p>
                                                        <p className='label-animal-more'>{t('ofWhich')} {femaleAnimals.length} {t('femalesListed')}</p>
                                                    </div>
                                                </div>

                                                {numNotIdentifiedCats > 0 ? (
                                                    <div className="stat-animal">
                                                        <div className="data-animal">
                                                            <p>{numNotIdentifiedCats}</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('unidentifiedCats')}</p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="stat-animal">
                                                        <div className="data-animal" style={{ backgroundColor: '#2f4f4f' }}>
                                                            <p style={{ color: '#fff' }}>OK</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('everyCatsAreIdentified')}</p>
                                                        </div>
                                                    </div>
                                                )}

                                                {sterilizationFemalePercentage > 0 && (
                                                    sterilizationFemalePercentage > 75 ? (
                                                        <div className="stat-animal">
                                                            <div className="data-animal" style={{ backgroundColor: '#2f4f4f' }}>
                                                                <p style={{ color: '#fff' }}>{sterilizationFemalePercentage}%</p>
                                                            </div>
                                                            <div className="label-animal">
                                                                <p>{t('rateOfSterilizedFemales')}</p>
                                                                <p className='label-animal-more'>{t('ieATotalOf')} {femaleAnimalsSterilized.length} {t('sterilizedFemales')} / {femaleAnimals.length} {t('listed')}</p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="stat-animal">
                                                            <div className="data-animal">
                                                                <p>{sterilizationFemalePercentage}%</p>
                                                            </div>
                                                            <div className="label-animal">
                                                                <p>{t('rateOfSterilizedFemales')}</p>
                                                                <p className='label-animal-more'>{t('ieATotalOf')} {femaleAnimalsSterilized.length} {t('sterilizedFemales')} / {femaleAnimals.length} {t('listed')}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                )}

                                                {sterilizationPercentage > 75 ? (
                                                    <div className="stat-animal">
                                                        <div className="data-animal" style={{ backgroundColor: '#2f4f4f' }}>
                                                            <p style={{ color: '#fff' }}>{sterilizationPercentage}%</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('overallSterilizationRate')}</p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="stat-animal">
                                                        <div className="data-animal" style={{ borderColor: '#872929' }}>
                                                            <p>{sterilizationPercentage}%</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('overallSterilizationRate')}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <p style={{ color: '#000' }}>{t('youDontHaveAnyAnimalsYet')}</p>
                                        )}
                                    </div>
                                )}

                            </div></div>
                    </div>
                </div>

            </div>
            {
                publishedAnimals.length ? (
                    ((userIsAdmin) || (!userIsAdmin && canal?.visibilityListEnabled === true)) ? (<div className='section-animalFilters'>
                        <AnimalFilters animals={publishedAnimals} archiveType={archiveType} isAdmin={userIsAdmin} />
                    </div>) : (<div className='no-animals' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><p> La consultation des animaux n'est pas disponible sur ce canal.</p>
                        <img src={CatNotFound} style={{ maxWidth: '150px' }} /></div>)




                ) : (<div className='no-animals' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><p> Il n'y a pas d'animaux à afficher.</p>
                    <img src={CatNotFound} style={{ maxWidth: '150px' }} /></div>)
            }


            {showEditSectorNamePopup && (
                <div className={`popup-container ${animationClass}`}>
                    <div className="popup">
                        <div className="popup-logo">
                            <img src={sign} />
                        </div>
                        <h2 className='editable-fields-label'>Modifier le nom du sous-secteur</h2>

                        {/* <div className="popup-content" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}> */}
                        <form id="form-create" className="popup-singleinput" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <input id="citySector-name"
                                value={sectorName}
                                onChange={(e) => setSectorName(e.target.value)} placeholder='Nouveau nom' />
                        </form>
                        <div className="popup-btns">
                            <button className="btn-cancel" onClick={handleCloseEditSectorNamePopup}>Annuler</button>
                            <button className="btn-validate" onClick={handleConfirmEditSectorName}>Valider</button>
                        </div>


                        {/* </div> */}
                    </div>
                </div>
            )}

        </div>
    );
};

export default SubSectorDetails;