import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ComparePostalCodeData } from '../../features/siret/siretSlice.js';


const CanalsListByCp = ({ handleConfirm, canals }) => {
  const { email } = useSelector((state) => state.auth);

  const [codePostal, setCodePostal] = useState('');
  const [canauxDisponibles, setCanauxDisponibles] = useState([]);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour le chargement
  const dispatch = useDispatch();


  useEffect(() => {
    if (email.endsWith('@one-voice.fr')  || email.endsWith('@kappze.com')) {
      setSuperAdmin(true);
    } else {
      setSuperAdmin(false);
    }
  }, [email]);


  const handleChangeCodePostal = (e) => {
    const newCodePostal = e.target.value;
    setCodePostal(newCodePostal);
    setIsLoading(true); // Commence le chargement
  
    const postalCodePattern = /^\d{5}$/; // Expression régulière pour vérifier 5 chiffres
  
    if (postalCodePattern.test(newCodePostal)) {
      dispatch(ComparePostalCodeData(newCodePostal))
        .then(response => {
          const canauxAccessibles = superAdmin ? response.payload : response.payload.filter(canal => canal.canalReachable);
          setCanauxDisponibles(canauxAccessibles);
          setIsLoading(false); // Arrête le chargement
        })
        .catch(error => {
          console.error("Error fetching canals:", error);
          setCanauxDisponibles([]); // Handle canals state in case of error
          setIsLoading(false); // Arrête le chargement en cas d'erreur
        });
    } else {
      setCanauxDisponibles([]); // Ensure a reset when the postal code is invalid
      setIsLoading(false); // Pas de chargement si le code postal est invalide
    }
  };
  

  // Check if the user is already a member of the canal
  const isUserAlreadyInCanal = (codeCommune, isCanalAssociation, cid) => {
    return canals.some(canal => canal.codeCommune === codeCommune && canal.isCanalAssociation === isCanalAssociation && canal.id === cid);
  };

  return (
    <div className="canalsListContainer">
    {superAdmin && (<p style={{color: 'black'}}>Vous êtes un super admin. </p>)}
      <input
        type="text"
        value={codePostal}
        onChange={handleChangeCodePostal}
        placeholder="Ecrivez le code postal ici"
        className='citySelectPostalCode citiesListByCpInput'
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
      />
      <div className="canalsListCities">
        {isLoading ? (
          <div style={{alignSelf: 'center'}}><div className="loaderPayment"></div></div>
        ) : canauxDisponibles.length > 0 ? (
          canauxDisponibles.map((canal, index) => (
            <div key={index} className="canalItem">
              <span style={{color: '#000'}}>{canal.name} - {canal.city}</span>
              <button
                onClick={(e) => handleConfirm(e, canal.codeCommune, canal.isCanalAssociation, canal.id)}
                disabled={isUserAlreadyInCanal(canal.codeCommune, canal.isCanalAssociation, canal.id)}
                className={isUserAlreadyInCanal(canal.codeCommune, canal.isCanalAssociation, canal.id) ? 'joinedButton' : 'joinButton'}
              >
                {isUserAlreadyInCanal(canal.codeCommune, canal.isCanalAssociation) ? 'Déjà rejoint' : 'Rejoindre'}
              </button>
            </div>
          ))
        ) : codePostal.length === 5 ? (
            <div><p style={{color:'#000'}}>Aucun canal trouvé pour ce code postal.</p></div>
          ) : (
            <div><p style={{color:'#000', textAlign: 'center', fontWeight: '500'}}>Les résultats s'afficheront ici.</p></div>
        )}
        <p style={{color: '#000'}}>Votre commune n'est pas dans la liste ? Parlez-en à votre commune !</p>
      </div>
    </div>
  );
};

export default CanalsListByCp;
