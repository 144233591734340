import React from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';

const PUBLIC_KEY = "pk_live_51Ng7WZKu63J8L2mLvGKduw00tmZbeY3PVWwjDuP7FSJXJ4bAIF1dieapNQcHfWavkqWsnavxBExGpXzpguD8rVxA00eloZ6nT9" ;
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Stripe = () => {
    return(
        <Elements stripe={stripeTestPromise} ><CheckoutForm /></Elements>
    )
}

export default Stripe;